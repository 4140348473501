.event__online {
	background: $bgColor-23;
	border-bottom: 1px solid $borderColor-23;
	padding-top: 11px;
	padding-bottom: 11px;

	& > * {
		display: inline-block;
	}

	.hint {
		margin-left: 30px;
	}
}


.event__online.form {
	padding-top: 30px;
	padding-bottom: 30px;

	.hint {
		margin: 0;
		font-size: em(14px);
		float: right;
		line-height: 28px;
		a {
			cursor: pointer;
			color: $textColor-30;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.selectbox {
		.label {
			display: inline-block;
			padding-right: 20px;
			&::after {
				content: ':';
			}
		}
		.row-field-controls {
			display: inline-block;
		}
	}
}
