.create-term-container {
	padding: 15px 0;
	.b-header {
		padding: 30px;
		border-bottom: 1px solid $borderColor-25;
		&_title {
			margin-bottom: 37px;
			position: relative;
		}
	}
	.b-section {
		padding: 30px;
		border-bottom: 1px solid $borderColor-25;
		.field-label {
			margin-bottom: 5px;
		}
	}

	.b-footer {
		padding: 30px 0 50px;
		text-align: center;
		.btn3 {
			margin: 0 5px;
		}
	}

	.author-name {
		line-height: 26px;
		cursor: pointer;
		border-bottom: 1px dashed $textColor-30;
		color: $textColor-30;
		white-space: nowrap;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		&:hover {
			color: darken($textColor-30, 15%);
			border-bottom-color: darken($textColor-30, 15%);
		}
		&.name-empty {
			font-style: italic;
		}
	}

	.widget-input-tags {
		display: inline-block;
		width: 100%;
		margin-bottom: -10px;
	}

	.has-errors-hint {
		padding-bottom: 15px;
		color: $textColor-51;
		.icon {
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			font-size: 20px;
			line-height: 1;
			margin-right: 10px;
		}
		.s-text {
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
		}
	}

	.action-remove {
		cursor: pointer;
		color: $textColor-51;
		font-size: em(14px);
		position: absolute;
		right: 0;
		top: 0;
		&:hover {
			color: darken($textColor-51, 20%);
		}
	}

	.term-options {
		.term-options-header {
			font-weight: bold;
			font-size: em(18px);
		}

		.term-options-row {
			margin: 20px 0;
			padding-left: 25px;
		}

		.term-options-controls {
			.label {
				font-weight: bold;
				white-space: nowrap;
			}
		}

		.term-options-hint {
			margin-top: 0.5em;
			padding-left: 24px;
			p {
				line-height: 1.3;
				font-size: em(15px);
				margin-bottom: 0.3em;
			}
		}
	}

	.fild-author {
		display: table;
		table-layout: fixed;
		width: 100%;
		&-cell {
			display: table-cell;
			vertical-align: middle;
			&.label {
				width: 150px;
			}
		}
	}
}
