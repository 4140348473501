md-input-container {
	.md-input {
		border-width: 1px;
		border-radius: 4px;
		border-color: #D0D5D8;
		padding: 5px 15px !important;
		background: #FFF;
	}

	.md-placeholder,
	label:not(.md-no-float):not(._md-container-ignore) {
		padding-left: 16px;
	}

	&.md-default-theme:not(.md-input-invalid).md-input-focused,
	&:not(.md-input-invalid).md-input-focused {

		label {
			color: $borderColor-10;
		}

		.md-input {
			border-color: $borderColor-12;
		}
	}

	// Use wide border in error state or in focused state
	&.md-input-focused .md-input,
	.md-input.ng-invalid.ng-dirty {
		padding-bottom: 5px; // Increase border width by 1px, decrease padding by 1
		border-width: 1px 1px 1px 1px;
	}

	&.md-default-theme label.md-no-float,
	& label.md-no-float {
		color: $textColor;
	}
}
