.images-list {
	@include pie-clearfix;
	@extend .c-wrapper;
	margin: 0;
}

.images-list-item {
	float: none;
	width: 100%;
	padding: 10px 0;
	//overflow: hidden;
	display: inline-block;
	.image-teaser {
		float: left;
		padding-right: 20px;
		width: 200px;
	}
}
