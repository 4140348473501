.md-chips-location {
	$height: 28px;
	$textColor: $textColor-23;
	$borderColor: $borderColor-28;
	$activeBorderColor: $borderColor-12;
	$bgColor: $bgColor-20;
	$disabledBgColor: $bgColor-27;

	display: block;

	.md-chips {
		//@include reset-font();
		background: $bgColor;
		font-size: em(14px);
		border-radius: 3px;
		border: 1px solid $borderColor;
		color: $textColor;
		padding: 0;
		box-shadow: none;
		min-width: 150px;
		&.md-focused {
			box-shadow: none;
			border-color: $activeBorderColor;
		}
	}

	.md-chips ._md-chip-input-container {
		margin: 0;
		float: none;
		&:not(:first-child) {
			display: none;
		}

		md-autocomplete-wrap, md-autocomplete {
			height: $height;
			border-radius: 0;
			min-width: 150px;
			max-width: 150px;
			width: auto;
		}

		md-autocomplete[disabled] {
			background-color: $disabledBgColor;
		}

		input {
			padding: 0 12px;
			height: $height;
			line-height: $height;
			font-size: 1em;
		}
	}

	.md-chips md-chip {
		float: none;
		border-radius: 0;
		margin: 0;
		height: $height;
		line-height: $height;
		background: transparent;
		&.md-focused {
			background: transparent;
			color: $textColor;
			md-icon {
				color: $textColor;
			}
		}

		._md-chip-remove-container {
			line-height: 9px;
		}

		._md-chip-remove {
			height: $height;
		}
	}
}

.md-chips-location-menu li {
	$height: 35px;
	line-height: $height;
	height: $height;
}
