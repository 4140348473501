#modal-new-message.modal {
	$modalWidth: 490px;
	$avatarSize: 70px;

	.modal-dialog {
		width: $modalWidth;
		background-color: $bgColor-55;
		border-radius: 16px;
		padding: 20px;
		overflow: visible;
		@include pie-clearfix;
	}

	.title {
		font-size: em(20px);
		font-weight: normal;
		color: $textColor-23;
	}

	.label {
		padding-bottom: 10px;
		font-size: em(14px);
		color: $textColor-22;
	}

	.message-form-header {
		margin-top: -10px;
		padding-bottom: 15px;
		border-bottom: 1px solid $borderColor-54;
		display: table;
		width: 100%;

		.image, .user-info, .action {
			display: table-cell;
			vertical-align: middle;
		}

		.user-info {
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			width: 100%;
		}

		.image {
			position: relative;

			.image__user {
				transition: transform 100ms linear;
			}

			&:hover .image__user{
				transform: scale(1.1);
			}
		}

		.image__user {
			margin-top: -30px;
			width: $avatarSize;
			height: $avatarSize;
			&.is-default {
				border: 1px solid $borderColor-54;
			}
		}

		.location-name {
			font-size: em(14px);
		}

		.location-icon {
			font-size: em(13px);
			position: relative;
			top: 1px;
			margin-right: 5px;
		}

		.location-wrapper {
			margin-top: 4px;
			color: $textColor-13;
		}
	}

	.message-form-content {
		border-top: 1px solid $borderColor-24;
		padding-top: 20px;
		overflow: hidden;
		textarea {
			min-height: 110px;
			max-height: 380px;
			overflow-y: auto !important;
		}
		.submit-button {
			margin-top: 23px;
		}
	}

	.notification-message {
		text-align: center;

		.title {
			padding-bottom: 0.4em;
		}

		.username {
			$linkColor: $textColor-31;
			color: $linkColor;
			font-weight: bold;
			&:hover {
				color: darken($linkColor, 15%);
			}
		}

		.button-chat {
			margin-top: 0.5em;
		}
	}
}
