.event__header {
	background-image: linear-gradient(-179deg, #B9B9B9 0%, #868686 100%);
	background-color: $borderColor-29;
	overflow: visible;

	/**
	 * **************** Event Header Info Section *****************
	 **/

	.info {
		height: auto;
		padding: 0;
		top: 5px;

		& > .info-table {
			width: 100%;
			tr td {
				border-bottom: 17px solid transparent;
				border-right: 40px solid transparent;
			}
			tr td:last-of-type {
				border-right: none;
			}
			tr:last-of-type td {
				border-bottom: none;
			}
		}
	}

	/**
	 * **************** Event Header Fields Styles *****************
	 **/

	.textfield {
		box-sizing: border-box;
		width: 100%;
	}

	.label {
		font-size: em(14px);
		color: $textColor-10;
	}

	.selectbox {
		.label {
			display: block;
			padding-bottom: 5px;
		}
		.select2-container .select2-choice {
			width: 140px;
			min-width: 140px;
		}
	}

	.checkbox {
		margin-top: 18px;
	}
}
