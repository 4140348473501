.audios-list-item {
	border-bottom: 1px solid $borderColor-20;
	padding: 10px 0 20px;
}

.audios-list-item .manage-controls {
	padding-top: 10px;
}

.audios-list-item .manage-content-tags {
	margin-top: 10px;
}

.audio-teaser:hover {
	border-color: transparent;
}

