.b-manage-wiki {
	border: 1px solid $borderColor-20;
	border-radius: 5px;
	overflow: hidden;
	box-sizing: border-box;
	margin: 20px;
	.b-manage-wiki-header {
		display: table;
		width: 100%;
		padding: 5px 10px;
		background-color: $bgColor-23;
		box-sizing: border-box;
		border-bottom: 1px solid $borderColor-20;
		&:last-child {
			border-bottom: none;
		}
		.b-manage-wiki-call {
			vertical-align: middle;
			display: table-cell;
			&.manage-title {
				font-size: 16px;
				vertical-align: middle;
			}
			&.manage-tooltip {
				text-align: right;
				.btn5 {
					margin-left: 10px;
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
	}
	.b-manage-wiki-content {
		padding: 10px;
		.not-fixed {
			font-style: italic;
			text-align: center;
			color: $textColor-17;
			margin: 30px 0;
		}
		.articles-list-item {
			background-color: $bgColor-20;
			border-radius: 8px;
			position: relative;
			display: block;
			padding: 5px;
			margin: 0;
			transition: opacity 0.3s ease;
			box-sizing: border-box;
			.article-media-box {
				h2 {
					font-size: 16px;
				}
				.info {
					min-height: 100px;
				}
			}
			.image__article {
				width: 100px;
				height: 100px;
			}
			.article-handler {
				position: absolute;
				width: 30px;
				height: 30px;
				background-color: rgba($bgColor-20, 0.7);
				border-radius: 4px;
				cursor: move !important;
				z-index: 100;
				left: 12px;
				top: 12px;
				.icon {
					font-size: 22px;
					line-height: 1;
					color: $textColor-20;
					vertical-align: middle;
					position: absolute;
					left: 50%;
					top: 50%;
					margin-left: -11px;
					margin-top: -11px;
				}
			}
			&:not(:last-of-type) {
				&::after {
					display: none;
				}
			}
			* {
				-webkit-touch-callout: none;
				-webkit-user-drag: none;
				user-drag: none;
				user-select: none;
			}
		}
		.b-faq_item {
			border-radius: 8px;
			display: table;
			table-layout: fixed;
			background-color: $bgColor-20;
			position: relative;
			margin: 0;
			* {
				-webkit-touch-callout: none;
				-webkit-user-drag: none;
				user-drag: none;
				user-select: none;
			}
			.b-faq_cell {
				display: table-cell;
				vertical-align: middle;
				&.handler {
					width: 40px;
					text-align: center;
				}
				&.content {
					padding: 10px;
				}
			}
			.b-faq_handler {
				width: 30px;
				height: 30px;
				display: inline-block;
				background-color: $bgColor-11;
				border-radius: 4px;
				cursor: move !important;
				z-index: 100;
				position: relative;
				.icon {
					font-size: 22px;
					line-height: 1;
					color: $textColor-10;
					vertical-align: middle;
					position: absolute;
					left: 50%;
					top: 50%;
					margin-left: -11px;
					margin-top: -11px;
				}
			}
			.b-faq_answer {
				p {
					font-size: 13px;
					line-height: 15px;
				}
			}
		}
		.b-edit_question, .edit_article {
			.btn5 {
				&.btn5-white {
					display: block;
				}
				&.btn5-green {
					display: none;
				}
			}
		}
		.sv-helper {
			opacity: 0.8;
			z-index: 10000000;
			box-shadow: 5px 5px 10px -2px rgba($borderColor-51, 0.8);
		}
	}
}

.fixed-articles:not(:last-child) {
	.articles-list-item.compact {
		&:after {
			content: '';
			display: block;
			padding: 19px 10px 0;
			height: 1px;
			background-color: $borderColor-20;
			background-clip: content-box;
		}
	}
}
