.article_view {
	$bgZIndex: 10;

	/***  Header  ***/
	.a-header {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		overflow: hidden;
		position: relative;
		padding-top: 30px;
		padding-bottom: 30px;
		background: $bgColor-60;
		border-bottom: 1px solid $bgColor-60;
		.header-container {
			position: relative;
			padding: 5px 0 (26px + 8px + 10px);
			box-sizing: border-box;
			min-height: 144px;
			.article-reading-time {
				position: absolute;
				bottom: 8px;
				left: 0;
			}
		}
	}

	.header-background-wrapper {
		user-select: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: $bgZIndex;
		img {
			position: relative;
			display: block;
			top: 50%;
			left: 50%;
			width: 105%;
			transform: translate(-50%, -50%);
			filter: blur(14px);
		}
		&::after {
			content: '';
			display: block;
			background: rgba(#000, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.header-content-wrapper {
		position: relative;
		z-index: $bgZIndex + 1;
		@include pie-clearfix;

		.article-controls {
			position: absolute;
			right: 0;
			bottom: 0;
		}
		.article-counters {
			text-align: right;
		}
		.article-actions {
			margin-top: 20px;
			text-align: right;
			.action {
				&.added {
					border-color: $textColor-32;
					color: $textColor-32;
				}
				i[class^='icon-m'] {
					font-size: 24px;
					line-height: 1;
					vertical-align: top;
				}
			}
		}

		.article-series {
			position: relative;
			float: right;
			vertical-align: top;
			cursor: default;
			user-select: none;
			margin-left: 10px;
			&-item {
				display: inline-block;
				vertical-align: middle;
				font-size: 16px;
				font-weight: bold;
				line-height: 1;
				color: $textColor-10;
				padding: 5px 20px;
				border-radius: 4px;
				background-color: $borderColor-10;
			}
			&-info {
				display: inline-block;
				vertical-align: middle;
				position: absolute;
				top: -1px;
				right: -34px;
				&:hover {
					.s-text-info {
						visibility: visible;
						opacity: 1;
					}
				}
				.icon-m-info {
					color: $textColor-32;
					font-size: 28px;
					line-height: 28px;
					vertical-align: top;
					cursor: pointer;
					&:after {
						content: '';
						width: 14px;
						height: 14px;
						margin: -7px;
						background-color: #fff;
						z-index: -1;
						left: 50%;
						top: 50%;
						position: absolute;
					}
				}
				.s-text-info {
					white-space: nowrap;
					display: inline-block;
					color: $textColor-10;
					font-size: 14px;
					line-height: 1;
					position: absolute;
					bottom: -30px;
					right: -10px;
					padding: 5px 10px;
					background-color: rgba(#000, 0.4);
					border-radius: 4px;
					visibility: hidden;
					opacity: 0;
					transition: 0.3s ease opacity;
					&:before {
						content: '';
						position: absolute;
						border: 6px solid transparent;
						border-bottom-color: rgba(#000, 0.4);
						right: 18px;
						top: -12px;
					}
				}
			}
		}
	}

	.header-content-wrapper.dark {
		.article-title,
		.article-counters {
			color: $textColor-10;
		}
		.participants-list-item .participant-label {
			color: $textColor-16;
		}
		.article-actions {
			.action-message {
				color: $textColor-10;
			}
			.action {
				border-color: $borderColor-24;
				color: $textColor-10;
				&.added, &.action-status {
					border-color: $textColor-33;
					color: $textColor-33;
				}
			}
		}

		.article-reading-time {
			user-select: none;
			cursor: default;
			border-color: #fff;
			color: #fff;
		}
	}

	/***  Content  ***/

	.article-audios-container {
		padding-top: 10px;
		padding-bottom: 10px;
		background: $bgColor-60;
		border-bottom: 1px solid $borderColor-25;
	}

	.a-content {
		padding-top: 40px;
		padding-bottom: 40px;
		.article-body {
			@include pie-clearfix;
		}
		.article-tags {
			padding-top: 10px;
		}
	}

	.a-info {
		background: $bgColor-60;
		background: #fff;
		border-top: 1px dotted $borderColor-25;
		border-bottom: 1px solid $borderColor-25;
		padding-top: 15px;
		padding-bottom: 15px;
		overflow: hidden;
		white-space: nowrap;

		.footer-box {
			display: inline-block;
			margin-right: 60px;
			&.right {
				float: right;
				margin-right: 0;
			}
			&.subscribe {
				margin-right: 10px;
				padding-top: 21px;
				.following {
					width: 160px;
					&.btn5-green {
						padding: 7px 10px;
					}
				}
			}
			.label {
				display: block;
				padding-bottom: 7px;
				font-size: em(14px);
				color: $textColor-13;
			}
		}

		.info__owner {
			vertical-align: middle;
			display: inline-block;
			.created {
				display: block;
				color: $textColor-13;
				font-size: em(12px);
			}
		}
	}

	.similar-items {
		$borderSize: 15px;
		background: $bgColor-60;
		padding: 20px 0 0;
		overflow: hidden;
		.container {
			position: relative;
			.loader-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}

		.similar-items-title {
			font-size: em(18px);
			padding-bottom: 10px;
			&::after {
				content: ':'
			}
		}

		.similar-items-header {
			text-align: center;
			margin-bottom: 25px;
			padding: 0 60px;
			h3 {
				font-size: 21px;
				line-height: 1;
			}
			a {
				color: $textColor-30;
			}
		}

		.similar-items-content {
			background: #fff;
			padding: 30px 60px;
			position: relative;
			border-top: 1px solid $borderColor-25;
			border-bottom: 1px solid $borderColor-25;
			.owl-carousel .owl-stage-outer {
				padding: 2px;
				margin: -2px;
			}
		}

		.items-wrapper {
			position: relative;
			display: block;
			min-height: 318px;
			&.items-empty {
				.b-articles-column {
					display: none;
				}
				.items-empty-text {
					display: block;
				}
			}
			.items-empty-text {
				text-align: center;
				font-style: italic;
				padding: 150px 0;
				display: none;
			}
		}

		.b-articles-column {
			.owl-carousel.owl-drag .owl-item {
				min-height: 318px;
			}
		}

		.owl-carousel {
			.owl-nav {
				$width: 60px;
				.owl-prev, .owl-next {
					width: $width;
				}
				.owl-prev {
					left: -$width;
				}
				.owl-next {
					right: -$width;
				}
			}
		}

		.similar-tabs {
			margin-top: 30px;
			md-tabs-canvas {
				position: relative;
				margin: 0 60px;
			}
			md-tabs-canvas, md-pagination-wrapper {
				height: 40px;
			}
			md-tabs-canvas {
				margin-bottom: -1px;
			}
			md-tabs-content-wrapper {
				background-color: #fff;
				border-top: 1px solid #D0D5D8;
			}
			md-tab-content {
				padding: 30px 0;
			}
			md-tabs-wrapper {
				position: relative;
				z-index: 2;
			}
			.md-tab {
				color: $textColor-27;
				text-transform: none;
				background-color: #e5e5e5;
				border: 1px solid transparent;
				border-bottom-color: #D0D5D8;
				border-radius: 8px 8px 0 0;
				top: 4px;
				padding: 4px 20px;
				font-size: 16px;
				line-height: 28px;
				height: 36px;
				&.md-active {
					color: $textColor-27;
					background-color: #fff;
					border-color: #D0D5D8;
					border-bottom-color: #fff;
					height: 40px;
					padding-top: 8px;
					top: 0;
				}
			}
		}
	}

	.note-comments-container {
		background-color: $bgColor-24;
		padding: 20px 60px 40px;
		border-bottom: 1px solid $borderColor-25;
		&-nauth {
			padding: 15px;
			background-color: $bgColor-59;
			color: $textColor-17;
			border: 1px solid $borderColor-20;
			border-radius: 5px;
			font-style: italic;
			text-align: center;
			margin-top: 30px;
			cursor: default;
			user-select: none;
			a {
				color: $textColor-30;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		&.ng-enter {
			transition: 0.3s transform;
			transform: translateY(-20%) scale(0.9);
			&.ng-enter-active {
				transform: translateY(0) scale(1);
			}
		}
		.section-header {
			border: 0 solid transparent;
			background-color: transparent;
			.sh-row {
				padding: 0;
				margin: 0;
				.sh-title {
					color: $textColor-28;
				}
				.btn5-white {
					margin-left: 15px;
				}
			}
		}
	}

	/***  Footer  ***/

	.a-footer {
		overflow: hidden;
		min-height: 30px;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		&.color-gray {
			background: $bgColor-60;
		}
		&.size-small {
			height: 16px;
		}
	}

	/***  Common Styles  ***/

	.article-title {
		font-size: em(22px);
		line-height: 1.2;
		color: $textColor-23;
	}

	.article-type {
		padding-top: 2px;
		color: $textColor-40;
	}

	.participants-list {
		padding-top: 10px;
	}

	.article-reading-time {
		border: 1px solid $textColor-13;
		color: $textColor-13;
		border-radius: 4px;
		font-size: em(14px);
		line-height: 22px;
		padding: 0 10px;
		display: inline-block;
		white-space: nowrap;
	}

	.article-body {
		font-size: em($articleFontSize);
		color: $articleFontColor;
		text-align: justify;
	}

	.article-tags {
		display: block;
		font-style: italic;
		.label {
			display: inline-block;
			margin-right: 0.5em;
			&::after {
				content: ':';
			}
		}

		.tags-list-item {
			font-size: $fontSize;
			line-height: 1;
		}
	}

	.article-counters {
		$spaceSize: 5px;
		margin: 0 (-$spaceSize);
		color: $textColor-13;
		@include font-helvetica-light;
		.counter {
			margin: 0 $spaceSize;
			display: inline-block;
			min-width: 40px;
			user-select: none;
			cursor: default;
			.icon {
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
			}
			i[class^='icon-m'] {
				display: inline-block;
				font-size: 20px;
				vertical-align: middle;
				margin-right: 5px;
			}
			.s-text {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	.article-actions {
		$spaceSize: 5px;
		margin: 0 (-$spaceSize);
		position: relative;
		.action-container {
			vertical-align: top;
			display: inline-block;
			margin: 0 $spaceSize;
		}

		.action {
			border: 2px solid $textColor-13;
			color: $textColor-13;
			transition: transform 100ms ease;
			&.action-status {
				cursor: default;
				color: $textColor-32;
				border-color: $textColor-32;
			}
			&:not(.action-status):hover:not(:active) {
				transform: scale(1.1);
			}
			.icon-bullhorn {
				font-size: 18px;
				display: inline-block;
			}
		}

		.action-message {
			position: absolute;
			right: 0;
			bottom: -20px;
			white-space: nowrap;
			font-size: em(12px);
			display: none;
		}

		.action-container:hover .action-message {
			display: block;
		}
	}
}
