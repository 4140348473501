//
// Bootstrap Popovers
// --------------------------------------------------

$popover-zindex: 98 !default;
$popover-bg: #fff !default;
$popover-max-width: 276px !default;
$popover-border-color: $borderColor-56 !default;
$popover-border-radius: 6px !default;
$popover-title-bg: #f7f7f7 !default;
$popover-arrow-width: 10px !default;
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color: rgba(0, 0, 0, 0.25) !default;

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $popover-zindex;
	display: none;
	max-width: $popover-max-width;
	padding: 1px;
	background-color: $popover-bg;
	background-clip: padding-box;
	border: 1px solid $popover-border-color;
	border-radius: $popover-border-radius;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

	// Offset the popover to account for the popover arrow
	&.top {
		margin-top: -$popover-arrow-width;
	}
	&.right {
		margin-left: $popover-arrow-width;
	}
	&.bottom {
		margin-top: $popover-arrow-width;
	}
	&.left {
		margin-left: -$popover-arrow-width;
	}
}

.popover-title {
	margin: 0; // reset heading margin
	padding: 8px 14px;
	background-color: $popover-title-bg;
	border-bottom: 1px solid darken($popover-title-bg, 5%);
	border-radius: ($popover-border-radius - 1) ($popover-border-radius - 1) 0 0;
}

.popover-content {
	padding: 9px 14px;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow {
	&,
	&:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
}

.popover > .arrow {
	border-width: $popover-arrow-outer-width;
}

.popover > .arrow:after {
	border-width: $popover-arrow-width;
	content: "";
}

.popover {
	&.top > .arrow {
		left: 50%;
		margin-left: -$popover-arrow-outer-width;
		border-bottom-width: 0;
		border-top-color: $popover-arrow-outer-color;
		bottom: -$popover-arrow-outer-width;
		&:after {
			content: " ";
			bottom: 1px;
			margin-left: -$popover-arrow-width;
			border-bottom-width: 0;
			border-top-color: $popover-arrow-color;
		}
	}
	&.right > .arrow {
		top: 50%;
		left: -$popover-arrow-outer-width;
		margin-top: -$popover-arrow-outer-width;
		border-left-width: 0;
		border-right-color: $popover-arrow-outer-color;
		&:after {
			content: " ";
			left: 1px;
			bottom: -$popover-arrow-width;
			border-left-width: 0;
			border-right-color: $popover-arrow-color;
		}
	}
	&.bottom > .arrow {
		left: 50%;
		margin-left: -$popover-arrow-outer-width;
		border-top-width: 0;
		border-bottom-color: $popover-arrow-outer-color;
		top: -$popover-arrow-outer-width;
		&:after {
			content: " ";
			top: 1px;
			margin-left: -$popover-arrow-width;
			border-top-width: 0;
			border-bottom-color: $popover-arrow-color;
		}
	}

	&.left > .arrow {
		top: 50%;
		right: -$popover-arrow-outer-width;
		margin-top: -$popover-arrow-outer-width;
		border-right-width: 0;
		border-left-color: $popover-arrow-outer-color;
		&:after {
			content: " ";
			right: 1px;
			border-right-width: 0;
			border-left-color: $popover-arrow-color;
			bottom: -$popover-arrow-width;
		}
	}

	&.validate-popover {
		max-width: 250px;
		border-color: $borderColor-42;
		.arrow {
			border-top-color: $borderColor-42;
		}
		.popover-content {
			padding: 8px 10px;
		}
		.errors {
			.error_item {
				font-size: 12px;
				line-height: 16px;
				margin-bottom: 10px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		code {
			background-color: $bgColor-25;
			display: inline-block;
			padding: 0 3px;
			border: 1px solid $borderColor-53;
		}
	}
}
