.b-series_content {
	.series-list {
		margin-bottom: 20px;
		&-none {
			margin-bottom: 20px;
			p {
				text-align: center;
				font-size: 15px;
				line-height: 22px;
				color: $textColor-21;
			}
		}
	}
	.series-add {
		background-color: $bgColor-20;
		border-top: 1px solid $borderColor-25;
		margin: 0 -30px -20px;
		padding: 20px 30px;
		display: table;
		width: 100%;
		table-layout: fixed;
		.series-add_cell {
			display: table-cell;
			vertical-align: middle;
			&.submit {
				text-align: right;
				width: 100px;
			}
		}
		.edit {
			$seriesHeight: 32px;
			padding: 0 5px;
			display: block;
			border: 1px solid $borderColor-26;
			background-color: transparent;
			font-size: 16px;
			height: $seriesHeight;
			line-height: $seriesHeight;
			width: 100%;
			vertical-align: middle;
			box-sizing: border-box;
			margin-bottom: 0;
			&:hover, &:focus {
				border-color: $borderColor-12;
			}
		}
	}
	.series-item {
		position: relative;
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
		.series-controls {
			box-shadow: -10px 0 5px -5px $bgColor-55;
			background-color: $bgColor-55;
			line-height: 24px;
			margin-top: -12px;
			position: absolute;
			padding-right: 4px;
			right: 1px;
			top: 50%;
		}
	}
	.series-title {
		border: 1px solid transparent;
		transition: border 0.3s ease;
		white-space: nowrap;
		overflow: hidden;
		&.focus, &:hover {
			border-color: $borderColor-12;
		}
		.series-view {
			$seriesHeight: 32px;
			padding: 0 10px;
			display: block;
			background-color: transparent;
			font-size: 16px;
			height: $seriesHeight;
			line-height: $seriesHeight;
			width: 100%;
			vertical-align: middle;
			box-sizing: border-box;
			white-space: nowrap;
			&.edit {
				line-height: $seriesHeight;
				padding: 0;
			}
		}
	}
	.series-edit {
		display: inline-block;
		position: relative;
		vertical-align: top;
		width: 25px;
		height: 25px;
		color: $textColor-18;
		border-radius: 3px;
		transition: 0.2s ease background-color, 0.2s ease color;
		&:hover {
			background-color: rgba(#000, 0.05);
		}
		&.edit {
			margin-right: 3px;
			&:hover {
				color: $textColor-30;
			}
		}
		&.remove {
			&:hover {
				color: $textColor-51;
			}
		}
		.icon {
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			line-height: 18px;
			font-style: normal;
			position: absolute;
			margin: -9px;
			left: 50%;
			top: 50%;
			&:before {
				vertical-align: initial;
				line-height: inherit;
			}
		}
	}
}

.series-autocomplite {
	$height: 34px;
	li {
		line-height: $height;
		height: $height;
	}
}

.recover-serie {
	font-size: 12px;
	line-height: 1;
	color: $textColor-30;
	border-bottom: 1px dotted $textColor-30;
	&:hover {
		color: $textColor-30;
		border-bottom-color: transparent;
	}
}

.series-textfield {
	$height: 34px;
	$textColor: $textColor-23;
	$borderColor: $borderColor-28;
	$activeBorderColor: $borderColor-12;
	$bgColor: $bgColor-20;
	$disabledBgColor: $bgColor-27;
	display: inline-block;
	width: 100%;
	.md-chips {
		background: $bgColor;
		font-size: em(14px);
		border-radius: 4px;
		border: 1px solid $borderColor;
		color: $textColor;
		padding: 0;
		box-shadow: none;
		&.md-focused {
			box-shadow: none;
			border-color: $activeBorderColor;
		}
		.md-chip {
			position: relative;
			.md-chip-content {
				float: none;
			}

			.md-chip-remove-container {
				top: 0;
			}
		}
	}

	.md-chips ._md-chip-input-container {
		margin: 0;
		float: none;
		&:not(:first-child) {
			display: none;
		}

		md-autocomplete-wrap, md-autocomplete {
			height: $height - 2;
			border-radius: 0;
		}

		md-autocomplete[disabled] {
			background-color: $disabledBgColor;
		}

		input {
			padding: 0 12px;
			height: $height - 2;
			line-height: $height - 2;
			font-size: 1em;
		}
	}

	.md-chips md-chip {
		float: none;
		border-radius: 0;
		margin: 0;
		height: $height - 2;
		line-height: $height - 2;
		background: transparent;
		&.md-focused {
			background: transparent;
			color: $textColor;
			md-icon {
				color: $textColor;
			}
		}

		._md-chip-remove-container {
			line-height: 9px;
		}

		.md-chip-remove {
			height: $height;
		}
	}
}

.b-articles-column {
	min-height: 400px;

	.articles-not-carousel-wrap {
		position: relative;
		margin-right: -30px;
	}
	.articles-not-carousel {
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
		article-teaser {
			display: table-cell;
			padding-right: 30px;
			&:only-child {
				display: block;
				width: 25%;
				box-sizing: border-box;
			}
		}
	}
}
