.notification-message {
	$iconSize: 120px;
	padding: 40px 0;

	&.style-1 {
		background-color: $bgColor-59;
		border-top: 1px solid $borderColor-20;
		border-bottom: 1px solid $borderColor-20;
	}

	%message-image {
		display: block;
		width: $iconSize;
		height: $iconSize;
		margin: 0 auto 30px;
		border-radius: 50%;
		overflow: hidden;
		border: 1px solid $borderColor-20;
		background-color: $bgColor-20;
	}

	.message-image-mail {
		@extend %message-image;
		@include background-image-2x('../images/icons/mail', 55px, 46px);
		background-repeat: no-repeat;
		background-position: center;
	}

	.message-image-video {
		@extend %message-image;
		@include background-image-2x('../images/icons/video', 65px, 65px);
		background-repeat: no-repeat;
		background-position: center;
	}

	.message-image-audio {
		@extend %message-image;
		@include background-image-2x('../images/icons/audio', 65px, 65px);
		background-repeat: no-repeat;
		background-position: center;
	}

	.message-image-inner {
		@extend %message-image;
		position: relative;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.message-icon-custom {
		@extend %message-image;
		text-align: center;
		line-height: $iconSize;
		color: $textColor-30;
		font-size: 45px;
	}

	.message-icon-success,
	.message-icon-error {
		@extend %message-image;
		text-align: center;
		line-height: $iconSize;
	}
	.message-icon-success {
		font-size: 40px;
		color: $textColor-30;
	}
	.message-icon-error {
		font-size: 45px;
		color: $textColor-50;
	}

	.message {
		text-align: center;
		line-height: 1.4;
		color: $textColor-22;
		padding-bottom: 15px;
		&:last-of-type {
			padding-bottom: 0;
		}
	}

	.message-actions {
		padding-top: 20px;
		text-align: center;
	}
}