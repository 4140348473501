.btn6 {
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	transition: background-color getTimeByName('fast') ease-out, color getTimeByName('fast') ease-out;
	border-radius: 50%;
	box-sizing: border-box;
	height: 40px;
	width: 40px;
	font-size: em(14px);
	position: relative;

	&.btn-mini {
		height: 25px;
		width: 25px;
		.icon{
			font-size: em(10px);
		}
	}
}

// # Colors

.btn6-white {
	background-color: $bgColor-20;
	&:not(.without-hover-bg):hover {
		background-color: darken($bgColor-20, 5%);
	}
	&.bordered {
		$borderColor: $borderColor-23;
		border: 1px solid $borderColor;
		&:hover {
			border-color: darken($borderColor, 15%);
		}
	}
}

.btn6-blue {
	$color: $textColor-10;
	background-color: $bgColor-30;
	border-top: 1px solid $borderColor-30;
	border-bottom: 1px solid $borderColor-31;
	color: $color;
	&:hover {
		background-color: $bgColor-31;
	}
}

.btn6-green {
	$color: $textColor-10;
	background-color: $bgColor-11;
	border-top: 1px solid $borderColor-13;
	border-bottom: 1px solid $borderColor-14;
	color: $color;
	&:hover {
		background-color: $bgColor-12;
	}
}

.btn6-bordered-green {
	$color: $bgColor-11;
	border: 1px solid $color;
	color: $color;
	transition: border-color getTimeByName('fast') ease-out, color getTimeByName('fast') ease-out;
	&:hover {
		border-color: darken($color, 15%);
		color: darken($color, 15%);
	}
}

// # Icons

.btn6 {
	.icon {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
	}

	.icon-add {
		font-size: 16px;
	}

	.icon-clear {
		font-size: 14px;
	}

	.icon-message {
		font-size: 12px;
	}

	.icon-checkmark {
		font-size: 14px;
	}

	.icon-dots-three-horizontal {
		font-size: 18px;
	}

	.icon-edit {
		font-size: 16px;
	}

	.icon-bullhorn {
		font-size: 17px;
	}

	.icon-angle-left,
	.icon-angle-right {
		font-size: em(30px, 14px);
		margin-top: em(-1px, 30px);
	}
	.icon-angle-left {
		margin-left: em(-2px, 30px);
	}
	.icon-angle-right {
		margin-left: em(1px, 30px);
	}
}

.btn6-white {
	.icon-add, .icon-checkmark {
		color: $textColor-30;
	}
	.icon-clear {
		color: $textColor-50;
	}
}


