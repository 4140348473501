.fixed-faq {
	margin-bottom: 20px;
}
.b-faq {
	&_wrap {
		.b-manage-wiki {
			margin: 20px 30px 0;
		}
		.b-manage-content {
			.b-faq_content {
				padding: 0;
			}
		}
	}
	&_content {
		position: relative;
		padding: 30px 40px 60px 30px;
		.loader-wrapper {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	&_item {
		margin-bottom: 25px;
		position: relative;
		text-align: justify;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&_question {
		margin-bottom: 12px;
		h3 {
			display: inline;
			vertical-align: top;
			font-size: 18px;
			line-height: 1;
			font-weight: bold;
			margin: 0;
		}
	}
	&_answer {
		p {
			line-height: 19px;
			margin-bottom: 10px;
			&:last-of-type {
				margin-bottom: 0 !important;
			}
			* {
				line-height: 19px !important;
			}
		}
	}
	&_footer {
		display: table;
		vertical-align: middle;
		table-layout: fixed;
		width: 100%;
		margin-top: 5px;
		&-cell {
			display: table-cell;
			&.right {
				text-align: right;
			}
		}
		.b-show_more {
			.l-show_more {
				color: $textColor-30;
				border-bottom: 1px dashed $textColor-30;
				&:hover {
					border-bottom-color: transparent;
				}
			}
		}
	}
}
