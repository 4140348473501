.audio-details-popover {
	max-width: none;
	white-space: nowrap;

	.details-row {
		overflow: hidden;
		margin: 5px 0;
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.details-row .label {
		font-size: em(14px);
		&::after {
			content: ': ';
		}
	}

	.maker {
		color: inherit;
		display: inline-block;
		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			width: 30px;
			height: 30px;
		}
		.name {
			font-weight: bold;
		}
		.hint {
			font-style: italic;
		}
		&:hover .name {
			text-decoration: underline;
		}
	}

	.maker-info {
		display: inline-block;
		vertical-align: middle;
		font-size: em(14px);
		.hint {
			padding-top: 0.2em;
			display: block;
		}
	}
}
