.layout {
	display: block;
	position: relative;
	min-height: 100vh;
	background: $bgColor;
}

.modal-open .layout {
	filter: blur(8px);
}
