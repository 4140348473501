.redactor-box {
	padding-top: 45px;
}

.redactor-toolbar {
	z-index: 10 !important;

	&:not(.toolbar-fixed-box){
		border: 1px solid $borderColor-25;
		border-bottom: none;
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100% !important;
		box-sizing: border-box;
	}

	&.toolbar-fixed-box {
		z-index: 72 !important;
	}

	li a {
		&:hover {
			background-color: $bgColor-11;
			color: #fff;
		}

		&.re-button-icon {
			font-size: 19px;
			line-height: normal;
			padding: 12px 12px 11px;
			i::before {
				display: block;
			}
		}
	}

	li.pull-right {
		float: right;
	}
}

.redactor-editor {
	border-radius: 3px;
	padding: 30px 30px;
}

.redactor-dropdown {
	font-size: 13px;
	li:not(.redactor-dropdown-link-inactive):hover {
		background-color: $bgColor-11;
	}
	a {
		padding: 11px 15px 10px;
	}
}


.redactor-wide-container {
	position: relative;
	margin: 0 -30px;

	.redactor-box {
		border-bottom: 1px solid $borderColor-25;
		background: rgb(250,250,250);
		padding: 45px 29px 0;
	}

	.redactor-editor {
		border: none;
		margin: 40px 0;
		box-shadow: 0 1px 3px 1px rgba(#000, .2);
	}

	.redactor-toolbar {
		&:not(.toolbar-fixed-box){
			border: none;
			border-top: 1px solid $borderColor-25;
		}
	}
}
