.page-home {
	position: relative;

	.c-wrapper {
		padding-left: 70px;
		padding-right: 70px;
	}

	.section-content {
		padding-top: 60px;
		padding-bottom: 60px;

		&.highlighted {
			background: #F8FAFB;
		}

		.section-content-wrapper {
			padding-top: 40px;
		}
	}

	.section-content-title {
		font-size: em(30px);
		font-weight: bold;
		color: #4C565B;
		text-align: center;
		line-height: 1;
	}

	.section-content-description {
		font-size: em(17px);
		color: $textColor-28;
		line-height: 1.3;
		text-align: center;
		white-space: pre-line;
		padding-top: 15px;
	}

	.section-view-more {
		text-align: center;
		margin-top: 40px;
		margin-bottom: -15px;
		a.link-view-more {
			display: inline-block;
			padding: 12px 40px 10px 20px;
			font-size: em(18px);
			position: relative;
			color: $textColor-14;
			transition: all 100ms ease-in-out;
			border-radius: 4px;
			border: 1px solid transparent;

			i {
				display: block;
				line-height: 1;
				font-size: 24px;
				position: absolute;
				top: 50%;
				right: 17px;
				transform: translate(-8px, -50%);
				transition: transform 100ms ease-in-out;
			}

			&:hover {
				color: darken($textColor-14, 15%);
				background: #F8FAFB;
				border-color: $borderColor-53;
				i {
					transform: translate(0, -50%);
				}
			}
		}
	}

	.owl-carousel.style-2 .owl-nav  {
		.owl-next { right: -45px; }
		.owl-prev { left: -45px; }
	}
}

// banner
.page-home .section-banner {
	height: 387px;

	.banner-bg {
		width: 100%;
		height: auto;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	.banner-content-wrapper {
		position: relative;
		padding-top: 75px;
	}

	.title {
		color: $textColor-10;
		font-size: em(35px);
		font-weight: normal;
		line-height: 1;
		letter-spacing: -0.03em;
		padding-bottom: 22px;
	}

	.description {
		color: $textColor-10;
		font-size: em(18px);
		line-height: 1.2;
		padding-bottom: 37px;
		white-space: pre-line;
	}

	.btn-cta {
		padding: 15px 30px 13px;
		font-size: em(17px);
		border-radius: 5px;
		letter-spacing: 0.01em;
	}
}


// footer
.page-home .section-footer {
	background: #393939;
	padding-top: 40px;
	padding-bottom: 40px;
	color: $textColor-10;

	.first-container {
		overflow: hidden;

		.footer-menu {
			float: right;
		}
	}

	.second-container {
		padding-top: 10px;
		position: relative;
	}

	a {
		color: $textColor-10;
		text-shadow: 0 1px 2px rgba(#000, 0.4);
		&:hover {
			opacity: 0.9;
		}
	}

	.site-logo {
		font-size: 27px;
	}

	.footer-menu {
		white-space: nowrap;
		padding-top: 4px;

		li {
			display: inline-block;
			padding: 0 20px;
			&:last-of-type {
				padding-right: 0;
			}
			&:first-of-type {
				padding-left: 0;
			}
		}

		a {
			display: block;
			font-weight: bold;
			font-size: em(15px);
			padding-bottom: 2px;
			border-bottom: 1px solid transparent;
			&:hover {
				opacity: 0.8;
				border-bottom-color: $textColor-10;
			}
		}
	}

	.site-description {
		display: block;
		font-size: em(15px);
		line-height: 1.2;
		white-space: pre-line;
	}

	.site-copyright {
		opacity: 0.5;
		font-size: em(13px);
		line-height: 1.4;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

// social
.page-home .section-social {
	$columnSpace: 14px;
	$socialBtnSize: 45px;
	$facebookColor: #3F70AD;
	$vkColor: #5680AD;

	.section-content-wrapper {
		white-space: nowrap;
		margin-left: -$columnSpace;
		margin-right: -$columnSpace;
		padding-bottom: 15px;
	}

	.social-box {
		display: inline-block;
		vertical-align: top;
		width: 300px;
		height: 402px;
		box-sizing: border-box;
		border: 1px solid #57AE60;
		border-radius: 3px;
		overflow: hidden;
		margin: 0 $columnSpace;
		position: relative;

		&.vk-group-section {
			border: 3px solid $vkColor;
		}

		&.facebook-group-section {
			border: 3px solid $facebookColor;
		}


		.social-box-link {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 9;

			a.social-btn {
				border-radius: 0;
				border-bottom-left-radius: 3px;
			}
		}
	}

	.social-box-info {
		position: relative;
		border-color: transparent;
		img.social-box-bg {
			height: 100%;
			width: auto;
			position: absolute;
			top: 0;
			left: 0;
		}

		.social-box-wrapper {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			box-sizing: border-box;
			padding: 20px 15px;
		}

		.text {
			white-space: pre-line;
			color: $textColor-10;
			line-height: 1.2;
			font-size: em(17px);
		}

		.social-buttons-wrapper {
			$space: 5px;
			padding-top: 17px;
			margin: 0 -$space;
			a.social-btn {
				margin: 0 $space;
			}
		}
	}

	a.social-btn {
		display: inline-block;
		vertical-align: top;
		border-radius: 50%;
		color: #fff;
		width: $socialBtnSize;
		height: $socialBtnSize;
		text-align: center;
		position: relative;
		transition: transform 100ms ease-in-out;

		&:hover {
			transform: scale(1.1);
		}

		i {
			display: block;
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}

		&.button-facebook {
			font-size: 25px;
			background: $facebookColor;
		}
		&.button-vk {
			font-size: 22px;
			background: $vkColor;
			i {
				transform: translateY(-55%);
			}
		}
	}
}

// articles tiles
.page-home .articles-tiles {
	margin-top: -15px;

	.articles-tiles-row {
		display: flex;
		width: 100%;
		height: 320px;
		box-sizing: border-box;
		margin: 10px 0;
		border-collapse: collapse;

		&:nth-of-type(odd) .articles-tiles-cell:first-of-type,
		&:nth-of-type(even) .articles-tiles-cell:last-of-type {
			width: calc(50% + 10px);

			.article-media-box.type-tile {
				.counter-label {
					display: none;
				}
				.counter-label-full {
					display: inline;
				}
			}
		}
	}

	.articles-tiles-cell {
		display: block;
		border-left: 5px solid transparent;
		border-right:5px solid transparent;
		width: 25%;
		overflow: hidden;
		background: #F8FAFB;

		&:first-of-type {
			border-left: none;
		}
		&:last-of-type {
			border-right: none;
		}
	}
}



