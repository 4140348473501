.event-view {
	@import "event-dates";
	@import "event-image";
	@import "event__header"; /**
   * **************** Event Other Styles *****************
   **/

	.event-info {
		overflow: hidden;
		position: relative;

		.left-container {
			max-width: 670px;
		}

		.right-container {
			@include stretch(25px, 30px, 25px, false);
			background-color: $bgColor-23;
			padding: 15px 35px;
			border-radius: 8px;
		}
	}

	.event_confirm {
		padding: 15px 35px;
		position: relative;
		&-wrapper {
			background-color: $bgColor-20;
			border-bottom: 1px solid #DADBDD;
			transition: background-color 0.4s ease;
			position: relative;
			h3 {
				font-size: 27px;
				line-height: 1;
				color: $textColor-10;
				margin-bottom: 7px;
				font-weight: normal;
			}
			p {
				font-size: 17px;
				line-height: 1.3;
				white-space: pre-line;
				color: $textColor-10;
				margin-bottom: 0;
			}
			&.success {
				background-color: $bgColor-10;
			}
			&.failure {
				background-color: $bgColor-41;
			}
			.event_confirm-close {
				position: absolute;
				display: inline-block;
				padding: 6px;
				background-color: $bgColor-23;
				border-radius: 0 0 0 6px;
				font-size: 10px;
				line-height: 1;
				right: 0;
				top: 0;
				.icon {
					vertical-align: top;
					color: $textColor-14;
				}
			}
		}
		.loading_list {
			height: 50px;
		}
	}

	.event__details {
		.details-container {
			min-height: 80px;
		}

		.participants-list-item {
			font-size: em(16px);
		}

		.members {
			padding-top: 25px;
		}

		.type {
			color: $textColor-40;
			padding: 3px 0 6px;
			font-size: em(17px);
		}
	}

	.event__content {

		.audios-container {
			padding-top: 10px;
			padding-bottom: 10px;
			background: $bgColor-60;
			border-bottom: 1px solid $borderColor-25;
		}

		.description-container {
			padding-top: 30px;
			@include pie-clearfix;
		}

		.broadcast {
			padding-top: 20px;
			padding-bottom: 30px;
			border-bottom: 1px solid $borderColor-25;
			.title {
				display: block;
				font-size: em(20px);
				font-weight: bold;
			}
			.widget-container {
				margin-top: 15px;
				display: table;
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;
			}
			.widget,
			.info {
				vertical-align: middle;
				display: table-cell;
				text-align: center;
			}
			.widget {
				background: image-url('content/events/bg-broadcast.jpg') 600px 340px $bgColor-27;
				border: 1px solid $borderColor-27;
				width: 600px;
				height: 340px;
				position: relative;
			}
			.widget__action {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				@include transition-bg-color('fast');
				&:hover {
					background: rgba(#000, 0.2);
				}
				.icon {
					display: block;
					width: 80px;
					height: 80px;
					overflow: hidden;
					position: relative;
					top: 50%;
					margin: -40px auto 0;
					box-shadow: 0 2px 4px 0 rgba(#000, 0.15);
					border-radius: 50%;
					color: $textColor-10;
					font-size: 80px;
					&::before {
						position: relative;
						z-index: 20;
					}
					&::after {
						content: '';
						display: block;
						width: 65%;
						height: 65%;
						position: absolute;
						top: (100% - 65%) / 2;
						left: (100% - 65%) / 2;
						background-color: $textColor;
						z-index: 10;
					}
				}
			}
			.info__label {
				display: block;
			}
			.info__timer {
				display: block;
				font-size: em(20px);
				font-weight: bold;
				margin-top: 10px;
			}
			.info__action {
				margin-top: 30px;
			}
		}
	}
}

.event-view .questions-container {
	$controlsWidth: 40px;

	padding-top: 20px;
	padding-bottom: 20px;

	.questions-header {
		overflow: hidden;
		.title {
			font-size: em(18px);
		}

		.hint {
			float: right;

			.label {
				color: gray;
				font-size: em(14px);
			}

			.progress-box {
				height: 3px;
				background: $bgColor-23;
				position: relative;
				margin-top: 3px;
				overflow: hidden;
				.bar {
					position: absolute;
					left: 0;
					top: 0;
					background: #60B969;
					height: 100%;
					width: 0;
					transition: width 100ms ease-in-out;
				}
			}
		}
	}

	.questions-list {
		margin-top: 15px;
		margin-bottom: 15px;

		&.with-controls .questions-list-item {
			.question-text {
				margin-right: $controlsWidth;
			}

			&:hover .question-controls {
				display: block;
			}
		}
	}

	.questions-list-item {
		$votesWidth: 100px;
		border: 1px solid $borderColor-26;
		position: relative;
		overflow: hidden;
		min-height: 60px;

		&:not(:last-of-type) {
			border-bottom: none;
		}

		.votes-container {
			padding: 0 10px;
			position: absolute;
			top: 0;
			left: 0;
			width: $votesWidth;
			height: 100%;

			.votes-inner-container {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				text-align: center;
			}
		}

		.votes-container {
			.value {
				@include font-helvetica-light;
				color: $textColor-32;
				font-size: em(18px);
			}

			.action {
				margin-top: 3px;
				white-space: nowrap;
				.hint {
					font-size: em(14px);
					color: $textColor-15;
				}
			}

			button {
				font-size: em(14px);
				color: $textColor-32;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.question-text {
			padding: 10px 10px 10px 0;
			margin-left: $votesWidth + 20px;
			line-height: 1.4;
			white-space: pre-line;
		}

		.question-controls {
			display: none;
			border-left: 1px solid $borderColor-26;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: $controlsWidth;

			.md-button {
				border-radius: 0;
				margin: 0;
				padding: 0;
				min-width: 0;
				min-height: 0;
				line-height: 1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}
