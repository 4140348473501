.userbar {
	$width: $userBarWidth;
	display: block;
	width: $width;
	box-sizing: border-box;
	position: fixed;
	padding-top: $headerHeight;
	top: 0;
	left: 50%;
	margin-left: ceil(-$width - $wrapperWidth / 2);
	z-index: 70;

	.separator-line {
		display: block;
		margin: 0 auto;
		height: 1px;
		width: 70px;
		background: darken(#F8FAFB, 8%);
	}
}

.userbar {
	.user_link {
		display: block;
		padding: 10px 0;

		@include transition-bg-color();

		.image-container {
			display: block;
			position: relative;
			width: 60px;
			height: 60px;
			margin: 0 auto;
			padding: 3px;
		}

		&:hover {
			background: darken(#F8FAFB, 3%);
			.user_name {
				color: $textColor-20;
			}
		}
	}

	.image__user {
		width: 100%;
		height: 100%;
	}

	.user_name {
		color: $textColor-24;
		font-size: em(15px);
		display: block;
		text-align: center;
		margin-top: 8px;
		line-height: 1.2;
		padding: 0 4px;
		overflow: hidden;
		text-overflow: ellipsis;
		@include transition-color();
	}

	.menu__link {
		color: $textColor-25;
		display: block;
		padding: 11px 2px;
		transition: color getTimeByName('fast') ease-out, background-color getTimeByName('fast') ease-out;
		position: relative;
		.icon {
			font-size: 28px;
			display: block;
			text-align: center;
		}

		.label {
			font-size: em(13px);
			display: block;
			text-align: center;
			padding-top: 6px;
			line-height: 1.15;
		}

		&:hover {
			background: darken(#F8FAFB, 3%)
		}

		&.active {
			color: $textColor-30;
		}

		.badge {
			position: absolute;
			display: inline-block;
			width: 22px;
			height: 22px;
			background-color: $borderColor-13;
			border-radius: 100%;
			right: 5px;
			top: 5px;
			text-align: center;
			font-size: 11px;
			line-height: 22px;
			color: $textColor-10;
			transition: all 0.3s ease;
		}
	}
}

// Next events block
.userbar {
	.next-events {
		margin-top: 20px;
	}

	.event {
		position: relative;
		&:hover {
			.event__teaser {
				&::before {
					display: block;
				}

				.date, .time {
					color: $textColor-23;
				}
			}
			.event__info {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.event__teaser,
	.event__info {
		padding: 14px 0;
	}

	.event__teaser {
		position: relative;
		display: block;
		z-index: 85;

		&::before {
			content: '';
			display: none;
			border-right: 1px solid $borderColor-20;
			height: 65%;
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translateY(-50%);
		}

		.date, .time, .type {
			display: block;
			text-align: center;
			line-height: 1.1;
			@include transition-color('fast');
		}

		.date {
			font-weight: bold;
			font-size: em(18px);
			color: $textColor-25;
		}

		.time {
			font-size: em(20px);
			color: $textColor-25;
			@include font-helvetica-light();
		}

		.type {
			font-size: em(13px);
			color: $textColor-40;
		}
	}

	.event__info {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		overflow: hidden;
		padding-left: 100px;
		padding-right: 20px;
		background: $bgColor-20;
		border: 1px solid $borderColor-20;
		border-radius: 5px;
		box-sizing: border-box;
		z-index: 82;
		opacity: 0;
		visibility: hidden;
		transition: opacity getTimeByName('fast') ease-out, visibility getTimeByName('fast');

		.name, .org {
			line-height: 1.1;
			color: $textColor-23;
			white-space: nowrap;
			display: block;
			@include transition-color('fast');
		}

		.name {
			font-weight: bold;
			&:hover {
				color: $textColor-27;
			}
		}

		.org {
			padding-top: 4px;
			font-size: em(14px);
		}
	}
}
