.profile-header {
	position: relative;
	height: 240px;
	border-bottom: 4px solid $borderColor-23;
	background-image: linear-gradient(-179deg, #B9B9B9 0%, #868686 100%);
	background-color: $borderColor-29;

	.background {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}

	&.has-bg::before {
		content: '';
		display: block;
		background-image: linear-gradient(89deg, rgba(#000,0.01) 20%, rgba(#000,0.6) 80%);
		background-color: rgba(#000, 0.1);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 11;
	}

	.profile-header-wrapper {
		@extend .c-wrapper;
		position: relative;
		top: 27px;
		z-index: 90;
		height: 184px;
	}

	.info {
		height: 100%;
		position: relative;
		padding: 5px 0;
		padding-left: 184px + 50px;
		box-sizing: border-box;
	}
}
