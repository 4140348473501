.b-alphabet {
	white-space: nowrap;
	overflow: hidden;
	vertical-align: top;
	user-select: none;
	.b-tokens {
		position: relative;
		vertical-align: top;
		display: inline-block;
		&_head, &_content {
			display: inline-block;
			overflow: hidden;
			vertical-align: top;
		}
		&_content {
			width: 0;
		}
		&_head {
			width: 55px;
		}
		&.active {
			width: 580px;
			.b-tokens_head {
				width: 0;
			}
			.b-tokens_content {
				width: 580px;
			}
		}
	}
	.l-token {
		display: inline-block;
		margin: 0 1px;
		padding: 1px 2px;
		font-size: 18px;
		line-height: 18px;
		color: $textColor-22;
		transition: all 0.3s ease;
		border-bottom: 1px dotted transparent;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			color: $textColor-32;
			border-bottom-color: $textColor-22;
		}
		&.active {
			color: $textColor-30;
			cursor: default;
			&:hover {
				border-bottom-color: transparent;
			}
		}
		&.disable {
			color: $textColor-16;
			cursor: default;
			&:hover {
				border-bottom-color: transparent;
				color: $textColor-16;
			}
		}
		span {
			font-size: 18px;
			line-height: 18px;
		}
	}
}

.b-filter-table {
	display: table;
	width: 100%;
	padding: 0 30px;
	box-sizing: border-box;
	margin-bottom: 15px;
	.b-filter-cell {
		display: table-cell;
		height: 21px;
		vertical-align: top;
		&.button {
			width: 65px;
		}
		.b-button_token-all {
			display: inline-block;
			margin: 0 1px;
			padding: 1px 2px;
			font-size: 18px;
			line-height: 18px;
			color: $textColor-22;
			transition: all 0.3s ease;
			border-bottom: 1px dotted transparent;
			cursor: pointer;
			&:hover {
				color: $textColor-32;
				border-bottom-color: $textColor-22;
			}
			&[disabled] {
				color: $textColor-16;
				cursor: default;
				&:hover {
					border-bottom-color: transparent;
					color: $textColor-16;
				}
			}
		}
	}
}
