.request-list > .users-list > .row {
	border-bottom: 0 solid #E3E3E3;
	padding: 10px 10px 0;
}

.users-list {
	& > .row {
		border-bottom: 1px solid $borderColor-20;
		padding: 8px 10px;
	}
}

.user-media-box {
	display: table;
	width: 100%;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	box-sizing: border-box;
	border-radius: 8px;
	table-layout: fixed;
	& > .col {
		display: table-cell;
		vertical-align: middle;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		&:not(.col-stretch) {
			width: auto;
			white-space: nowrap;
		}
		&:first-of-type {
			border-left: none;
			width: 65px;
		}
		&:last-of-type {
			border-right: none;
		}
	}

	.col-contacts {
		.col-contact {
			cursor: default;
			font-size: em(14px);
			line-height: 1.2;
			.col-contact-title {
				color: $bgColor-53;
				width: 70px;
				text-align: right;
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
			}
			.col-contact-content {
				color: $textColor-21;
				display: inline-block;
				vertical-align: middle;
			}
			a:hover {
				color: $textColor-32;
			}
		}
	}

	.state-container {
		position: relative;
		.state-user {
			position: absolute;
			bottom: -2px;
			right: -5px;
			width: 21px;
			height: 21px;
			background-color: $bgColor-23;
			border: 2px solid $borderColor-24;
			border-radius: 100%;
			box-sizing: border-box;
			cursor: pointer;
			&.confirmed {
				background-color: $bgColor-10;
			}

		}
	}
}

// user-media-box elements

.user-media-box {
	.image {
		$transitionTime: 100ms;
		$transitionFunction: linear;
		position: relative;
		display: block;

		.badge {
			position: absolute;
			&.badge-role {
				bottom: -4px;
				right: 3px;
			}
		}

		.image__user {
			transition: transform $transitionTime $transitionFunction;
		}

		&:hover .image__user {
			transform: scale(1.1);
		}
	}

	.name {
		color: $textColor-23;
		font-size: $fontSize;
		font-weight: normal;
		word-wrap: break-word;

		.main-name {
			font-size: em(17px);
		}
		.additional-name {
			font-size: em(14px);
			margin-top: 3px;
			&::before {
				content: "\a";
				white-space: pre;
				font-size: 0;
			}
		}
		a:hover {
			color: $textColor-32;
		}
	}

	.location-name {
		font-size: em(14px);
	}
	.location-icon {
		font-size: em(13px);
		position: relative;
		top: 1px;
		margin-right: 5px;
	}
	.location-wrapper {
		margin-top: 4px;
		color: $textColor-13;
	}

	.actions {
		white-space: nowrap;
		text-align: right;
		& > .action {
			margin-right: 10px;
			display: inline-block;
			vertical-align: middle;
			&:last-of-type {
				margin-right: 0;
			}
		}

		.request-hint {
			margin-right: 15px;
		}
	}
}

// user-media-box states

.user-media-box.highlighted {
	background-color: $bgColor-11;
	color: $textColor-10;
	& > .col {
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
	}

	.name,
	.location-wrapper {
		color: $textColor-10;
	}

	.name a:hover {
		color: $textColor-10;
	}
}
