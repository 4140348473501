@mixin alignment() {
	.text-center {
		text-align: center;
	}
	.text-right {
		text-align: right;
	}
}


.redactor-editor {
	@include alignment;
	font-size: $articleFontSize;

	//p {
	//	line-height: 1.5;
	//}

	// Line height and margin
	p,
	ul,
	ol,
	dl,
	blockquote,
	hr,
	pre,
	table,
	figure,
	address {
		margin-bottom: 16px;
		line-height: inherit;
	}

	ul,
	ol,
	ul ul,
	ol ol,
	ul ol,
	ol ul {
		margin-left: $base-line * 1.5;
	}

	// quote
	blockquote {
		position: relative;
		font-style: italic;
		color: rgba(0, 0, 0, .6);
		& cite {
			font-size: 80%;
		}
	}
}

.redactor-editor {
	ul {
		list-style: disc;
	}
	ol {
		list-style: decimal;
	}
}

.redactor-box .redactor-editor {

	// terms
	span[term-definition] {
		color: $textColor-32;
		background-color: $bgColor-55;
		box-sizing: border-box;
		border: 1px dashed transparent;
		border-radius: 3px;
		text-decoration: none;
		cursor: pointer;
		padding: 0 3px;
		&:hover {
			background-color: darken($bgColor-55, 1);
			border-color: $textColor-32;
		}
	}

	// videos
	content-video button[remove-video] {
		@include font-material;
		@extend .icon-m-close;
		position: absolute;
		right: 0;
		top: 0;
		border: 1px solid $borderColor-12;
		color: $textColor-31;
		background-color: $bgColor-20;
		cursor: pointer;
		transition: transform 100ms ease;
		font-size: 16px;
		padding: 3px;
		z-index: 10;
		&:hover {
			transform: scale(1.2);
		}
	}

	.video-container {
		overflow: hidden;
	}
}

// videos
.redactor-editor {
	figure[data-type-video] {
		display: block;
		position: relative;
		user-select: none;
	}
}

// OLD Styles

.html-styles {
	@include alignment;

	[term-definition] {
		color: $textColor-32;
		text-decoration: underline;
		transition: color 100ms ease;
		cursor: pointer;
		&:hover {
			color: darken($textColor-32, 10%);
		}
	}
}

// Content Term Popover
.html-styles [term-definition] {
	position: relative;
	display: inline-block;
	&:hover .popover-simple {
		display: block;
		opacity: 1;
		visibility: visible;
	}

	.popover-simple {
		font-size: $fontSize - 2px;
		color: $textColor;
		transform: translateY(0) translateX(-50%);
		top: auto;
		left: 50%;
		min-width: 0;
		max-width: none;
		width: auto;
		cursor: auto;
		transition: all 0.3s ease;
		opacity: 0;
		visibility: hidden;
		display: block;
		font-weight: normal;
		&.bottom {
			margin-top: 8px;
			&::before {
				$height: 10px;
				content: '';
				display: block;
				position: absolute;
				top: -$height;
				left: 0;
				width: 100%;
				height: $height;
			}
		}
	}

	p {
		line-height: 1.4;
	}

	.popover-simple-content {
		padding: 0;
		position: relative;
		&:after, &:before {
			content: '';
			left: 0;
			right: 0;
			position: absolute;
			height: 10px;
		}
		&:before {
			top: 0;
			background: linear-gradient(top, #fff 0%, rgba(255, 255, 255, 0) 100%);
		}
		&:after {
			bottom: 0;
			background: linear-gradient(bottom, #fff 0%, rgba(255, 255, 255, 0) 100%);
		}
	}

	.popover-simple-content-wrapper {
		padding: 10px;
		box-sizing: border-box;
		overflow: hidden;
		overflow-y: auto;
		max-height: 12em;
		min-width: 300px;
		max-width: 400px;
		p {
			&:last-of-type {
				margin-bottom: 0 !important;
			}
		}
		a {
			transition: color 0.3s ease;
		}
		.term__title {
			font-weight: bold;
			color: $textColor-21;
			&:hover {
				color: darken($textColor-21, 20%);
			}
		}
		.l-more {
			color: $textColor-30;
			text-decoration: underline;
			position: relative;
			&:after {
				content: "\e611";
				font-family: 'absolutera';
				position: absolute;
				font-size: 10px;
				line-height: 1;
				right: -13px;
				padding-left: 3px;
				top: 50%;
				margin-top: -5px;
			}
			&:hover {
				color: darken($textColor-30, 20%);
			}
		}
	}
}


// old
.html-styles {
	p, ul, ol, table, dl, blockquote, pre {
		line-height: 1.5;
		margin-bottom: 0.75em !important;
	}

	blockquote {
		margin-left: 1.5em;
		font-style: italic;
	}

	/*
		Lists
	*/

	ul, ol {
		padding-left: 2em !important;
	}
	ul ul, ol ol, ul ol, ol ul {
		margin: 2px;
		padding: 0 0 0 2em;
	}
	ul {
		list-style: disc;
	}
	ol {
		list-style: decimal;
	}
	dl dt {
		font-weight: bold;
	}
	dd {
		margin-left: 1em;
	}

	/*
		Tables
	*/

	table {
		border-collapse: collapse;
		font-size: 1em !important;
		td {
			padding: 5px !important;
			border: 1px solid #ddd;
			vertical-align: top;
		}
		thead td {
			border-bottom: 2px solid #000 !important;
			font-weight: bold !important;
		}
	}

	/*
		Other
	*/

	object, embed, video, img {
		max-width: 100%;
		width: auto;
	}
	video, img {
		height: auto;
	}

	hr {
		display: block;
		height: 1px;
		border: none;
		border-top: 1px solid #ccc;
		margin: 2em 0 3em;
	}

	code {
		background-color: #d8d7d7 !important;
	}

	pre {
		overflow: auto;
		padding: 1em !important;
		border: 1px solid #ddd !important;
		border-radius: 3px !important;
		background: #f8f8f8 !important;
		white-space: pre;
		font-size: 90% !important;
	}

	iframe, object {
		margin-bottom: em(10px);
	}
}

// new
.html-styles {
	span[redactor-question] {
		display: none;
		user-select: none;
	}

	.text-center {
		text-align: center;
	}
	.text-right {
		text-align: right;
	}
}

.html-styles {
	font-family: $content-font-family;
	font-size: $font-size;
	line-height: $base-line;
	color: $body-color;

	&[dir="rtl"] {
		direction: rtl;
		unicode-bidi: embed;

		& ul,
		& ol {
			& li {
				text-align: right;
			}
		}
		ul,
		ol,
		ul ul,
		ol ol,
		ul ol,
		ol ul {
			margin: 0 $base-line 0 0;
		}
	}

	@media (max-width: $breakpoint-small) {
		font-size: 16px;
	}

	// links
	a {
		color: $link-color;
		&:focus,
		&:hover {
			color: $link-hover-color;
		}
	}

	// headings
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $headings-font-family;
		font-weight: bold;
		color: $headings-color;
		text-rendering: optimizeLegibility;
		margin: 0;
		padding: 0;
		margin-bottom: 16px;
	}
	h1 {
		font-size: $font-size-h1;
		line-height: 44px;
	}
	h2 {
		font-size: $font-size-h2;
		line-height: 36px;
	}
	h3 {
		font-size: $font-size-h3;
		line-height: 32px;
	}
	h4 {
		font-size: $font-size-h4;
		line-height: $base-line;
	}
	h5 {
		font-size: $font-size-h5;
		line-height: $base-line;
	}
	h6 {
		font-size: $font-size-h6;
		line-height: $base-line;
		text-transform: uppercase;
	}

	// Line height and margin
	p,
	ul,
	ol,
	dl,
	blockquote,
	hr,
	pre,
	table,
	figure,
	address {
		padding: 0;
		margin: 0;
	}
	ul,
	ol,
	ul ul,
	ol ol,
	ul ol,
	ol ul {
		margin: 0 0 0 $base-line;
	}
	ul li,
	ol li {
		text-align: left;
	}
	ol ol li {
		list-style-type: lower-alpha;
	}
	ol ol ol li {
		list-style-type: lower-roman;
	}
	p,
	ul,
	ol,
	dl,
	blockquote,
	hr,
	pre,
	table,
	figure,
	address {
		margin-bottom: 16px;
	}

	// quote
	blockquote {
		position: relative;
		font-style: italic;
		color: rgba(0, 0, 0, .6);
		& cite {
			font-size: 80%;
		}
	}

	@media (min-width: $breakpoint-small) {

		blockquote {
			padding-left: $base-line;
			border-left: 1px solid rgba(0, 0, 0, .1);
		}

	}

	// address
	address {
		font-style: normal;
	}

	// definition list
	dl dt {
		font-weight: bold;
	}
	dd {
		margin-left: $base-line;
	}

	// text-level
	cite {
		color: rgba(0, 0, 0, .5);
		font-style: italic;
	}
	s,
	del {
		text-decoration: line-through;
	}
	abbr[title],
	dfn[title] {
		border-bottom: 1px dotted #000;
		cursor: help;
	}
	strong,
	b {
		font-weight: bold;
	}
	em,
	i {
		font-style: italic;
	}
	sub,
	sup {
		font-size: 10px;
		line-height: 0;
		position: relative;
		margin-left: .2rem;
	}
	sup {
		top: -.4rem;
	}
	sub {
		bottom: -.2rem;
	}
	figcaption {
		margin: .3rem 0;
		font-size: 11px;
		font-style: italic;
		text-align: left;
	}
	ins,
	u {
		text-decoration: underline;
	}
	mark {
		background-color: $mark-background-color;
		color: #000;
		text-decoration: none;
	}

	// сode
	pre,
	code,
	kbd,
	samp,
	var,
	output {
		font-size: 90%;
		font-style: normal;
		font-family: $monospace-font-family;
	}
	pre {
		margin-top: 16px;
		font-size: 90%;
		line-height: $base-line;
		color: rgba(0, 0, 0, .75);
		overflow: auto;
		background: rgba(0, 0, 0, .03);
		padding: 24px;
		word-wrap: normal;
	}
	mark,
	code,
	samp,
	kbd {
		padding: .2rem .4rem;
		display: inline-block;
		line-height: 1;
		border-radius: 4px;
	}
	code {
		background: $code-background-color;
	}
	pre code {
		font-size: 100%;
		border: none;
		padding: 0;
		background: none;
		line-height: $base-line;
	}
	var {
		color: rgba(0, 0, 0, .5);
	}
	samp {
		background: #46a9fc;
		color: rgba(255, 255, 255, .9);
	}
	kbd {
		background: #000;
		color: rgba(255, 255, 255, .85);
		white-space: nowrap;
	}

	// Normalize horizontal line
	hr {
		display: block;
		box-sizing: content-box;
		height: 1px;
		border: 0;
		border-top: 1px solid rgba(0, 0, 0, .1);
	}

	// Responsive media
	img,
	video,
	audio,
	embed,
	object {
		max-width: 100%;
	}
	img,
	video,
	embed,
	object {
		height: auto;
	}
	embed,
	object {
		height: 100%;
	}
	img {
		vertical-align: middle;
		-ms-interpolation-mode: bicubic;
	}

	// tables
	table {
		border-collapse: collapse;
		max-width: 100%;
		width: 100%;
		empty-cells: show;

		caption {
			text-transform: uppercase;
			padding: 0;
			color: rgba(0, 0, 0, .5);
			font-size: 11px;
		}

		th,
		td {
			border: 1px solid #eee;
			padding: 16px;
			padding-bottom: 15px;
		}

		tfoot th,
		tfoot td {
			color: rgba(0, 0, 0, .5);
		}
	}

	// Responsive embedded objects
	& .video-container {
		height: 0;
		padding-bottom: 56.25%; // ratio 16:9
		position: relative;
		margin-bottom: 16px;
		overflow: hidden;
		& iframe,
		& object,
		& embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
			z-index: 2;
		}
	}
}
