.image-teaser {
	display: block;
	//overflow: hidden;
	.image-container {
		font-size: 0;
	}
	.image {
		max-width: 100%;
	}


	.controls {
		display: none;
		.action-container {
			float: left;
			margin-left: 7px;
			&:first-of-type {
				margin-left: 0;
			}
		}

		.action {
			display: inline-block;
			border-radius: 50%;
			text-align: center;
			background-color: $bgColor-20;
			width: 26px;
			height: 26px;
			font-size: 11px;
			padding-top: 1px;
			cursor: pointer;
			border: 1px solid $borderColor-55;
			&.warning {
				color: $textColor-51;
				border-color: $textColor-51;
			}
		}
	}
}

.image-teaser.type-default {
	$height: 130px;
	height: $height;
	position: relative;
	transition: 100ms transform;
	.image-container {
		display: block;
		width: 100%;
		height: 100%;
		@include block-fill-image;
		background: #fff;
	}

	.controls {
		position: absolute;
		z-index: 5;
		top: -10px;
		right: -10px;
	}

	&:hover {
		z-index: 5;
		transform: scale(1.1);
		.image-container {
			outline: 4px solid #fff;
			box-shadow: 0 0 16px 2px rgba(#000,0.5);
		}
		.controls {
			display: block;
		}
	}
}


.image-teaser.type-single {
	.image {
		max-height: 340px;
	}
}
