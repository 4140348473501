.b-search__calendar {
	@extend %b-search-table;
	@extend %b-search-border-top;
	padding-top: 20px;
	min-height: 301px;

	.mounth-name {
		font-size: em(18px);
		color: $textColor-18;
		text-align: center;
		text-transform: capitalize;
		padding-bottom: 10px;
	}
	.datetimepicker {
		margin: 0 auto;
		thead tr:first-of-type {
			display: none;
		}

		&.datetimepicker-bordered {
			table td.day.disabled.active {
				color: $textColor-10;
				background-color: $bgColor-11;
				font-weight: bold;
			}
		}
	}
}

.b-calendar__btn {
	@extend %b-search-cell;
	vertical-align: middle;
	width: 1px;
}

