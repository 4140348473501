.menu_tabs {
	margin-top: 10px;
	border-bottom: 1px solid $borderColor-23;
	@include pie-clearfix;
	@include no-select;

	li {
		float: left;
		border: 1px solid transparent;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		position: relative;
		bottom: -1px;
		&.active {
			border-color: $borderColor-23 $borderColor-23 $borderColor-24;
			a {
				color: $textColor-30;
				cursor: default;
			}
		}
		&.right {
			float: right;
		}
		&.link a {
			padding-right: 0;
			padding-left: 0;
		}
	}

	a {
		display: block;
		padding: 12px 25px;
		color: $textColor-13;
		&:hover {
			color: $textColor-30;
		}
	}
}

.menu_tabs.menu_tabs_buttons {
	margin: 0;
	padding-top: 20px;
	padding-bottom: 20px;

	li {
		border: none;
		border-radius: 0;
		bottom: auto;
		position: static;
		margin: 0 5px;

		&:first-of-type {
			margin-left: 0;
		}
		&:last-of-type {
			margin-right: 0;
		}

		&.active a {
			color: $textColor-10;
			border-color: $bgColor-11;
			background-color: $bgColor-11;
			&:hover {
				background-color: $bgColor-11;
			}
		}

		a {
			padding: 8px 20px;
			border-radius: 5px;
			white-space: nowrap;
			color: $textColor-23;
			border: 1px solid $borderColor-21;
			background-color: $bgColor-20;
			&:hover {
				background-color: rgba($bgColor-57, 0.5);
			}

			.badge {
				font-size: em(12px);
				margin-left: 8px;
				margin-right: -12px;
				@include font-helvetica-light;
			}
		}

		a.action-green {
			display: inline-block;
			color: $textColor-10;
			border-color: $bgColor-11;
			background-color: $bgColor-11;
			&:hover {
				background-color: $bgColor-13;
			}
		}
		a.action-white {
			display: inline-block;
			padding: 6px;
			margin-left: 10px;
		}
	}
}
