.events-list {
}

.events-list-item {
	padding: 0 30px;
	margin: 20px 0;
}

// Processing Events Lists
.processing-events-subscribes {
	.event-media-box {
		border-color: $borderColor-12;
		.presentation {
			border-right-color: $borderColor-12;
		}
	}
}

// Subscribes
.processing-events-subscribes {
	.processing-materials-list {
		min-height: 240px;
	}

	.event-media-box {
		position: relative;
	}
	.event-media-box::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 20px 20px;
		border-color: transparent transparent $borderColor-12 transparent;
	}

	.events-list-item {
		position: relative;
	}

	.controls {
		$margin: 8px;
		margin: 0 (-$margin);
		position: absolute;
		bottom: 15px;
		right: 20px;
		white-space: nowrap;
		button {
			float: left;
			margin: 0 $margin;
		}
	}
}
