.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 100;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: $bgColor-20;
	background-clip: padding-box;
	border: 1px solid $borderColor-56;
	border-radius: 6px;
	box-shadow: 0 0 4px 0 rgba(#000, 0.1);

	&::after,
	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border: 10px solid transparent;
	}
	&::before {
		border-width: 11px;
	}

	& > li > a {
		display: block;
		padding: 3px 20px;
		clear: both;
		line-height: 1.7;
		color: $textColor-13;
		white-space: nowrap;

		&:hover,
		&:focus {
			color: $textColor-23;
			text-decoration: none;
			background-color: $bgColor-24;
		}
	}

	.divider {
		height: 1px;
		margin: 7px 0;
		overflow: hidden;
		background-color: #e5e5e5;
	}
}


.dropdown-menu.bottom-right {
	&::after,
	&::before {
		top: -10px;
		right: 5px;
		border-top-width: 0;
		border-bottom-color: $bgColor-20;
	}
	&::before {
		top: -11px;
		right: 4px;
		border-bottom-color: $borderColor-56;
	}
}
