.b-term__images {
	display: block;
	position: relative;
	border-top: 1px solid $borderColor-23;
	white-space: nowrap;
	padding: 30px;
	overflow: hidden;

	.owl-item {
		width: 197px;
		overflow: hidden;
		vertical-align: top;
		.b-image_center {
			display: block;
			height: 140px;
			@include block-fill-image;
		}
	}
}

.wiki_images {
	.images-list {
		padding-bottom: 0;
		margin-top: 0;
		padding-top: 30px;
	}

	.images-list-item {
		width: 25%;
	}
}
