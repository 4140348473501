.groups-list {}

.groups-list-item {
	padding: 0 30px;
	margin: 20px 0;
	&:not(:last-of-type) {
		padding-bottom: 20px;
		border-bottom: 1px solid $borderColor-20;
	}
}
