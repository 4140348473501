.header-background-form {
	.modal-dialog {
		width: 742px !important;
	}

	.headers-bg-list {
		overflow: hidden;

		.image-container {
			float: left;
			cursor: pointer;
			margin: 10px;
			position: relative;

			&::after {
				content: '';
				display: block;
				background: #000;
				position: absolute;
				z-index: 9;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.1;
				@include transition-opacity('fast');
			}

			&:not(.active):hover::after {
				opacity: 0;
			}

			.icon {
				@include circle(70px);
				color: $textColor-30;
				position: absolute;
				z-index: 10;
				top: 50%;
				left: 50%;
				margin-left: -35px;
				margin-top: -35px;
				text-align: center;
				font-size: 30px;
				line-height: 70px;
				display: none;
			}

			&.active {
				.icon {
					display: block;
				}
				&::after {
					background: #FFF;
				}
			}
		}

		.image {
			display: block;
			width: 321px;
			height: 122px;
		}
	}
}
