#modal-article {
	.modal-dialog {
		overflow: visible;
		margin-top: 90px;
		@include pie-clearfix;
	}

	.modal-content {
		padding: 0;
		overflow: visible;
		@include pie-clearfix;
	}
	&.show-player {
		.a-footer {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}
