.owl-carousel {
	display: block;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	white-space: nowrap;
	z-index: 1;

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		white-space: nowrap;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		white-space: nowrap;
		transform: translate3d(0px, 0px, 0px);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		display: inline-block;
		vertical-align: middle;
		-webkit-backface-visibility: visible;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}

	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: hand;
		user-select: none;
	}

	&.owl-loaded {
		display: block;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		display: none;
	}

	&.owl-drag .owl-item {
		user-select: none;
	}

	&.owl-grab {
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;
	}

	&.owl-rtl .owl-item {
		float: right;
	}

	.owl-nav {
		.owl-prev, .owl-next {
			background-color: rgba(#000, 0.3);
			width: 25px;
			height: 100%;
			cursor: pointer;
			position: absolute;
			top: 0;
			color: $textColor-10;
			font-size: 20px;
			line-height: 1;
			&:before {
				@include font-absolutera;
				position: absolute;
				margin: -10px -5px;
				left: 50%;
				top: 50%;
			}
		}
		.owl-prev {
			left: 0;
			@extend .icon-triangle-left;
		}
		.owl-next {
			right: 0;
			@extend .icon-triangle-right;
		}

		.disabled {
			display: none;
		}
	}
}


.owl-carousel.style-2 {
	.owl-nav {
		$width: 30px;
		$size: 35px;

		.owl-prev, .owl-next {
			background: none;
			width: $width;
			font-size: 25px;
			&::before {
				@include font-absolutera;
				position: absolute;
				margin-top: -$size/2;
				margin-left: -$size/2;
				left: 50%;
				top: 50%;
				border: 1px solid $textColor-14;
				color: $textColor-14;
				border-radius: 50%;
				width: $size;
				height: $size;
				text-align: center;
				padding-top: 3px;
				box-sizing: border-box;
				transition: all 100ms ease-in-out;
			}

			&:hover::before {
				border-color: $textColor-30;
				color: $textColor-30;
				transform: scale(1.1);
			}
		}
		.owl-prev {
			left: -$width;
			@extend .icon-angle-left;
			&:before { padding-right: 3px; }
		}
		.owl-next {
			right: -$width;
			@extend .icon-angle-right;
			&:before { padding-left: 3px; }
		}
	}
}
