.group-add-material-options {
	text-align: center;
	width: 400px;
	padding: 20px 0;
	margin: 0 auto;

	.option-hint {
		line-height: 1.4;
		padding-top: 5px;
	}

	.option-button {
		margin: 25px 0;
		width: 200px;
		display: inline-block;
	}

	.option-divider {
		width: 70%;
		margin: 0 auto;
		position: relative;

		&::before, &::after {
			content: '';
			display: block;
			height: 1px;
			background: $borderColor-21;
			width: 40%;
			position: absolute;
			top: 50%;
			margin-top: 1px;
		}

		&::before {
			left: 0;
		}
		&::after {
			right: 0;
		}
	}
}
