.separated-sections {
  .section {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $borderColor-23;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .section__header {
    padding-bottom: 15px;
    display: table;
    width: 100%;
    box-sizing: border-box;
  }

  .section__label,
  .section__actions {
    display: table-cell;
  }

  .section__label {
    width: 100%;
    vertical-align: middle;
    color: $textColor-26;
    &.with-suffix::after {
      content: ':';
    }
    &.size-large {
      font-size: em(20px);
    }
  }

	.section__header.actions-inline {
		.section__label,
		.section__actions {
			display: inline-block;
			width: auto;
		}
		.section__actions {
			margin-left: 0.5em;
		}
	}
}
