.info__owner {
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	word-wrap: normal;
	font-size: 0;

	.owner-image-container {
		display: inline-block;
		line-height: inherit;
		vertical-align: middle;
	}

	.image__user,
	.image__group {
		width: 32px;
		height: 32px;
		font-size: $fontSize;
	}
	.image__group {
		border-radius: 3px;
	}

	.owner__details {
		display: inline-block;
		font-size: $fontSize;
		vertical-align: middle;
		padding-left: 8px;
		.name {
			font-size: em(15px);
			color: $textColor-23;
			line-height: 1;
			display: inline-block;
			max-width: 530px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
