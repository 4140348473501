.page-event {
  padding-bottom: 200px;

	.event-image {
		width: 240px;
		height: 184px;
		border: 2px solid $borderColor-24;
		border-radius: 8px;
		box-sizing: border-box;
	}
}

.event__details {
  border-bottom: 1px solid $borderColor-23;
  padding-top: 25px;
  padding-bottom: 25px;
  @include pie-clearfix;

  .b-details {
    float: left;
    margin-right: 12px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .b-details__header {
    padding-bottom: 9px;
    display: block;
    &.label:after {
      content: ':';
    }
  }

  .b-details__item {
    display: block;
    padding: 3px 0;
    line-height: 20px;
    text-align: left;
  }
}

.event-dates {
	.b-details__item {
		font-weight: bold;
		font-size: em(18px);
	}

	.b-details-empty .b-details__item {
		font-weight: normal;
		font-size: em(16px);
	}
}
