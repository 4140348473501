.widget-input-rooms-matrix {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;

	th {
		padding: 4px 0;
		font-size: em(14px);
	}
	td {
		height: 37px;
		position: relative;
		&.counter::after {
			content: '.';
		}
		&.value {
			position: relative;
			&::before {
				content: '';
				display: block;
				background-color: $bgColor-52;
				height: 1px;
				width: 13px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -1px;
			}
		}

		.i-field-error {
			right: 4px;
			top: 9px;
		}
	}

	.name {
		min-width: 200px;
	}

	.value {
		width: 100%;
		padding-left: 13px;
	}

	.actions {
		text-align: right;
		white-space: nowrap;
		padding-left: 20px;

	}

	.action-remove {
		cursor: pointer;
		color: $textColor-51;
		font-size: em(14px);
		&:hover {
			color: darken($textColor-51, 20%);
		}
	}

	.textfield {
		width: 100%;
	}
}
