.b-term_info {
	display: inline-block;
	position: absolute;
	&.definition {
		right: 10px;
		top: 27px;
	}
	&.faq {
		right: -30px;
		top: 0;
	}
	.btn-details {
		$size: 26px;
		width: $size;
		height: $size;
		background-color: $bgColor-20;
		box-shadow: 0 0 1px 0 rgba(#000, 0.3);
		border-radius: 50%;
		cursor: pointer;
		&::after {
			content: '?';
			display: block;
			font-weight: bold;
			text-align: center;
			color: $textColor-31;
		}
		&:hover {
			background-color: darken($bgColor-20, 3%);
		}
	}
	.details {
		&__container {
			font-size: em(13px);
			line-height: 1.4;
			min-width: 150px;
			margin: 0 !important;
			padding: 0 !important;
		}
		&__row {
			padding-bottom: 5px;
			list-style: none;
			a {
				display: inline-block;
				max-width: 100%;
				text-overflow: ellipsis;
				color: $textColor-30;
				text-decoration: underline;
				transition: color 0.3s ease;
				overflow: hidden;
				white-space: nowrap;
				&:hover {
					text-decoration: none;
				}
			}
		}
		&__label,
		&__value {
			display: block;
		}
		&__label {
			color: $textColor-12;
		}
	}
	.tags {
		text-align: left;
		span {
			margin-right: 5px;
			&:after {
				content: ', ';
			}
			&:last-child {
				margin-right: 0;
				&:after {
					content: '';
				}
			}
		}
		a {
			display: inline;
			color: $bgColor-41;
		}
	}
}
