@font-face {
	font-family: "material";
	src: url('/fonts/generated/icons/material/material.eot');
	src: url('/fonts/generated/icons/material/material.eot?#iefix') format('eot'),
	url('/fonts/generated/icons/material/material.woff2') format('woff2'),
	url('/fonts/generated/icons/material/material.woff') format('woff'),
	url('/fonts/generated/icons/material/material.ttf') format('truetype'),
	url('/fonts/generated/icons/material/material.svg#material') format('svg');
}

@mixin icon-m-styles {
	font-family: "material";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon-m {
	@include icon-m-styles;
}

@function icon-m-char($filename) {
	$char: "";

	@if $filename == add {
	$char: "\E001";
}
	@if $filename == add_circle {
	$char: "\E002";
}
	@if $filename == cancel {
	$char: "\E003";
}
	@if $filename == chat {
	$char: "\E004";
}
	@if $filename == check {
	$char: "\E005";
}
	@if $filename == check_circle {
	$char: "\E006";
}
	@if $filename == close {
	$char: "\E007";
}
	@if $filename == collections_bookmark {
	$char: "\E008";
}
	@if $filename == dehaze {
	$char: "\E009";
}
	@if $filename == expand_less {
	$char: "\E00A";
}
	@if $filename == expand_more {
	$char: "\E00B";
}
	@if $filename == format_align_center {
	$char: "\E00C";
}
	@if $filename == format_align_justify {
	$char: "\E00D";
}
	@if $filename == format_align_left {
	$char: "\E00E";
}
	@if $filename == format_align_right {
	$char: "\E00F";
}
	@if $filename == format_bold {
	$char: "\E010";
}
	@if $filename == format_indent_decrease {
	$char: "\E011";
}
	@if $filename == format_indent_increase {
	$char: "\E012";
}
	@if $filename == format_italic {
	$char: "\E013";
}
	@if $filename == format_line_spacing {
	$char: "\E014";
}
	@if $filename == format_list_bulleted {
	$char: "\E015";
}
	@if $filename == format_list_numbered {
	$char: "\E016";
}
	@if $filename == get_app {
	$char: "\E017";
}
	@if $filename == help {
	$char: "\E018";
}
	@if $filename == highlight_off {
	$char: "\E019";
}
	@if $filename == home {
	$char: "\E01A";
}
	@if $filename == info {
	$char: "\E01B";
}
	@if $filename == keyboard_arrow_down {
	$char: "\E01C";
}
	@if $filename == keyboard_arrow_left {
	$char: "\E01D";
}
	@if $filename == keyboard_arrow_right {
	$char: "\E01E";
}
	@if $filename == keyboard_arrow_up {
	$char: "\E01F";
}
	@if $filename == library_books {
	$char: "\E020";
}
	@if $filename == link {
	$char: "\E021";
}
	@if $filename == live_help {
	$char: "\E022";
}
	@if $filename == mode_edit {
	$char: "\E023";
}
	@if $filename == more_horiz {
	$char: "\E024";
}
	@if $filename == more_vert {
	$char: "\E025";
}
	@if $filename == navigate_before {
	$char: "\E026";
}
	@if $filename == navigate_next {
	$char: "\E027";
}
	@if $filename == ondemand_video {
	$char: "\E028";
}
	@if $filename == pause {
	$char: "\E029";
}
	@if $filename == pause_circle_filled {
	$char: "\E02A";
}
	@if $filename == photo_camera {
	$char: "\E02B";
}
	@if $filename == play_arrow {
	$char: "\E02C";
}
	@if $filename == play_circle_filled {
	$char: "\E02D";
}
	@if $filename == queue_music {
	$char: "\E02E";
}
	@if $filename == reorder {
	$char: "\E02F";
}
	@if $filename == reply {
	$char: "\E030";
}
	@if $filename == room {
	$char: "\E031";
}
	@if $filename == save {
	$char: "\E032";
}
	@if $filename == screen_share {
	$char: "\E033";
}
	@if $filename == search {
	$char: "\E034";
}
	@if $filename == settings {
	$char: "\E035";
}
	@if $filename == star {
	$char: "\E036";
}
	@if $filename == star_border {
	$char: "\E037";
}
	@if $filename == tune {
	$char: "\E038";
}
	@if $filename == wallpaper {
	$char: "\E039";
}

@return $char;
}

@mixin icon-m($filename, $insert: before, $extend: true) {
&:#{$insert} {
	@if $extend {
		@extend %icon-m;
	} @else {
		@include icon-m-styles;
	}
	content: icon-m-char($filename);
}
}

.icon-m-add {
	@include icon-m(add);
}
.icon-m-add_circle {
	@include icon-m(add_circle);
}
.icon-m-cancel {
	@include icon-m(cancel);
}
.icon-m-chat {
	@include icon-m(chat);
}
.icon-m-check {
	@include icon-m(check);
}
.icon-m-check_circle {
	@include icon-m(check_circle);
}
.icon-m-close {
	@include icon-m(close);
}
.icon-m-collections_bookmark {
	@include icon-m(collections_bookmark);
}
.icon-m-dehaze {
	@include icon-m(dehaze);
}
.icon-m-expand_less {
	@include icon-m(expand_less);
}
.icon-m-expand_more {
	@include icon-m(expand_more);
}
.icon-m-format_align_center {
	@include icon-m(format_align_center);
}
.icon-m-format_align_justify {
	@include icon-m(format_align_justify);
}
.icon-m-format_align_left {
	@include icon-m(format_align_left);
}
.icon-m-format_align_right {
	@include icon-m(format_align_right);
}
.icon-m-format_bold {
	@include icon-m(format_bold);
}
.icon-m-format_indent_decrease {
	@include icon-m(format_indent_decrease);
}
.icon-m-format_indent_increase {
	@include icon-m(format_indent_increase);
}
.icon-m-format_italic {
	@include icon-m(format_italic);
}
.icon-m-format_line_spacing {
	@include icon-m(format_line_spacing);
}
.icon-m-format_list_bulleted {
	@include icon-m(format_list_bulleted);
}
.icon-m-format_list_numbered {
	@include icon-m(format_list_numbered);
}
.icon-m-get_app {
	@include icon-m(get_app);
}
.icon-m-help {
	@include icon-m(help);
}
.icon-m-highlight_off {
	@include icon-m(highlight_off);
}
.icon-m-home {
	@include icon-m(home);
}
.icon-m-info {
	@include icon-m(info);
}
.icon-m-keyboard_arrow_down {
	@include icon-m(keyboard_arrow_down);
}
.icon-m-keyboard_arrow_left {
	@include icon-m(keyboard_arrow_left);
}
.icon-m-keyboard_arrow_right {
	@include icon-m(keyboard_arrow_right);
}
.icon-m-keyboard_arrow_up {
	@include icon-m(keyboard_arrow_up);
}
.icon-m-library_books {
	@include icon-m(library_books);
}
.icon-m-link {
	@include icon-m(link);
}
.icon-m-live_help {
	@include icon-m(live_help);
}
.icon-m-mode_edit {
	@include icon-m(mode_edit);
}
.icon-m-more_horiz {
	@include icon-m(more_horiz);
}
.icon-m-more_vert {
	@include icon-m(more_vert);
}
.icon-m-navigate_before {
	@include icon-m(navigate_before);
}
.icon-m-navigate_next {
	@include icon-m(navigate_next);
}
.icon-m-ondemand_video {
	@include icon-m(ondemand_video);
}
.icon-m-pause {
	@include icon-m(pause);
}
.icon-m-pause_circle_filled {
	@include icon-m(pause_circle_filled);
}
.icon-m-photo_camera {
	@include icon-m(photo_camera);
}
.icon-m-play_arrow {
	@include icon-m(play_arrow);
}
.icon-m-play_circle_filled {
	@include icon-m(play_circle_filled);
}
.icon-m-queue_music {
	@include icon-m(queue_music);
}
.icon-m-reorder {
	@include icon-m(reorder);
}
.icon-m-reply {
	@include icon-m(reply);
}
.icon-m-room {
	@include icon-m(room);
}
.icon-m-save {
	@include icon-m(save);
}
.icon-m-screen_share {
	@include icon-m(screen_share);
}
.icon-m-search {
	@include icon-m(search);
}
.icon-m-settings {
	@include icon-m(settings);
}
.icon-m-star {
	@include icon-m(star);
}
.icon-m-star_border {
	@include icon-m(star_border);
}
.icon-m-tune {
	@include icon-m(tune);
}
.icon-m-wallpaper {
	@include icon-m(wallpaper);
}