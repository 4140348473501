.body-l-system {
  background: image-url("content/system-bg.jpg") no-repeat center fixed;
  background-size: cover;
}

.l-system-close {
  @include transition-opacity('fast');
  position: fixed;
  top: 20px;
  right: 20px;
  display: block;
  font-size: em(28px);
  color: $textColor-10;
  opacity: .7;
  &:hover {
    opacity: 1;
		color: $textColor-10;
  }
  .icon {
    display: block;
  }
}
