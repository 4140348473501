.comment-form {
	.message-container {
		margin-right: 130px;
	}

	textarea {
		height: 36px;
		font-size: em(14px);
		line-height: normal;
		padding-top: 10px;
		padding-bottom: 9px;
	}

	button {
		float: right;
	}
}
