input.textfield, textarea:not(.md-input) {
	&:not(.focus-opacity) {
		@include transition-border-color('fast');
		&:focus {
			border-color: $borderColor-12;
		}
	}

	&.focus-opacity {
		@include transition-opacity('fast');
		opacity: 0.8;
		&:focus {
			opacity: 1;
		}
	}
}
