.users-list-briefly {
	$transitionTime: 100ms;
	$transitionFunction: linear;
	@include pie-clearfix;
	white-space: nowrap;
	vertical-align: top;
	.user {
		display: inline-block;
		margin-right: 8px;
		position: relative;
		width: 70px;
		height: 70px;
		cursor: pointer;
		border-radius: 50%;
		transition: transform $transitionTime $transitionFunction;
		vertical-align: top;
		&:hover {
			transform: scale(1.14);
		}
	}

	.user.all-users {
		margin-right: 0;
		text-align: center;
		padding-top: 17px;
		box-sizing: border-box;
		line-height: 1.2;
		border: 1px solid $borderColor-27;
		color: $textColor-15;
		background-color: transparent;
		transition: all $transitionTime $transitionFunction;
		&:hover {
			color: darken($textColor-15, 20%);
			background-color: rgba(#000, 0.05);
			transform: scale(1.14);
		}
	}

	.badge {
		position: absolute;
		&.badge-role {
			bottom: -4px;
			right: 3px;
		}
	}
}
