.widget-material-author {
	$minHeight: 47px;
	min-height: $minHeight;
	display: table;
	width: 100%;

	.widget-label {
		display: table-cell;
		vertical-align: middle;
		white-space: nowrap;
		width: 1px;
		padding-right: 0.6em;
		&::after {
			content: ':';
		}
	}

	.widget-content-wrapper {
		display: table-cell;
		vertical-align: middle;
	}

	.btn-change {
		$iconWidth: 25px;
		text-align: left;
		cursor: pointer;
		border: 1px solid transparent;
		border-radius: 3px;
		position: relative;
		overflow: hidden;
		padding-right: $iconWidth + 10px;

		.btn-append-container {
			background-color: $bgColor-11;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: $iconWidth;
			color: $textColor-10;
			display: none;
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		&:hover {
			border-color: $borderColor-12;
			.btn-append-container {
				display: block;
			}
		}
	}

	.widget-input-author-container {
		display: inline-block;
		vertical-align: middle;
		max-width: 70%;
		min-width: 310px;
	}

	.select-actions {
		display: inline-block;
		margin-left: 0.6em;
		.btn-link {
			font-size: em(14px);
		}
	}
}
