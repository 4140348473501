.modal-login-form {
  .login-options {
    overflow: hidden;
    width: 320px;
    margin: 20px auto 0;

    .forgot-password {
      @include transition-color();
      font-size: em(14px);
      color: $textColor-22;
      float: right;
      line-height: 1.28;
      &:hover {
        color: $textColor-13;
      }
    }

    .remember-me {
      float: left;
    }
  }

	.modal-actions {
		margin-top: 30px;
	}

	.social-buttons-auth {
		margin-top: 30px;
		.hint-container {
			clear: both;
			text-align: center;
			width: 80%;
			margin: 0 auto 15px;
			position: relative;

			&::before, &::after {
				content: '';
				display: block;
				height: 1px;
				background: $borderColor-21;
				width: 70px;
				position: absolute;
				top: 50%;
				margin-top: 1px;
			}

			&::before { left: 0; }
			&::after { right: 0; }
		}
	}
}
