md-select {
	font-size: em(14px);
	._md-select-value {
		border: 1px solid $borderColor-28;
		border-radius: 3px;
		padding: 1px 25px 0 10px;

		& > span:not(._md-select-icon) {
			max-width: 100%;
			flex: 1 1 auto;
			transform: none;
		}

		._md-select-icon {
			display: block;
			align-items: flex-end;
			width: 24px;

			margin: 0;
			transform: none;
			height: 100%;
			border-left: 1px solid $borderColor-28;
			position: absolute;
			top: 0;
			right: 0;
			text-align: center;

			&::after {
				top: 50%;
				transform: scaleY(.5) scaleX(1) translateY(-80%);
			}
		}
	}

	&:not([disabled]):focus ._md-select-value {
		border: 1px solid $borderColor-12;
	}
}
