#event-questions {
	.modal-dialog {
		padding-top: 40px;
	}

	.modal-header {
		position: relative;
		&::before {
			content: '';
			@include background-image-2x('../images/icons/questions', 40px, 29px);
			background-repeat: no-repeat;
			background-position: center;
			background-color: $bgColor-24;
			display: block;
			width: 66px;
			height: 66px;
			border-radius: 50%;
			position: absolute;
			top: -28px;
			left: 50%;
			margin-left: -33px;
		}
	}

	.modal-footer {
		background-color: $bgColor-24;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
	}
}

#event-questions {
	.question-text {
		line-height: 1.3;
	}

	.maker-name {
		font-size: em(15px);
		font-weight: bold;
		color: $textColor-23;
		&:hover {
			color: darken($textColor-23, 20%);
		}
	}

	.question-date {
		font-size: em(12px);
		color: $textColor-13;
	}

	.maker-avatar {
		float: left;
		.image__user {
			background-color: $bgColor-20;
		}
	}

	.question-header {
		display: inline-block;
		width: 100%;
		.question-date {
			float: right;
		}
	}

	.questions-list--item {
		background-color: $bgColor-24;
		border-radius: 8px;
		padding: 15px 20px;
		margin-bottom: 20px;
		@include pie-clearfix;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.questions-list--item.card {
		border-left: 40px solid $bgColor-30;
	}

	.question-container {
		padding-left: 90px;
	}

	.questions-list {
		padding-bottom: 60px;
	}

	.questions-list.manage-mode {
		.questions-list--item {
			position: relative;
			&.published {
				border-left-color: $bgColor-11;
			}

			&:hover {
				.question-container .controls {
					opacity: 1;
				}
			}
		}

		.question-container .controls {
				padding-top: 5px;
				opacity: 0;
				.action {
					margin-right: 8px;
					&:last-of-type {
						margin-right: 0;
					}
				}
				.action-publish {
					width: 110px;
				}
				.action-remove {
					color: $textColor-51;
					float: left;
					margin-top: 10px;
					&:hover {
						color: darken($textColor-51, 15%);
					}
				}
			}

		.icon.question-published {
			color: $textColor-10;
			font-size: 20px;
			position: absolute;
			top: 50%;
			left: -30px;
			transform: translateY(-50%);
		}

		.form-edit {
			.controls {
				opacity: 1;
				text-align: right;
			}
			.input-container {
				padding-bottom: 5px;
			}
		}
	}




	.form {
		position: relative;
		.input-message-wrapper {
			padding-right: 115px;
		}
		.btn-submit {
			position: absolute;
			bottom: 0;
			right: 0;
			max-width: 105px;
		}
	}

	.modal-footer {
		position: relative;

		.question-create-success {
			background-color: $bgColor-11;
			color: $textColor-10;
			text-align: center;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 20px 40px;
			transform: translateY(-100%) translateX(0);

			&.ng-enter {
				transition: getTimeByName('slow') all;
				opacity: 0;
				transform: translateY(-100%) translateX(100%);
				&.ng-enter-active {
					opacity: 1;
					transform: translateY(-100%) translateX(0);
				}
			}
		}
	}
}
