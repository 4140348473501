.wiki-navigation {
	height: 100%;
	display: inline-block;
	border-right: 1px solid $borderColor-55;
	position: relative;
	user-select: none;
	&-fixed {
		$navWidth: 1080px;
		position: fixed;
		margin-left: -$navWidth/2 + 1;
		width: $navWidth;
		left: 50%;
		top: 60px;
		bottom: 0;
		@include max-screen(1120px) {
			left: 21px;
			margin-left: 0;
		}
	}
	&-content {
		box-sizing: border-box;
		padding: 16px 18px 20px;
		overflow-y: auto;
		height: 100%;
		background-color: $bgColor-56;
		.no-results {
			text-align: left;
			font-size: 14px;
			&-wrap {
				height: auto;
			}
		}
	}
	.nav-header {
		margin-bottom: 5px;
		&__title {
			font-size: em(28px);
			font-weight: normal;
			color: $textColor-23;
		}
		&__summary {
			$fontSize: 14px;
			display: block;
			font-size: em($fontSize);
			line-height: 1;
			height: $fontSize;
			color: $textColor-12;
		}
	}
	.nav-content {
		display: block;
		padding-bottom: 20px;
		width: 194px;
		margin: 0 auto;
	}
	.nav-group {
		.title {
			font-size: em(22px);
			font-weight: bold;
			color: $textColor-31;
			padding: 15px 0 6px;
			cursor: default;
		}
		.list {
			&__item {
				position: relative;
				margin-bottom: 4px;
				&__link {
					display: block;
					color: $textColor-22;
					transition: color 0.3s ease;
					&:hover {
						color: $textColor-32;
						.list__item__name {
							border-bottom-color: $borderColor-51;
						}
					}
					&.active {
						color: $textColor-23;
						font-weight: bold;
						&::before {
							content: '';
							display: inline-block;
							width: 5px;
							height: 5px;
							border-radius: 50%;
							background-color: $bgColor-10;
							position: absolute;
							vertical-align: middle;
							left: -12px;
							top: 50%;
						}
					}
				}
				&__name {
					display: inline-block;
					overflow: hidden;
					max-width: 100%;
					text-overflow: ellipsis;
					border-bottom: 1px dotted transparent;
					font-size: em(14px);
					line-height: 14px;
					white-space: nowrap;
					vertical-align: middle;
					transition: border-color 0.3s ease;
				}
			}
		}
	}
	.loading_list {
		height: 0;
	}
}
