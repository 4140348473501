.upload-box-container {
	.upload-errors {
		padding-bottom: 15px;
		line-height: 1.3;
		color: $textColor-51;
		text-align: center;
		font-size: em(15px);
	}

	.upload-button {
		overflow: hidden;
		position: relative;
		display: block;
		margin: 0 auto;
		text-align: center;
		width: 200px;
		input {
			display: block !important;
			width: 100% !important;
			height: 200% !important;
			position: absolute;
			top: -100%;
			left: 0;
			opacity: 0 !important;
			overflow: hidden !important;
			cursor: pointer;
		}
	}

	.progress-box {
		.progress-details {
			overflow: hidden;
			margin-bottom: 7px;
			.label {
				&.right {
					float: right;
				}
			}
		}
	}

	.item-progress-box {
		height: 8px;
		border-radius: 4px;
		background: $bgColor-52;
		position: relative;
		overflow: hidden;

		.item-progress {
			border-radius: 8px;
			background: $bgColor-11;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 100%;
			transition: width 100ms linear;
		}
	}
}
