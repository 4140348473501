.page-im {
	.c-wrapper {
		padding-right: 20px;
		padding-left: 20px;
	}
	&.player {
		.im__content__messages {
			.messages__wrapper {
				margin-bottom: $audioPlayerHeight;
			}
			.messages__send-fixed {
				bottom: $audioPlayerHeight;
			}
		}
		.im__content__chats {
			bottom: $audioPlayerHeight;
		}
	}
}

.im__content {
	overflow: hidden;
	&__messages {
		margin-left: $imChatsWidth;
		height: 100%;
		position: relative;
	}
	&__view {
		position: absolute;
		right: 0;
		top: 55px;
		bottom: 0;
		left: $imChatsWidth;
		background-color: $bgColor-20;
		user-select: none;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s ease;
		&.active {
			visibility: visible;
			opacity: 1;
		}
		.im__view {
			$size: 400px;
			position: absolute;
			width: $size;
			left: 50%;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
			box-sizing: border-box;
			text-align: center;
			padding-bottom: 20vh;
			&:before {
				$sizeImage: 220px;
				content: '';
				position: relative;
				display: inline-block;
				width: $sizeImage;
				height: $sizeImage;
				border-radius: 100%;
				margin-bottom: 10px;
				@include background-image-2x('../images/icons/chat', $sizeImage, $sizeImage);
			}
			&-text {
				font-size: 16px;
				line-height: 25px;
				font-style: italic;
				color: $textColor-12;
				margin-bottom: 20px;
			}
			&-button {
				.btn5 {
					font-size: 16px;
					padding: 10px 30px;
					height: 40px;
					&:focus {
						background-color: $bgColor-13;
					}
				}
			}
		}
	}
}
