.redactor-videos-list {
	videos,
	videos-list,
	video-teaser {
		display: block;
		width: 100%;
	}

	.modal-body {
		background-color: #fff;
		padding: 0 !important;
	}

	.videos-list {
		margin: 0;
		padding: 0;
	}

	.videos-list-item {
		float: none;
		height: auto;
		width: auto;
	}

	.video-teaser .thumbnail {
		width: 215px;
		height: 121px;
	}

	md-radio-button {
		margin-bottom: 0;
		padding: 20px 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-bottom: 1px dotted $borderColor-20;

		._md-label {
			margin-left: 35px;
			display: block;
		}

		&:hover {
			background-color: lighten($bgColor-55, 2);
		}
		&:last-of-type {
			border-bottom: none;
		}
	}
}
