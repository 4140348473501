[ng\:cloak], [ng-cloak], .ng-cloak {
	display: none !important;
}

.ng-hide {
	display: none !important;
}

ui-view {
	display: block;
}
