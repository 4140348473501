.tags-container {
	width: 100%;
	.md-chips {
		background: #FFF;
		md-chip {
			line-height: 30px;
		}
	}

	.tag-name {
		display: inline-block;
		vertical-align: middle;
		color: inherit;
		margin: 0 5px;
	}
	md-progress-linear {
		display: none;
	}
}


.tags-container.tags-first-highlight {
	.md-chips md-chip[index="0"] {
		background-color: $bgColor-10;
		color: $textColor-10;
		&.md-focused {
			background-color: darken($bgColor-10, 5%);
			._md-chip-remove md-icon {
				color: $textColor-10;
			}
		}
		._md-chip-remove md-icon {
			color: $textColor-10;
		}
	}
}


.tags-container.tags-controls {
	.tag-position {
		color: $textColor-10;
		background-color: $bgColor-11;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		border-radius: 2px;
		margin-right: 3px;
		height: 16px;
		width: 16px;
		transition: background-color 0.3s ease;
		&:hover {
			background-color: darken($bgColor-11, 10%);
		}
		&:active {
			background-color: $bgColor-11;
		}
		&:last-of-type {
			margin-right: 0;
		}
		i {
			&:before {
				vertical-align: initial;
			}
		}
	}


	.tag-controls {
		line-height: 16px;
		display: inline-block;
		vertical-align: middle;
	}
}
