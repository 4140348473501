.audio-teaser {
	padding: 7px 0;
	height: 40px;
	user-select: none;
	@include pie-clearfix;
	position: relative;

	.state-button {
		float: left;
	}

	.info-container {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-left: 55px;
		margin-top: 12px;
	}

	.details-container {
		float: right;
		padding-left: 20px;
		margin-top: 10px;
		text-align: right;
		.controls {
			display: none;
		}
		.duration {
			display: inline-block;
		}
	}

	&:hover {
		.details-container {
			.controls {
				display: inline-block;
				vertical-align: top;
			}
			.duration {
				display: none;
			}
		}
	}
}

.audio-teaser {
	.state-button {
		cursor: pointer;
		overflow: visible;
		.button {
			$inactiveColor: $textColor-25;
			$activeColor: $textColor-31;
			$bgColor: $bgColor-20;
			box-shadow: 0 0 0 1px $inactiveColor;
			display: block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			font-size: 40px;
			position: relative;
			cursor: pointer;
			color: $bgColor;
			overflow: hidden;

			&::before, &::after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 50%;
			}
			&::before {
				background-color: $inactiveColor;
			}
			&::after {
				border: 1px solid $bgColor;
				box-sizing: border-box;
			}

			&.active {
				box-shadow: 0 0 0 2px $activeColor;
				color: $activeColor;
				&::before {
					background-color: $bgColor;
				}
			}

			&:not(.active) {
				&::before {
					transition: background-color 150ms;
				}

				&:hover {
					box-shadow: 0 0 0 1px $activeColor;
					&::before {
						background-color: $activeColor;
					}
				}
			}
		}

		.icon {
			position: relative;
		}
	}

	.artist {
		color: $textColor-31;
		font-weight: bold;
	}

	.title::before {
		content: ' – ';
	}

	.btn-details {
		$size: 26px;
		width: $size;
		height: $size;
		background-color: $bgColor-20;
		box-shadow: 0 0 1px 0 rgba(#000, 0.3);
		border-radius: 50%;
		cursor: pointer;
		margin-left: 15px;
		display: inline-block;
		&:after {
			content: '?';
			display: block;
			font-weight: bold;
			text-align: center;
			color: $textColor-31;
		}
		&:hover {
			background-color: darken($bgColor-20, 3%);
		}
	}

	.duration {
		@include font-helvetica-light();
		font-size: em(15px);
		margin-top: -2px;
	}

	.controls {
		.action-container {
			display: inline-block;
			margin-left: 5px;
			&:first-of-type {
				margin-left: 0;
			}
		}
		.action {
			display: inline-block;
			border-radius: 50%;
			text-align: center;
			background-color: $bgColor-20;
			width: 26px;
			height: 26px;
			cursor: pointer;
			border: 1px solid $borderColor-55;
			transition: transform 100ms ease-in-out;
			color: $borderColor-51;
			&.success {
				color: $textColor-30;
				border-color: $textColor-30;
			}
			&.warning {
				color: $textColor-51;
				border-color: $textColor-51;
			}
			&:hover {
				transform: scale(1.15);
			}
			i[class^='icon-m'] {
				display: inline-block;
				margin-left: 1px;
				margin-top: 2px;
				font-size: 16px;
				width: 20px;
				text-align: center;
				vertical-align: top;
			}
			.icon {
				font-size: 14px;
			}
		}
	}
}