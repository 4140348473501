/* Search block form styles */

.b-search__form {
	position: relative;
	.textfield {
		width: 100%;
		box-sizing: border-box;
		padding-left: 40px;
	}
	.icon {
		font-size: 16px;
		position: absolute;
		left: 15px;
		top: 50%;
		margin-top: -8px;
		color: $textColor-25;
	}
	.summary {
		color: $textColor-12;
		font-size: em(14px);
		white-space: nowrap;
		position: absolute;
		right: 10px;
		top: 50%;
		margin-top: -6px;
		opacity: 1;
		&.ng-hide {
			opacity: 0;
		}
		&.ng-hide-add, &.ng-hide-remove {
			display: block !important;
		}
		&.ng-hide-remove {
			@include transition-opacity();
		}
	}
}
