.btn3 {
	box-sizing: border-box;
	border-radius: 21px;
	display: inline-block;
	cursor: pointer;
	color: $textColor-10;
	padding: 12px 20px;
	line-height: 1;
	white-space: nowrap;
	border: 1px solid transparent;
	text-align: center;
	@include transition-bg-color('fast');
	&:hover {
		color: $textColor-10;
	}
}


// # Sizes

.btn3-medium {
	padding: 10px 16px;
	border-radius: 19px;
}


// # Colors

.btn3-transparent {
	background-color: transparent;
	border: 1px solid $borderColor-24;
	color: $textColor-10;
	&:hover {
		background: rgba(#000, 0.2);
		color: $textColor-10;
	}
}

.btn3-green {
	background-color: $bgColor-11;
	border-top: 1px solid $borderColor-13;
	border-bottom: 1px solid $borderColor-14;
	&:hover {
		background-color: $bgColor-12;
	}
	&[disabled] {
		$amount: 20%;
		cursor: default;
		background-color: lighten($bgColor-11, $amount);
		border-top-color: lighten($borderColor-13, $amount);
		border-bottom-color: lighten($borderColor-13, $amount);
	}
}

.btn3-blue {
	background-color: $bgColor-30;
	border-top: 1px solid $borderColor-30;
	border-bottom: 1px solid $borderColor-31;
	&:hover {
		background-color: $bgColor-31;
	}
}

.btn3-white {
	background-color: $bgColor-20;
	border: 1px solid $borderColor-52;
	color: $textColor-23;
	&:hover {
		background: rgba(#000, 0.1);
		color: darken($textColor-23, 15%);
	}
}

.btn3-red {
	background-color: $bgColor-40;
	border-top: 1px solid $borderColor-42;
	border-bottom: 1px solid $borderColor-42;
	&:hover {
		background-color: darken($bgColor-40, 15%);
	}
}


// # Icons

.btn3 .icon {
	position: relative;
	padding-right: 4px;
	line-height: normal;
	display: inline-block;
	vertical-align: top;

	&.icon-plus {
		font-size: 14px;
		padding-right: 8px;
	}
	&.icon-message {
		font-size: 12px;
		padding-right: 8px;
	}
	&.icon-help-circle-fill {
		font-size: 18px;
		padding-right: 9px;
	}
	&.icon-close {
		padding-right: 9px;
		color: $textColor-50;
	}
	&.icon-checkmark {
		padding-right: 9px;
		color: $textColor-31;
	}
	&.icon-join {
		font-size: 18px;
	}
	&.icon-add {
		font-size: 14px;
		padding-right: 10px;
	}
	&.icon-enter {
		padding-right: 10px;
		bottom: -2px;
	}
}
