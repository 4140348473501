.upstairs {
	$width: $userBarWidth;
	width: $width;
	margin-right: ceil(-$width - $wrapperWidth / 2) - 10px;
	display: block;
	position: fixed;
	top: 0;
	height: 100%;
	right: 50%;
	z-index: 70;
	opacity: 0;
	transition: opacity 0.5s ease, background-color .15s ease-out;
	cursor: pointer;
	visibility: hidden;
	&:hover {
		background-color: darken(#F7F9FA, 2%);
	}
	&.show {
		opacity: 1;
		visibility: visible;
	}
	.upstairs-button {
		display: block;
		box-sizing: border-box;
		color: $textColor-25;
		padding: 2px 2px 15px;
		position: absolute;
		text-align: center;
		bottom: 0;
		left: 0;
		width: 100%;
		.icon {
			font-size: 40px;
			display: inline-block;
			vertical-align: top;
			position: relative;
		}
	}
}
