.modal.modal-style-event {
	.modal-dialog {
		width: 750px;
	}
	.modal-top-line {
		padding: 0;
		border-radius: 16px 16px 0 0;
		position: relative;
		overflow: hidden;
		&:before {
			content: '';
			display: block;
			background-image: linear-gradient(89deg, rgba(#000, 0.01) 20%, rgba(#000, 0.6) 80%);
			background-color: rgba(#000, 0.1);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	.modal-field {
		width: 400px;
	}
	.modal-content {
		padding: 25px 0 40px;
	}
	.modal-field-wrap {
		position: relative;
		padding: 10px 0 30px;
		border-bottom: 1px solid $borderColor-20;
		&:first-of-type {
			padding-top: 0;
		}
		&:last-of-type {
			border-bottom: 0 solid;
		}
		&:hover {
			.modal-fild-close {
				color: $textColor-15;
			}
		}
	}
	.modal-fild-close {
		display: inline-block;
		padding: 5px;
		position: absolute;
		right: 10px;
		top: 10px;
		color: $textColor-16;
		transition: color 0.3s ease;
	}
	.modal-field-action {
		width: 150px;
		background-color: $bgColor-24;
		margin: -22px auto 0;
		text-align: center;
		padding: 4px;
		position: relative;
		z-index: 3;
	}
	.modal-actions {
		overflow: visible;
		margin-top: 20px;
		md-checkbox {
			margin-bottom: 0;
		}
	}
	.background {
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		top: 0;
	}
	.header-event {
		display: table;
		box-sizing: border-box;
		position: relative;
		padding: 20px 30px;
		text-align: left;
		table-layout: fixed;
		width: 100%;
		z-index: 2;
		.image {
			display: table-cell;
			vertical-align: top;
			width: 180px;
		}
		.info {
			display: table-cell;
			vertical-align: middle;
			.title {
				$font-size: 23px;
				$line-height: 1.2;
				$lines-to-show: 3;
				display: block;
				display: -webkit-box;
				color: #fff;
				font-weight: normal;
				max-height: $font-size*$line-height*$lines-to-show;
				overflow: hidden;
				margin: 0 auto;
				font-size: $font-size;
				line-height: $line-height;
				-webkit-line-clamp: $lines-to-show;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
			.subtitle {
				padding: 15px 0 0;
				color: #fff;
			}
		}
		.event-image {
			float: none;
			display: inline-block;
			background-color: darkgray;
			border: 2px solid #fff;
			border-radius: 8px;
			min-height: 112px;
			width: 150px;
			.image__event {
				width: 100%;
				height: auto;
			}
		}
	}
}

.modal-registered-wrapper {
	padding: 0 30px;
	margin-bottom: -20px;
	position: relative;
}

.modal-registered {
	background-color: white;
	padding: 5px;
	user-select: none;
	border-radius: 6px;
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
	box-sizing: border-box;
	border: 1px solid $borderColor-23;
	&.several {
		width: 48%;
		&:nth-child(odd) {
			margin-right: 4%;
		}
	}
	.modal-registered-table {
		display: table;
		width: 100%;
		table-layout: fixed;
	}
	.modal-registered-cell {
		display: table-cell;
		vertical-align: middle;
		padding-right: 10px;
		&.avatar {
			width: 70px;
			.image {
				width: 70px;
				height: 70px;
			}
		}
		&.contacts {
			ul {
				font-size: 14px;
				line-height: 1.2;
				display: block;
				width: 100%;
				li {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-bottom: 5px;
					display: block;
					cursor: default;
					vertical-align: middle;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
			.s-title {
				color: $bgColor-53;
			}
			.s-contact {
				color: $textColor-21;
			}
		}
	}
}

