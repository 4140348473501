.popular {
    margin-top: 34px;

    &__form-input {
        text-align: center;
        border-bottom: 2px solid black;
        transition: border-color .3s;

        &:focus {
            border-color: #7FC786;
            transition: border-color .3s;
        }
    }

    &__button {
        background-color: #7FC786;
        color: white;

        &:hover {
            background-color: rgba(#7FC786, 0.7);
        }
    }

    &__button--saved {
        background-color: #c1c1c1;

        &:hover {
            background-color: rgba(#c1c1c1, 0.7)
        }
    }

    &__error-message {
        color: red;
        font-size: 12px;
    }
}