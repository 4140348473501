@font-face {
	font-family: 'absolutera';
	src: url('/fonts/absolutera/absolutera.eot?4ise2m');
	src: url('/fonts/absolutera/absolutera.eot?4ise2m#iefix') format('embedded-opentype'),
	url('/fonts/absolutera/absolutera.ttf?4ise2m') format('truetype'),
	url('/fonts/absolutera/absolutera.woff?4ise2m') format('woff'),
	url('/fonts/absolutera/absolutera.svg?4ise2m#absolutera') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin font-absolutera {
	font-family: 'absolutera';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin font-material {
	font-family: "material";
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:not([class^="icon-m-"]),
[class*=" icon-"]:not([class*=" icon-m-"]) {
	@include font-absolutera;
}

.icon-clock:before {
	content: "\e907";
}

.icon-format_line_spacing:before {
	content: "\e240";
}

.icon-add:before {
	content: "\e605";
}

.icon-close:before {
	content: "\e117";
}

.icon-help-circle-fill:before {
	content: "\e601";
}

.icon-pause-circle-fill:before {
	content: "\e603";
}

.icon-play-circle-fill:before {
	content: "\e604";
}

.icon-chevron-with-circle-down:before {
	content: "\e904";
}

.icon-chevron-with-circle-left:before {
	content: "\e905";
}

.icon-chevron-with-circle-right:before {
	content: "\e906";
}

.icon-chevron-with-circle-up:before {
	content: "\e903";
}

.icon-dots-three-horizontal:before {
	content: "\e62a";
}

.icon-logo-yandex:before {
	content: "\e902";
}

.icon-logo-facebook:before {
	content: "\f09a";
}

.icon-logo-google:before {
	content: "\f1a0";
}

.icon-social-vk:before {
	content: "\e61f";
}

.icon-social-odnolassniki:before {
	content: "\e622";
}

.icon-angle-left:before {
	content: "\e623";
}

.icon-angle-right:before {
	content: "\e61b";
}

.icon-bullhorn:before {
	content: "\e901";
}

.icon-clear-circle-fill:before {
	content: "\e617";
}

.icon-eye:before {
	content: "\f06e";
}

.icon-file-text2:before {
	content: "\e625";
}

.icon-social-facebook:before {
	content: "\e609";
}

.icon-social-twitter:before {
	content: "\e60a";
}

.icon-arrow-left:before {
	content: "\e612";
}

.icon-arrow-right:before {
	content: "\e611";
}

.icon-checkmark:before {
	content: "\e60c";
}

.icon-edit:before {
	content: "\e614";
}

.icon-enter:before {
	content: "\e60d";
}

.icon-exit:before {
	content: "\e60e";
}

.icon-logo:before {
	content: "\e900";
}

.icon-pin-alt:before {
	content: "\e607";
}

.icon-search:before {
	content: "\e608";
}

.icon-triangle-left:before {
	content: "\f044";
}

.icon-triangle-right:before {
	content: "\f05a";
}

.icon-volume-high:before {
	content: "\e60f";
}

.icon-volume-mute:before {
	content: "\e610";
}

.icon-bubble:before {
	content: "\e619";
}

.icon-camera:before {
	content: "\e61a";
}

.icon-locked:before {
	content: "\e616";
}

.icon-message:before {
	content: "\e61c";
}

.icon-plus:before {
	content: "\e61d";
}

.icon-warning:before {
	content: "\e602";
}

.icon-clear:before {
	content: "\e606";
}

