.badge-role {
	$bgWidth: 18px;
	$bgHeight: 27px;
	display: block;
	width: $bgWidth;
	height: $bgHeight;

	&.r-admin {
		@include background-image-2x('../images/icons/badge-role/admin', $bgWidth, $bgHeight);
	}
	&.r-moderator {
		@include background-image-2x('../images/icons/badge-role/moderator', $bgWidth, $bgHeight);
	}
}
