// common
.b-search__selectbox {
	.label {
		font-size: em(14px);
		display: block;
		padding-bottom: 4px;
		cursor: default;
	}

	&.is-disabled {
		opacity: 0.3;
	}
}

// ui-select
.b-search__selectbox {
	$maxWidth: 160px;
	.select2-container .select2-choice {
		box-sizing: border-box;
		width: $maxWidth;
		min-width: $maxWidth;
	}

	.select2-container.select2-container-disabled .select2-choice {
		background: none;
	}
}

.b-search__selectbox.mw-120 {
	$maxWidth: 120px;
	.select2-container .select2-choice {
		width: $maxWidth;
		min-width: $maxWidth;
	}
}
