.notify-message-wrap {
	.ui-pnotify-container {
		padding: 0;
	}
	.ui-pnotify-closer {
		position: absolute;
		right: 2px;
		top: 2px;
		z-index: 2;
		height: 20px;
		width: 20px;
		text-align: center;
		vertical-align: middle;
		font-size: 11px;
		line-height: 20px;
		border-radius: 100%;
		.brighttheme-icon-closer {
			@include font-absolutera;
			color: $textColor-10;
			&:before {
				content: "\e117";
			}
		}
	}
}

.notify-message {
	position: relative;
	width: 350px;
	max-height: 90px;
	background-color: rgba($bgColor-50, 0.65);
	border-radius: 6px;
	box-sizing: border-box;
	padding: 13px;
	color: $textColor-10;
	cursor: pointer;
	&_content {
		display: table;
		table-layout: fixed;
		width: 100%;
	}
	&_image, &_context {
		display: table-cell;
		vertical-align: top;
		height: 45px;
	}
	&_image {
		width: 55px;
		img {
			width: 45px;
			height: auto;
		}
	}
	&_context {
		h2 {
			color: $textColor-10;
			font-size: 15px;
			line-height: 1;
			margin-bottom: 4px;
			white-space: nowrap;
			overflow: hidden;
			width: 260px;
		}
	}
	&_message {
		overflow: hidden;
		font-size: 13px;
		line-height: 1.2;
		word-wrap: break-word;
	}
	&_link {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}
