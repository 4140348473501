.content_video-wrap {
	position: relative;
	height: 540px;
	background: #CCC;
	text-align: center;
	&:after {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		cursor: default;
	}
	.content_video-view {
		position: absolute;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		min-width: 300px;
	}
	.content_video-button {
		cursor: pointer;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100px;
		height: 100px;
		margin: -50px;
		border-radius: 100%;
		box-sizing: border-box;
		border: 5px solid white;
		z-index: 1;
		text-shadow: 2px 2px 5px rgba(#000, 0.4);
		box-shadow: 2px 2px 5px 0 rgba(#000, 0.4);
		transition: transform 0.3s ease;
		&:hover {
			transform: scale(1.05);
		}
		.icon-m-play_arrow {
			color: #fff;
			font-size: 90px;
			line-height: 1;
			vertical-align: top;
		}
	}
}