.maker-options-form {
	.preview-box {
		position: relative;
		padding-bottom: 20px;
		padding-right: 20px;;
		.label {
		}
		.name {
			margin-left: 0.5em;
		}
		.name-empty {
			font-style: italic;
		}
		.delete {
			display: block;
			cursor: pointer;
			color: $bgColor-53;
			background-color: #fff;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.search-field {
		display: block;
		width: 100%;
		box-sizing: border-box;
		padding-left: 15px;
		padding-right: 15px;
		&:not(.empty) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.list-container {
		padding: 10px 15px;
		background: $bgColor-20;
		border: 1px solid $borderColor-21;
		border-top: none;
		&:first-of-type {
			padding-top: 15px;
		}
		&:last-of-type {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		.label {
			display: block;
			padding-bottom: 7px;
			color: $textColor-22;
			font-weight: bold;
		}
		.name {
			color: $textColor-23;
			display: block;
			padding: 8px 30px 7px 20px;
			cursor: pointer;
			width: 100%;
			text-align: left;
		}
		.icon-link {
			color: $textColor-32;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			font-size: 18px;
			width: 30px;
			text-align: center;
			padding-top: 6px;
		}
	}

	.list-item {
		border-radius: 4px;
		position: relative;
		overflow: hidden;
		border: 1px solid transparent;

		&:hover {
			border-color: $borderColor-12;
			.name {
				color: darken($textColor-32, 7%);
			}
			.icon-link {
				color: $textColor-10;
				background: $bgColor-13;
				&:hover {
					background: darken($bgColor-13, 5%);
				}
			}
		}
	}

}
