.page-profile__users-requests {
  .user_list {
    border-bottom: 1px solid $borderColor-20;
  }

  .user_list__item {
    transition: none;
    padding: 0 10px;
    margin: 10px 0;
    border-bottom: none;

    &:hover {
      background: none;
    }

    .c-wrapper {
      background: $bgColor-11;
      overflow: hidden;
      border-radius: 8px;
      padding-right: 25px;
      padding-left: 25px;
    }

    .info__name, .info__location {
      color: $textColor-10;
    }
  }
}
