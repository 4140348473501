.b-info_list {
	text-align: center;
	dt, dd {
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		margin-bottom: 10px;
	}
	dt {
		text-align: right;
		margin-right: 10px;
		width: 24%;
	}
	dd {
		text-align: left;
		width: 73%;
	}
	a {
		color: $textColor-30;
		&:hover {
			color: $textColor-31;
		}
	}
}
