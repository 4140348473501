.textfield {
  background: $bgColor-20 !important;
  margin: 0;
  &::placeholder {
    @include transition-color('fast');
  }
}

.textfield-style-1 {
  border: 1px solid $borderColor-21;
  padding: 7px 20px 6px;
  border-radius: 5px;
  color: $textColor-21;
	font-size: em(14px);
	line-height: 19px;
  &:focus::placeholder {
    color: $textColor-21;
  }
	&:disabled {
		background: darken($bgColor-20, 5%) !important;
	}
}

.textfield-style-2 {
  padding: 12px;
  border-radius: 8px;
  color: $textColor-22;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid $borderColor-22;
	font-size: em(14px);
	line-height: 19px;
  &.error {
    border: 1px solid $borderColor-40;
  }
}

.textfield-style-3 {
  font-size: em(13px);
  padding: 5px 10px 3px;
  border: 1px solid $borderColor-21;
  color: $textColor-21;
  border-radius: 3px;
  box-sizing: border-box;
	line-height: 18px;
}

.textfield-style-4 {
	display: block;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid $borderColor-25;
	padding: 13px 13px 12px;
	border-radius: 8px;
	&::placeholder {
		padding-top: 2px;
	}
}
