.ng-social {
	display: block;
	overflow: hidden;
	@include no-select;

	li {
		float: left;
		margin-right: 5px;
		&:last-of-type {
			margin-right: 0;
		}
	}
}

.ng-social-button {
	display: block;
	position: relative;
	cursor: pointer;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	overflow: hidden;
	color: #fff;
	@include transition-bg-color('fast');
	&:hover {
		color: #fff;
	}
}

.ng-social-text,
.ng-social-counter {
	display: none;
}

.ng-social-icon {
	display: block;
	text-align: center;
	@include font-absolutera;
}


/* Facebook */
.ng-social-facebook {
	$btn-color: #39559F;

	.ng-social-button {
		color: $btn-color;
		@include transition-color('fast');
		&:hover {
			color: darken($btn-color, 15%);
		}
	}
	.ng-social-icon {
		@extend .icon-social-facebook;
		font-size: 30px;
	}
}


/* Twitter */
.ng-social-twitter {
	$btn-color: #359BED;

	.ng-social-button {
		color: $btn-color;
		@include transition-color('fast');
		&:hover {
			color: darken($btn-color, 15%);
		}
	}
	.ng-social-icon {
		@extend .icon-social-twitter;
		font-size: 30px;
	}
}


/* Vkontakte */
.ng-social-vk {
	$btn-color: #4C75A3;

	.ng-social-button {
		background-color: $btn-color;
		&:hover {
			background-color: darken($btn-color, 15%);
		}
	}
	.ng-social-icon {
		@extend .icon-social-vk;
		font-size: 20px;
		padding-top: 4px;
	}
}

/* Odnoklassniki */
.ng-social-odnoklassniki {
	$btn-color: #F5821F;

	.ng-social-button {
		background-color: $btn-color;
		&:hover {
			background-color: darken($btn-color, 15%);
		}
	}
	.ng-social-icon {
		@extend .icon-social-odnolassniki;
		font-size: 18px;
		padding-top: 5px;
	}
}
