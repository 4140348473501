.wiki-content {
	.item__header {
		padding: 15px 30px;
		position: relative;
		word-wrap: break-word;
		+.item__content {
			border-top: 1px solid $borderColor-23;
		}
	}
	.item__content {
		word-wrap: break-word;
	}
	.item__name {
		font-size: em(30px);
		color: $textColor-23;
	}
	.item__settings {
		font-size: em(14px);
		color: $textColor-11;
		.action, .separator {
			display: inline-block;
			vertical-align: baseline;
		}
		.action {
			color: inherit;
			&:hover {
				color: darken($textColor-11, 25%);
				text-decoration: underline;
			}
		}
		.separator {
			width: 1px;
			height: 10px;
			margin: 0 8px;
			background: $textColor-11;
		}
	}
	.item__menu {
		a {
			padding-left: 26px;
			padding-right: 26px;
		}
	}

	.menu-options {
		position: absolute;
		right: 30px;
		top: 34px;
	}
}
