.videos-list {
  @include pie-clearfix;
	@extend .c-wrapper;
  margin: 10px -15px 30px;
	padding-top: 5px;
}

.videos-list-item {
  width: 349px;
  height: 240px;
	float: left;
}

// Processing Videos Lists

.processing-videos-list {
	.processing-materials-list {
		min-height: 182px;
	}
}

.processing-videos-list-item {
	padding: 0 30px;

	.video-teaser {
		border: 1px solid $borderColor-12;
		border-radius: 3px;
		margin: 20px 0;
		position: relative;
		.video-teaser-details {
			padding-top: 10px;
			padding-right: 15px;
			max-height: 150px;
			overflow: hidden;
		}

		.description {
			$lineHeight: 1.3;
			$fontSize: 16px;
			max-height: $fontSize * $lineHeight * 4;
		}

		.title {
			text-overflow: ellipsis;
			line-height: normal;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.video-teaser::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 20px 20px;
		border-color: transparent transparent $borderColor-12 transparent;
	}

	.invitations-controls-container {
		overflow: hidden;
		padding: 8px 20px 8px 335px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
	}

	.invitations-controls {
		$margin: 8px;
		margin: 0 (-$margin);
		float: right;
		white-space: nowrap;
		button {
			float: left;
			margin: 0 $margin;
		}
	}

	.referrer {
		color: inherit;
		float: left;
		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			width: 30px;
			height: 30px;
			&.image__group {
				border-radius: 3px;
			}
		}
		.name {
			font-weight: bold;
		}
		.hint {
			font-style: italic;
		}
		&:hover .name {
			text-decoration: underline;
		}
	}

	.referrer-info {
		display: inline-block;
		vertical-align: middle;
		font-size: em(14px);
		.hint {
			padding-top: 0.2em;
			display: block;
		}
	}
}
