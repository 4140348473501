.page-album-update {}

.page-album-update .album-section {
	position: relative;
	.left-column {
		float: left;
	}
	.right-column {
		margin-left: 355px;
		overflow: hidden;
	}

	.actions {
		position: absolute;
		bottom: 0;
		left: 355px + 30px;
		width: 100%;

		.action {
			display: inline-block;
			vertical-align: middle;
			margin-right: 25px;
		}
	}
}


.page-album-update {
	.images-list {
		margin: -20px 0 0;
	}
	.images-list-item {
		float: none;
		width: auto;
		display: block;
		padding: 0;
	}
}

.page-album-update .image-update-form {
	padding: 20px 0;
	border-bottom: 1px dotted $borderColor-20;
	@include pie-clearfix;
	.image-container {
		@include block-fill-image;
		float: left;
		position: relative;
		overflow: visible;
		margin-bottom: 20px;

		.controls-top {
			position: absolute;
			top: -13px;
			right: -13px;
			visibility: hidden;

			.action-remove {
				display: block;
				border-radius: 50%;
				text-align: center;
				background-color: $bgColor-20;
				width: 26px;
				height: 26px;
				line-height: 28px;
				font-size: 11px;
				cursor: pointer;
				border: 1px solid $textColor-51;
				transition: transform 100ms ease-in-out;
				color: $textColor-51;
				&:hover {
					transform: scale(1.15);
				}
			}
		}

		.controls-bottom {
			$fontSize: 14px;
			position: absolute;
			bottom: -$fontSize - 8px;
			left: 0;
			width: 100%;
			text-align: center;
			font-size: em($fontSize);
			.action-container {
				display: inline-block;
			}

			.action-link {
				color: $textColor-30;
				cursor: pointer;
				font-size: inherit;
				&:hover {
					color: darken($textColor-30, 15%);
				}
			}
		}
	}

	.details {
		margin-left: 355px;
		@include pie-clearfix;
	}

	.row-field {
		padding-bottom: 15px;
		&::after {
			clear: right;
		}
	}

	&:hover .controls-top {
		visibility: visible;
	}

	.row-change-album {
		.action-link {
			color: $textColor-30;
			cursor: pointer;
			font-size: em(14px);
			&:hover {
				color: darken($textColor-30, 15%);
			}
		}

		.selectbox {
			.field-label {
				display: inline-block;
				padding-right: 0.5em;
			}

			.row-field-controls {
				display: inline-block;
			}

			.select2-container {
				width: 250px;
			}
		}
	}

	.form-actions {
		.action {
			margin-right: 20px;
		}
	}
}
