// bg colors
$bgColor-10: #60CB6B;
$bgColor-11: #60B969;
$bgColor-12: #519c59;
$bgColor-13: #57AE60;
$bgColor-14: #42864A;

$bgColor-20: #FFF;
$bgColor-21: #E4E9ED;
$bgColor-22: #D6DCE1;
$bgColor-23: #f6f6f6;
$bgColor-24: #F3F5F8;
$bgColor-25: #FBFCFC;
$bgColor-26: #BDC3C7;
$bgColor-27: #D8D9D9;
$bgColor-28: #868686;
$bgColor-29: #F3F6F8;

$bgColor-30: #3398DB;
$bgColor-31: #2d87c3;

$bgColor-40: #E74C3C;
$bgColor-41: #E67E22;
$bgColor-42: #ED492D;

$bgColor-50: #000;
$bgColor-51: #F1F1F1;
$bgColor-52: #CBCBCB;
$bgColor-53: #8C9093;
$bgColor-54: #DDE7EC;
$bgColor-55: #f3f5f8;
$bgColor-56: #FCFDFD;
$bgColor-57: #F4F4F4;
$bgColor-58: #F2F6F8;
$bgColor-59: #FBFBFB;
$bgColor-60: #F9F9F9;

// border colors
$borderColor-10: #549F5C;
$borderColor-11: #BFEAC3;
$borderColor-12: #60CB6B;
$borderColor-13: #7FC786;
$borderColor-14: #427C47;

$borderColor-20: #E3E3E3;
$borderColor-21: #CBCBCB;
$borderColor-22: #DEDFE2;
$borderColor-23: #DADBDD;
$borderColor-24: #fff;
$borderColor-25: #D0D5D8;
$borderColor-26: #DEDEDE;
$borderColor-27: #979797;
$borderColor-28: #CCC;
$borderColor-29: #868686;

$borderColor-30: #5AACE2;
$borderColor-31: #2976A9;

$borderColor-40: #FF765E;
$borderColor-41: #E67E22;
$borderColor-42: #ED492D;

$borderColor-50: #000;
$borderColor-51: #666;
$borderColor-52: #8C9093;
$borderColor-53: #EFF4F6;
$borderColor-54: #E4E9F1;
$borderColor-55: #DDDEDE;
$borderColor-56: #CDCDCD;

// text colors
$textColor-10: #fff;
$textColor-11: #9A9A9A;
$textColor-12: #A1A1A1;
$textColor-13: #8C9093;
$textColor-14: #BDC3C7;
$textColor-15: #979797;
$textColor-16: #dedede;
$textColor-17: #868686;
$textColor-18: #818181;

$textColor-20: #1c1c1c;
$textColor-21: #575757;
$textColor-22: #666;
$textColor-23: #434C51;
$textColor-24: #4A4A4A;
$textColor-25: #7F7F7F;
$textColor-26: #3F3F3F;
$textColor-27: #000;
$textColor-28: #5C5C5C;
$textColor-29: #455563;

$textColor-30: #60B969;
$textColor-31: #60CB6B;
$textColor-32: #4ca256;
$textColor-33: #74ff8b;

$textColor-40: #E67E22;

$textColor-50: #FF765E;
$textColor-51: #ED492D;

$textColor-60: #3398DB;
