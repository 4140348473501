.layout-content {
	display: block;
	min-height: 100vh;
	position: relative;
	padding-top: $headerHeight;
	box-sizing: border-box;
	border-left: 1px solid $borderColor-20;
	border-right: 1px solid $borderColor-20;
	background-color: $bgColor-20;

	//.state-homepage & {
	//	min-height: 0;
	//}

	a {
		&:active, &:focus {
			//background-color: inherit;
		}
	}
}

