.event__header {
	overflow: hidden;

	/**
	 * **************** Event Header Info Section *****************
	 **/

	.info {
		height: auto;
		width: 100%;
		padding: 0 30px 0 300px;
		margin: 0;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	/**
	 * **************** Event Header Other Styles *****************
	 **/

	.title {
		font-size: em(31px);
		color: $textColor-10;
		font-weight: normal;
	}

	.subtitle {
		padding: 15px 0 30px;
		color: $textColor-10;
		.icon {
			position: relative;
			top: 2px;
			font-size: em(18px);
			margin-right: 3px;
		}
	}

	.actions {
		.action {
			margin-right: 8px;
			&:last-of-type {
				margin-right: 0;
			}
		}
		.action-ms-hover {
			min-width: 180px;
		}
		.action-questions {
			position: relative;
			overflow: visible;
			.badge-count {
				display: block;
				position: absolute;
				right: -9px;
				top: -16px;
				height: 30px;
				line-height: 1;
				white-space: nowrap;
				background-color: $bgColor-20;
				min-width: 30px;
				box-sizing: border-box;
				border-radius: 15px;
				padding: 6px 9px 0;
				text-align: center;
				@include font-helvetica-light;

				.published {
					color: $textColor-23;
				}
				.new {
					float: left;
					margin-top: -4px;
					margin-left: -7px;
					margin-right: 5px;
					box-sizing: border-box;
					height: 26px;
					border-bottom-left-radius: 13px;
					border-top-left-radius: 13px;
					padding: 4px 4px 0 6px;
					font-weight: bold;
					background-color: $bgColor-40;
					color: $textColor-10;
				}
			}
			.badge-count-new {
				padding: 7px 10px 0;
				color: $textColor-10;
				height: 26px;
				border-radius: 13px;
				top: 50%;
				left: 100%;
				margin-top: -14px;
				margin-left: -14px;
			}
		}
	}

	.entity-update {
		position: absolute;
		right: 0;
		top: 20px;
		z-index: 91;
		margin-right: -110px;
		transition: margin-right 200ms ease-in;
		&:hover {
			margin-right: 0;
		}
	}
}
