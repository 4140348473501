.page-profile {

	.articles-status-switcher {
		@extend .c-wrapper;
		padding-top: 15px;
		text-align: right;
		.btn {
			margin-right: 20px;
			color: $textColor-30;

			&:hover {
				text-decoration: underline;
			}
		}

		.btn.active {
			font-weight: bold;
			text-decoration: underline;
		}
	}
}


