.redactor-terms-list {
	terms,
	terms-search,
	terms-list {
		display: block;
	}

	.modal-nav {
		padding-top: 0 !important;
	}

	.modal-body {
		background-color: #fff;
		padding: 0 !important;
	}

	.b-filter-table {
		padding: 0;
		margin: 15px 0 7px;
	}

	.term-definition .name {
		font-weight: bold;
		color: $textColor-22;
		~ a:hover {
			color: $textColor-22;
		}
	}

	md-radio-button {
		margin-bottom: 0;
		padding: 20px 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-bottom: 1px dotted $borderColor-20;

		._md-label {
			margin-left: 35px;
			display: block;
		}

		&:hover {
			background-color: lighten($bgColor-55, 2);
		}
		&:last-of-type {
			border-bottom: none;
		}
	}

	.html-styles p {
		line-height: 1.35;
		&:last-of-type {
			margin-bottom: 0 !important;
		}
	}
}
