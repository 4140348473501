.event-form {
	@import "event-dates";
	@import "event-image";
	@import "event__header";

	/**
   * **************** Event Other Styles *****************
   **/

	.roles-hint {
		display: block;
		font-size: em(14px);
		margin-top: 15px;
	}

	.length-counter {
		float: right;
		&.success {
			color: $textColor-32;
		}
		&.warning {
			color: $textColor-51;
		}
	}

	.form-footer.border-top {
		margin-top: 0;
	}
}
