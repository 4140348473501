/* list search block styles */

.b-search {
	display: block;
	@extend %b-search-border-bottom;
}

%b-search-border-bottom {
	border-bottom: 1px solid $borderColor-20;
}

%b-search-border-top {
	border-top: 1px solid $borderColor-20;
}

%b-search-table {
	display: table;
	width: 100%;
	box-sizing: border-box;
	table-layout: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	@extend .c-wrapper;
	&.mt-10 {
		margin-top: 10px;
	}
	&.mb-10 {
		margin-bottom: 10px;
	}
}

%b-search-cell {
	display: table-cell;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	vertical-align: middle;
	@include no-select;
	&.border-small {
		border-left-width: 5px;
		border-right-width: 5px;
	}
	&:first-of-type {
		border-left: none;
	}
	&:last-of-type {
		border-right: none;
	}
	&.cell-stretch {
		width: 100%;
	}
	&.pb-18 {
		padding-top: 18px;
	}
	&.materials-types {
		padding-top: 5px;

		.label, .actions {
			display: inline-block;
			vertical-align: middle;
		}

		.label, .actions a {
			font-size: em(14px);
		}

		.actions {
			display: inline-block;
			margin: 0 0 0 10px;
			vertical-align: middle;

			a {
				height: 16px;
				margin: 0 10px 0 0;
				color: $textColor-29;
				border-bottom: 1px solid transparent;
				transition: color 100ms, border-bottom-color 100ms;
				&:hover, &.active {
					color: $textColor-31;
				}
				&.active {
					font-weight: bold;
					border-bottom-color: $borderColor-12;
				}
			}
		}
	}
}

.b-search-table {
	@extend %b-search-table;
}

.b-search-cell {
	@extend %b-search-cell;
}

.b-search-minimal-cell {
	@extend %b-search-cell;
	width: 1px;
	white-space: nowrap;
}

.b-search-query-pane {
	@extend %b-search-table;
}

.b-search-filters-pane {
}

.b-search-reset-btn-cell {
	@extend %b-search-cell;
	vertical-align: bottom;
	text-align: right;
	.b-search__action {
		display: inline-block;
	}
}
