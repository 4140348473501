.page-form {
	padding-bottom: 70px;
}

.block-form {
	display: block;
}

.page-form {
	.f-section {
		@include pie-clearfix;
		border-bottom: 1px solid $borderColor-25;
		padding-top: 30px;
		padding-bottom: 30px;

		&.f-section-small-padding {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		&.f-section-no-border {
			border-bottom: none;
		}

		&.f-section-gray {
			background-color: $bgColor-59;
		}

		&:last-of-type {
			border-bottom: none;
		}

		.row-field:last-of-type {
			padding-bottom: 0;
		}
	}

	.f-header {
		padding-top: 30px;
		font-size: em(22px);
	}
}

.page-form, .block-form {
	.row-field {
		@include pie-clearfix;
		padding-bottom: 30px;
		overflow: visible;
		&.small-pb {
			padding-bottom: 15px;
		}
		&.no-pb {
			padding-bottom: 0;
		}
	}
}

// Form elements

.page-form, .block-form {
	.field-label {
		display: block;
		padding-bottom: 0.6em;
		&.nowrap {
			white-space: nowrap;
		}
	}

	.field-label_small {
		font-size: em(14px);
	}

	.field-label_suffix::after {
		content: ':';
	}

	.field-hint {
		margin: 10px 0;
		font-size: em(14px);
	}

	.i-field-error {
		@include no-select;
		display: block;
		position: absolute;
		z-index: 99999;
		text-align: center;

		.icon {
			display: block;
			font-size: 20px;
			color: $bgColor-42;
			position: relative;
			top: -1px;
			&::before {
				position: relative;
				z-index: 90;
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 20%;
				height: 80%;
				background-color: #fff;
				top: 10%;
				left: 40%;
				z-index: 89;
			}
		}

		.popover-simple {
			background: $bgColor-42;
			color: $bgColor-20;
			font-size: em(12px);
			padding: 6px 8px;
			min-width: 150px;
			.error {
				padding-bottom: 4px;
				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}

		.popover-simple.top {
			display: none;
			transform: translateX(-50%) translateY(-100%);
			margin: -11px 0 0 10px;
			border-color: darken($borderColor-42, 15%);
			& > .arrow {
				border-top-color: darken($borderColor-42, 15%);
				&::after {
					border-top-color: $borderColor-42;
				}
			}
		}

		.popover-simple.bottom {
			display: none;
			transform: translateX(-50%);
			margin: 31px 0 0 10px;
			border-color: darken($borderColor-42, 15%);
			& > .arrow {
				border-bottom-color: darken($borderColor-42, 15%);
				&::after {
					border-bottom-color: $borderColor-42;
				}
			}
		}

		.popover-simple.popover-input {
			border: none;
			box-shadow: none;
			border-radius: 10px;
			max-width: none;
			display: block;
			padding: 5px 11px 3px;
			min-width: 0;
			.error {
				white-space: nowrap;
			}
		}

		&:hover {
			.popover-simple.top,
			.popover-simple.bottom {
				display: block;
			}
		}
	}

	.row-field-controls {
		position: relative;
		.i-field-error {
			top: -9px;
			right: 12px;
		}

		.popover-simple.popover-input {
			top: auto;
			left: auto;
			bottom: 0;
			right: 35px;
		}

		&.flex {
			display: inline-block;
		}
	}

	// header actions
	.form-header-actions {
		border-bottom: 1px dashed $borderColor-25;
		padding-top: 20px;
		padding-bottom: 20px;
		overflow: hidden;
		.action-right {
			float: right;
		}
	}
	// header actions
	.form-footer-actions {
		border-top: 1px dashed $borderColor-25;
		padding-top: 20px;
		padding-bottom: 20px;
		overflow: hidden;
		.action-right {
			float: right;
		}
	}

	// Form Footer

	.form-footer {
		&.border-top {
			border-top: 1px solid $borderColor-25;
			padding-top: 60px;
			margin-top: 10px;
		}

		.action:not(.btn-link) {
			padding-left: 40px;
			padding-right: 40px;
			&.save {
				padding-left: 20px;
				padding-right: 20px;
				width: 150px;
			}
		}

		.controls-container {
			$actions-offset: 20px;
			position: relative;
			display: table;
			width: 100%;

			.cc-group {
				display: table-cell;
				vertical-align: middle;
			}

			.cc-group.cc-group-right {
				text-align: right;
			}

			.action:not(:first-of-type) {
				margin-left: $actions-offset;
			}

			md-checkbox {
				margin-left: $actions-offset;
				margin-bottom: 0;
			}
		}
	}
}

.has-errors-hint {
	margin-bottom: 25px;
	color: $textColor-51;
	.icon {
		@include no-select;
		display: inline-block;
		vertical-align: top;
		margin-right: 5px;
		margin-top: -2px;
		font-size: 20px;
	}
}

// Table Fields

.page-form table.table-fields {
	tr.table-fields-row {
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
	}

	td.label-cell {
		text-align: right;
		padding-right: 15px;
	}

	.field-label {
		padding: 0;
	}

	.row-field-controls .popover-simple.popover-input {
		right: auto;
		left: 45px;
	}
}
