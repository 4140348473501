.event-dates {
	text-align: center;

	.b-details {
		float: none;
		width: auto;
		display: inline-block;
		margin-right: 90px;
		position: relative;
		vertical-align: top;
	}

	.b-details__header {
		float: left;
		padding: 12px 30px 0 0;
	}

	.date-container {
		border: 1px solid transparent;
		cursor: pointer;
		position: relative;
		background-color: $bgColor-51;
		border-radius: 8px;
		padding: 8px 22px 7px 16px;
		float: right;
		transition: color 80ms ease-out, background-color 80ms ease-out;
		&:hover {
			background-color: $bgColor-11;
			color: $bgColor-20;
		}
		.icon {
			display: block;
			position: absolute;
			right: 6px;
			bottom: 7px;
		}
	}

	.error .date-container {
		border-color: $borderColor-42;
		&:hover {
			border-color: transparent;
		}
	}

	.i-field-error {
		top: -6px;
		right: -6px;
	}
}
