.modal.modal-ligthbox.modal-ligthbox-images {
	.modal-dialog {
		overflow: visible;
		//width: auto;
		//display: table;
	}

	.media-container {
		position: relative;
		padding: 5px 5px 0;
		.image-container {
			min-width: 700px;
			//max-width: $wrapperWidth - ($wrapperPaddingSize*2);
			font-size: 0;
			img {
				max-width: 100%;
				max-height: 80vh;
			}
		}
	}
}

.modal-ligthbox-images .slider-navigation {
	$actionWidth: 60px;
	position: absolute;
	top: 0;
	left: -$actionWidth;
	width: 100%;
	height: 100%;
	padding: 0 $actionWidth;

	.action {
		position: absolute;
		top: 0;
		height: 100%;
		width: $actionWidth;
		cursor: pointer;
		color: $textColor-10;
		font-size: 30px;
		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0.6;
		}
		&:hover {
			background-color: rgba(#FFF, 0.1);
			.icon {
				opacity: 1;
			}
		}
	}
	.action-prev {
		left: 0;
	}
	.action-next {
		right: 0;
	}
}


.modal-ligthbox-images .page-form {
	padding: 0 20px;
	.form-title {
		padding-bottom: 1em;
	}
}
