.audios-list {
  display: block;
	padding-top: 15px;
	@extend .c-wrapper;
}

.audios-list-item .audio-teaser {
	border-bottom: 1px dotted transparent;
	&:hover {
		border-bottom-color: $borderColor-25;
	}
}

// Processing Videos Lists

.processing-audios-list {
	.processing-materials-list {
		padding: 0 15px;
		min-height: 102px;
	}
	.processing-materials-load-container {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.processing-audios-list-item {
	padding: 0 15px;
	border: 1px solid $borderColor-12;
	border-radius: 3px;
	margin: 20px 0;
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 20px 20px;
		border-color: transparent transparent $borderColor-12 transparent;
	}

	.invitations-controls-container {
		overflow: hidden;
		padding: 8px 0;
	}

	.invitations-controls {
		$margin: 8px;
		margin: 0 (-$margin);
		float: right;
		white-space: nowrap;
		button {
			float: left;
			margin: 0 $margin;
		}
	}

	.referrer {
		color: inherit;
		float: left;
		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			width: 30px;
			height: 30px;
			&.image__group {
				border-radius: 3px;
			}
		}
		.name {
			font-weight: bold;
		}
		.hint {
			font-style: italic;
		}
		&:hover .name {
			text-decoration: underline;
		}
	}

	.referrer-info {
		display: inline-block;
		vertical-align: middle;
		font-size: em(14px);
		.hint {
			padding-top: 0.2em;
			display: block;
		}
	}
}
