.user-settings .page-settings-subscribes {
	$colActionsSize: 100px;
	.table-subscribes-header,
	.table-subscribes-body {
		td {
			width: $colActionsSize;
			text-align: center;
			vertical-align: middle;
		}
	}

	.table-subscribes-header {
		width: 100%;
		height: 38px;
		border-bottom: 1px solid $borderColor-20;
		border-left: 30px solid transparent;
		color: $borderColor-27;
		td.entity-type {
			width: auto;
			text-align: left;
		}
	}

	.table-subscribes-body {
		width: $colActionsSize * 5;
		text-align: center;
		.checkbox {
			display: inline-block;
			width: 16px;
			.label {
				padding: 0;
				display: inline-block;
			}
		}
	}

	.col {
		display: table-cell;
		vertical-align: middle;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		&:not(.col-stretch) {
			width: 1px;
			white-space: nowrap;
		}
		&:first-of-type {
			border-left: none;
			width: 65px;
		}
		&:last-of-type {
			border-right: none;
		}
	}

	.col-subscribes-checkbox {
		border: none;
		white-space: nowrap;
		position: relative;
		.error-block {
			position: absolute;
			top: -4px;
			left: 50%;
			transform: translateX(-50%);
			color: $textColor-51;
			cursor: pointer;
			padding: 4px;
			&:hover {
				color: darken($textColor-51, 15%);
			}
		}
	}
}

// Users And Groups Styles
.user-settings .page-settings-subscribes {
	.b-search {
		border-bottom: none;
	}

	.users-list .row,
	.groups-list-item {
		padding: 8px 0 8px 30px;
		border-bottom: 1px solid $borderColor-20 !important;
		margin: 0;
	}

	.user-media-box,
	.group-media-box {
		padding: 0;
		border: none;
	}

	.user-media-box {
		table-layout: auto;
	}

	.group-media-box {
		$avatarSize: 70px;
		display: table;
		width: 100%;
		.image__group {
			width: $avatarSize;
			height: $avatarSize;
			border-radius: 4px;
		}
		.title {
			color: $textColor-23;
			font-size: em(18px);
			font-weight: normal;
			padding: 0;
		}
	}
}
