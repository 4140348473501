html, body {
	height: auto;
}

body {
	font-family: $fontName;
	font-size: $fontSize;
	font-weight: normal;
	font-style: normal;
	line-height: 1;
	color: $textColor;
	background: $bgColor;
	min-width: $wrapperWidth + $wrapperPaddingSize * 2;
	position: relative;
	word-wrap: break-word;
	overflow-y: scroll;
	&.disable-pointer-events, &.disable-pointer-events * {
		pointer-events: none !important;
	}
	&.disable-pointer-events .redactor-box * {
		pointer-events: auto !important;
	}
}

input, textarea, keygen, select, button {
	font-family: $fontName;
	font-size: $fontSize;
	line-height: 1;
	color: $textColor;
	padding: 0;
	margin: 0;
	border: none;
	&::-ms-clear {
		display: none;
	}
}

button {
	background: none;
}

.base-wrapper {
	@extend .clearfix;
	margin: 0 auto;
	padding: 0 $wrapperPaddingSize;
	width: $wrapperWidth;
	display: block;
}

.c-wrapper {
	padding: 0 30px;
}

* {
	text-rendering: optimizeLegibility;
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

.pre-line {
	white-space: pre-line;
}
