.im__content__messages {
	box-sizing: border-box;
	background-color: $bgColor-20;
	.loader-wrapper {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		height: 60px;
		z-index: 1;
		.loading_list {
			height: 60px;
		}
	}
	.messages {
		&__list {
			padding: 8px 10px 105px;
			box-sizing: border-box;
			display: table-cell;
			vertical-align: bottom;
		}
		&__wrapper {
			display: table;
			width: 100%;
			table-layout: fixed;
		}
		&__send {
			box-sizing: border-box;
			background-color: $bgColor-20;
			border-top: 1px solid $borderColor-20;
			position: absolute;
			left: $imChatsWidth;
			bottom: 0;
			right: 0;
			&-fixed {
				position: fixed;
				width: 1078px;
				height: 0;
				left: 50%;
				bottom: 0;
				margin-left: -539px;
			}
			&-checklist {
				position: absolute;
				left: 18px;
				bottom: 9px;
				label {
					color: $textColor-25;
					font-size: 12px;
				}
			}
		}
		&__form {
			background: $bgColor-24;
			padding: 25px 18px 36px;
			table-layout: fixed;
			box-sizing: border-box;
			overflow: hidden;
			display: table;
			width: 100%;
			&-item {
				display: table-cell;
				vertical-align: top;
				&.send {
					width: 125px;
					text-align: right;
				}
			}
			&.disabled {
				text-align: center;
				color: $textColor-25;
				padding: 44px 18px 43px;
				font-style: italic;
				font-size: 16px;
				line-height: 1;
				p {
					margin-bottom: 0;
				}
				a {
					color: $textColor-30;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.input-message {
				max-height: 116px;
				border-radius: 4px;
				height: 40px;
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
				&::placeholder {
					transition: color 0.1s ease;
				}
			}
		}

	}
	.message {
		padding: 6px 10px;
		margin: 0 0 6px;
		display: table;
		table-layout: fixed;
		box-sizing: border-box;
		width: 100%;
		&__cell {
			display: table-cell;
			vertical-align: top;
			position: relative;
			&.image {
				width: 55px;
			}
		}
		&__image {
			width: 45px;
			height: 45px;
			display: inline-block;
			vertical-align: top;
			&-link {
				display: inline-block;
				vertical-align: top;
			}
			img {
				width: 100%;
				height: auto;
			}

		}
		&__item {
			&.name {
				margin-bottom: 3px;
			}
			&.content {
				white-space: pre-line;
				word-wrap: break-word;
				font-size: 14px;
				line-height: 20px;
			}
		}
		&__date,
		&__name {
			display: inline-block;
			vertical-align: top;
		}
		&__date {
			font-size: 12px;
			color: $textColor-15;
			text-align: right;
			position: absolute;
			right: 0;
			top: 0;
		}
		&__name {
			color: $textColor-23;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			&:hover {
				text-decoration: underline;
			}
		}

		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		&.new {
			background-color: $bgColor-59;
		}
		&.not {
			padding: 20px 10px;
			p {
				text-align: center;
				color: $textColor-11;
				font-style: italic;
				margin: 0;
			}
		}
	}
}
