.news-list {
	padding-top: 20px;
	padding-bottom: 80px;
}

.news-list-item {
	padding: 15px 0;
	border-bottom: 1px dotted $borderColor-20;
	@include pie-clearfix;
	&:first-of-type {
		padding-top: 0;
	}

	&:hover {
		.note-header .controls {
			visibility: visible;
		}
	}
}

.news-list-item .avatar-container {
	float: left;
	.image__user, .image__group {
		width: 50px;
		height: 50px;
	}
	.image__group {
		border-radius: 3px;
	}
}

.news-list-item .note-container {
	margin-left: 65px;
}

.note-header {
	$fontSize: 16px;
	overflow: hidden;
	padding-bottom: 8px;
	.controls {
		float: right;
		visibility: hidden;
		border-right: 1px dotted $borderColor-20;
		margin-right: 7px;
		padding-right: 7px;
		button {
			font-size: em(12px);
			overflow: hidden;
			color: $textColor-13;
			float: left;
			cursor: pointer;
			opacity: 0.5;
			.icon {
				position: relative;
				top: 1px;
			}
			&:hover {
				opacity: 1;
			}
			&.remove:hover {
				color: $textColor-51;
			}
		}
	}
	.date {
		float: right;
		font-size: em(12px);
		line-height: $fontSize;
		color: $textColor-13;
	}
	.author {
		display: inline-block;
		vertical-align: middle;
		font-size: em($fontSize);
		a:hover {
			color: $textColor-32;
		}
	}
	.note-avatar {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		.image__user, .image__group {
			width: 50px;
			height: 50px;
		}
		.image__group {
			border-radius: 3px;
		}
	}
}

.note-content {
	line-height: 1.3;
	white-space: pre-line;
	margin-bottom: 10px;
}

.news-list-item .note-comments {
	$animationSpeed: 150ms;
	margin-top: 10px;
	min-height: 18px;
	position: relative;
	.button-new-comment {
		color: $textColor-60;
		cursor: pointer;
		font-size: em(14px);
		padding: 2px 0;
		position: absolute;
		left: 0;
		bottom: 0;
		&:hover {
			color: darken($bgColor-31, 5%);
		}
		&.ng-enter {
			transition: $animationSpeed opacity;
			transition-delay: $animationSpeed * 2;
			opacity: 0;
			&.ng-enter-active {
				opacity: 1;
			}
		}
	}

	.note-comments-container {
		background-color: $bgColor-24;
		border-radius: 5px;
		padding: 10px;
		&.ng-enter {
			transition: $animationSpeed transform;
			transform: translateY(-20%) scale(0.9);
			&.ng-enter-active {
				transform: translateY(0) scale(1);
			}
		}
	}

	.button-show-all-comments {
		display: block;
		width: 100%;
		font-size: em(14px);
		padding: 12px 0 10px;
		cursor: pointer;
		font-style: italic;
		opacity: 0.6;
		background-color: transparent;
		transition: opacity 100ms, background-color 100ms;
		margin-bottom: 10px;
		&:hover {
			opacity: 1;
			background-color: $bgColor-25;
		}
	}
}

