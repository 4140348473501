.wiki_videos {
	.videos-list {
		margin: 10px 0 0;
		padding: 0 15px;
		&-item {
			width: 50%;
		}
	}
	.video-teaser {
		.thumbnail {
			width: auto;
			img {
				width: 100%;
				height: auto;
			}
		}
	}

}
