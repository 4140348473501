// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
body.modal-open {
	overflow: hidden;

	//position:fixed;
	//width: 100%;
	//height: 100%;
	& > .layout {
		//pointer-events: none;
	}
}

// Container that the modal scrolls within
.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $modalZIndex;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;

	// When fading in the modal, animate it to slide down
	//&.fade .modal-dialog {
	//	.translate(0, -25%);
	//	.transition-transform(~"0.3s ease-out");
	//}
	//&.in .modal-dialog { .translate(0, 0) }
}

.modal-open .modal {
	overflow-x: auto;
	overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
	position: relative;
	width: auto;
	margin: 30px auto 50px;
}

// Actual modal
.modal-content {
	position: relative;
	//.box-shadow(0 3px 9px rgba(0,0,0,.5));
	background-clip: padding-box;
	// Remove focus outline from opened modal
	outline: 0;
}

// Modal background
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $bgColor-50;
	opacity: $backdropOpacity;
	// Fade for backdrop
	//&.fade { .opacity(0); }
	//&.in { .opacity(@modal-backdrop-opacity); }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}


.modal {
	&.modal-fake {
		display: block;
	}

	.modal-close {
		display: block;
		position: fixed;
		top: 20px;
		right: 30px;
		font-size: 26px;
		color: $textColor-10;
		opacity: .7;
		&:hover {
			opacity: 1;
			color: $textColor-10;
		}
		.icon {
			color: $textColor-10;
		}
	}
}

