.sharing-options-form {
	$radio-offset: 30px;

	.section-title {
		font-weight: bold;
		font-size: em(17px);
		margin: 0 0 20px;
		display: block;
	}

	.row-field {
		.section-title {
			margin-bottom: 8px;
		}

		.row-field-controls {
			padding-left: $radio-offset;
			margin: 12px 0;
		}
	}

	.field-hint {
		color: $textColor-13;
		margin: 0;
	}

	.radio label {
		padding-left: $radio-offset;
		display: block;
	}

	.input-message {
		min-height: 100px;
		max-height: 200px;
	}
}
