.btn-link {
	cursor: pointer;
	color: $linkColor;
	vertical-align: middle;
	&:hover {
		color: darken($linkColor, 20%);
	}

	&.color-warning {
		color: $textColor-51;
		&:hover {
			color: darken($textColor-51, 20%);
		}
	}

	&.color-info {
		color: $textColor-60;
		&:hover {
			color: darken($textColor-60, 20%);
		}
	}

	&.color-success {
		color: $textColor-30;
		&:hover {
			color: darken($textColor-30, 20%);
		}
	}
}
