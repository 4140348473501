.videos-list {
	margin: 0;
}

.videos-list-item {
	float: none;
	width: auto;
	height: auto;
	padding: 20px 0;
	overflow: hidden;
	border-bottom: 1px solid $borderColor-20;
}

.videos-list-item .manage-controls {
	padding-top: 20px;
}
