.profile-image {
	position: relative;
	float: left;
	overflow: hidden;
	width: 184px;
	height: 184px;
	border-radius: 50%;
	box-sizing: border-box;
	border: 1px solid $borderColor-24;
	box-shadow: 0 2px 0 0 rgba(#000, .3);

	.image__user {
		width: 100%;
		height: 100%;
	}
}
