.datetimepicker {
	$defaultBgColor: lighten($bgColor-57, 2.5%);
	$defaultColor: $textColor-23;
	$activeBgColor: $bgColor-11;
	$activeColor: $textColor-10;
	$headHoverColor: $textColor-32;

	%defaultStyles {
		border-radius: 6px;
		box-sizing: border-box;
		border: 2px solid transparent;
		cursor: pointer;
		background-color: $defaultBgColor;
		color: $defaultColor;
		font-size: em(14px);
		padding: 10px 0 7px;

		&:not(.disabled) {
			&:hover {
				background-color: darken($defaultBgColor, 3%);
			}
			&:active {
				color: $activeColor;
				background-color: darken($activeBgColor, 7%);
			}
		}

		&.past, &.future {
			color: lighten($defaultColor, 15%);
			background: none;
		}

		&.disabled {
			background: none;
			color: lighten($defaultColor, 55%);
			cursor: default;
		}

		&.active {
			background-color: $activeBgColor;
			color: $activeColor;
			font-weight: bold;

			&:not(.disabled) {
				&:hover {
					border-color: darken($activeBgColor, 7%);
					background-color: darken($activeBgColor, 7%);
				}
				&:active {
					border-color: darken($activeBgColor, 12%);
					background-color: darken($activeBgColor, 12%);
				}
			}
		}
	}

	width: 253px;

	table {
		width: 100%;
		border-spacing: 1px;
		border-collapse: separate;

		th {
			padding: 10px 8px 8px;
		}

		td, th {
			text-align: center;
			box-sizing: border-box;
			@include no-select;
		}

		thead {
			tr:first-of-type > th {
				cursor: pointer;
				color: $textColor-32;
				border-radius: 4px;
				box-sizing: border-box;
				font-weight: bold;
				font-size: em(18px);
				&:hover {
					color: $activeColor;
					background-color: $bgColor-11;
				}
			}

			.left, .right {
				width: 34px;
				padding-left: 0;
				padding-right: 0;
			}
		}

		td.day {
			width: 35px;
			height: 35px;
			@extend %defaultStyles;
		}

		td > span {
			display: block;
			width: 23%;
			float: left;
			margin: 1%;
			@extend %defaultStyles;

			&.hour, &.minute {
				height: auto;
				line-height: 1;
				font-size: em(16px);
			}

			&.month, &.year {
				font-size: em(16px);
				padding: 19px 0 18px;
			}
		}
	}

	.glyphicon {
		@include font-absolutera;
		&.glyphicon-arrow-left {
			@extend .icon-arrow-left;
		}

		&.glyphicon-arrow-right {
			@extend .icon-arrow-right;
		}

		.sr-only {
			display: none;
		}
	}
}

// Other styles

.datetimepicker.datetimepicker-bordered {
	$defaultBgColor: lighten($bgColor-57, 2.5%);
	$defaultColor: $textColor-23;
	$activeBgColor: $bgColor-11;

	%styles {
		&:not(.disabled) {
			border-color: $activeBgColor;
			&:active {
				background-color: darken($activeBgColor, 7%);
				border-color: darken($activeBgColor, 7%);
			}
		}

		&:not(.past):not(.future).disabled {
			background-color: $defaultBgColor;
			color: $defaultColor;
		}
	}

	table {
		td.day {
			@extend %styles;
		}

		td > span {
			@extend %styles;
		}
	}
}
