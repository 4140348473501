.content_selector {
	.image-selector {
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
		width: auto;
		height: 140px;
		border: 1px solid transparent;
		transition: border-color .3s ease;
		&:hover {
			border-color: rgba($borderColor-13, 0.4);
			.image-selector-icon_radio {
				&:after {
					opacity: 0.5;
				}
			}
		}
		&.active {
			border-color: $borderColor-13;
			.image-selector-icon_radio {
				&:after {
					border-color: $borderColor-13;
					opacity: 1;
				}
			}
		}
		&-icon {
			width: 20px;
			position: absolute;
			left: 6px;
			top: 6px;
			&_radio {
				display: inline-block;
				width: 16px;
				height: 16px;
				cursor: pointer;
				position: relative;
				vertical-align: top;
				padding: 3px;
				background: $bgColor-20;
				border-radius: 2px;
				&:after {
					content: '';
					width: 11px;
					height: 7px;
					border: 4px solid $borderColor-28;
					border-top: none;
					border-right: none;
					background: transparent;
					transform: rotate(-45deg);
					transition: 0.15s ease opacity, 0.15s ease border-color;
					position: absolute;
					opacity: 0.25;
				}
			}
		}
		&-view {
			display: block;
			width: 100%;
			height: 100%;
			@include block-fill-image;
		}
	}
}
