.page-settings-wrapper {
	.page__tabs {
		position: relative;
		z-index: 60;
	}
}

.page-settings-navigable {
	$menuWidth: 210px;

	.menu {
		width: $menuWidth;
		box-sizing: border-box;
		float: left;
		margin-top: 2px;
		padding-top: 30px;
		padding-bottom: 30px;
		text-align: right;
		@extend .c-wrapper;
		&::after {
			content: '';
			display: block;
			border-right: 1px solid $borderColor-20;
			height: 100%;
			width: 0;
			position: absolute;
			top: 0;
			float: left;
			left: $menuWidth;
			z-index: 50;
		}

		li {
			$horizontalPadding: 8px;
			&.menu-header {
				padding: 7px $horizontalPadding 10px;
				font-size: em(17px);
				font-weight: bold;
				&:first-of-type {
					padding-top: 0;
				}
			}

			&.separator {
				margin: 20px 0;
				height: 1px;
				background-color: $borderColor-20;
				display: block;
			}

			&.menu-item {
				a {
					display: block;
					padding: 7px $horizontalPadding;
					color: $textColor-18;
					transition: background-color 50ms;
				}

				&:not(.active) a:hover {
					background-color: $bgColor-23;
				}

				&.active a {
					color: $textColor-30;
					cursor: default;
				}
			}
		}
	}

	.content {
		display: block;
		margin-left: $menuWidth;
		overflow: hidden;
	}

	.settings-description {
		margin-top: 10px;
		font-size: em(14px);
		padding-top: 15px;
		padding-bottom: 15px;
		background-color: $bgColor-59;
		border-top: 1px solid $borderColor-20;
		border-bottom: 1px solid $borderColor-20;
		p {
			line-height: 1.5;
		}
	}

	.page-form .form-footer {
		padding-top: 20px;
	}
}
