.btn1 {
	cursor: pointer;
	padding: 10px 16px;
	line-height: 1;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 18px;
	white-space: nowrap;
	@include transition-bg-color('fast');
}

// # Colors

.btn1-blue {
	color: $textColor-10;
	background-color: $bgColor-30;
	border-top: 1px solid rgba(#000, 0.2);
	border-bottom: 1px solid rgba(#FFF, 0.2);
	&:hover {
		background-color: darken($bgColor-30, 10%);
	}
}

.btn1-white {
	border: 1px solid $borderColor-28;
	color: $textColor-22;
	background-color: $bgColor-20;
	&:hover {
		background-color: darken($bgColor-20, 5%);
	}
}

.btn1-green {
	color: $textColor-10;
	background-color: $bgColor-11;
	border-top: 1px solid rgba(#000, 0.2);
	border-bottom: 1px solid rgba(#FFF, 0.2);
	&:hover {
		background-color: darken($bgColor-11, 10%);
	}
	&[disabled] {
		$amount: 20%;
		cursor: default;
		background-color: lighten($bgColor-11, $amount);
	}
}
