textarea {
	display: block;
	width: 100%;
	box-sizing: border-box;
	line-height: 1.3;
	resize: none !important;

	&.textarea-style-1 {
		border: 1px solid $borderColor-25;
		border-radius: 8px;
		padding: 13px;
		&.msd-elastic-mini {
			height: 48px;
		}
	}

	&.textarea-style-2 {
		border-radius: 18px;
		box-shadow: 0 1px 0 0 rgba(#000, 0.07);
		font-size: em(14px);
		padding: 11px 20px;
		&.msd-elastic-mini {
			height: 36px;
			min-height: 36px;
		}
	}

	&.textarea-style-3 {
		border-radius: 8px;
		box-shadow: 0 1px 0 0 rgba(#000, 0.07);
		font-size: em(14px);
		padding: 11px 20px;
		&.msd-elastic-mini {
			height: 36px;
			min-height: 36px;
		}
	}
}
