.term-definition-popover {

	.body-container {
		max-width: 320px;
		max-height: 300px;
		background-clip: padding-box;
		background: #fff;
		box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
		border-radius: 4px;
		overflow: hidden;
		overflow-y: auto;
		padding: 15px 20px;

		&[ng-click] {
			cursor: pointer;

			&:hover {
				background-color: lighten($bgColor-55, 3);
			}
		}
	}

	.body-content {
		p {
			font-weight: normal;
			font-size: em(14px);
			line-height: 1.4;
			font-style: normal;
			&:last-of-type {
				margin-bottom: 0 !important;
			}
		}
	}
}
