.modal.modal-style-6.content_selector {
	$vertical-padding: 20px;
	$horizontal-padding: 30px;
	$modal-width: 800px;

	&.reset-search {
		.b-search {
			border: none;
		}
		.b-search-query-pane {
			padding: 0;
			margin: 10px 0;
			border: none;
		}
	}

	.content_selector-not {
		text-align: center;
		padding: 30px 0;
		p {
			color: $bgColor-28;
			font-style: italic;
		}
	}

	.loader-wrapper {
		height: 0;
		.loading_list {
			height: 30px;
		}
	}

	.modal-dialog {
		width: $modal-width;
	}

	.modal-body {
		height: 550px;
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 10px;
		&.pb0 {
			padding: 0;
		}
		&-content {
			margin: 0 auto 30px;
			width: 740px;
		}
	}

	&.upload-avatar {
		&.clear-height {
			.modal-body {
				height: auto;
			}
		}
	}

	.modal-nav {
		padding: $vertical-padding $horizontal-padding 0;
		border-bottom: 1px solid $borderColor-20;
		.menu_tabs.menu_tabs_buttons {
			padding: 0;
			border: none;
		}
	}
}
