.b-profile {
	@extend .separated-sections;
	padding-bottom: 40px;
}

.b-profile-section {
	@extend .c-wrapper;
	@extend .section;
	display: block;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin-top: 30px;
	margin-bottom: 30px;
	box-sizing: border-box;
}

.b-profile-info {
	@extend .b-profile-section;
	border-bottom: none !important;
	display: table;
	width: 100%;

	.b-profile-info-column {
		display: table-cell;
		vertical-align: top;
	}

	ul.b-profile-info-contacts {
		@extend .b-profile-info-column;
		border-right: 40px solid transparent;
		li {
			display: table-row;
			line-height: 1.5;
		}
		.label {
			display: table-cell;
			border-right: 20px solid transparent;
			white-space: nowrap;
			vertical-align: top;
			&::after {
				content: ':';
			}
		}
		.value {
			display: table-cell;
			white-space: nowrap;
			vertical-align: top;
			a {
				display: inline-block;
				white-space: nowrap;
				max-width: 350px;
				overflow: hidden;
				text-overflow: ellipsis;
				color: $textColor-32;
				&:hover {
					color: darken($textColor-32, 10%);
					text-decoration: underline;
				}
			}
		}
	}

	.b-profile-info-about {
		@extend .b-profile-info-column;
		width: 100%;
		line-height: 1.5;
		white-space: pre-line;
		p {
			line-height: inherit;
		}
	}
	.b-profile-info_none {
		$fsize: 16px;
		font-size: $fsize;
		line-height: $fsize;
		color: $bgColor-28;
		text-align: center;
		cursor: default;
		&-icon {
			$size: 24px;
			@extend .base-icons-write;
			display: inline-block;
			width: $size;
			height: $size;
			vertical-align: middle;
			margin-right: 10px;
		}
		&-text {
			padding-top: 3px;
			display: inline-block;
			vertical-align: middle;
			a {
				color: $textColor-30;
				transition: color 0.3s ease;
				&:hover {
					color: $textColor-31;
					text-decoration: underline;
				}
			}
		}
	}
}

.b-profile-tags {
	@extend .b-profile-section;
	border-bottom: none !important;

	.tags-wrapper {
		margin: -3px -5px;
	}

	.tag {
		position: relative;
		display: inline-block;
		border: 1px solid $borderColor-41;
		color: $textColor-40;
		padding: 7px 10px 6px;
		border-radius: 16px;
		margin: 3px 5px;
		font-size: em(14px);
		white-space: nowrap;

		.delete {
			@include font-absolutera;
			@extend .icon-clear-circle-fill;
			cursor: pointer;
			color: $textColor-40;
			background-color: #fff;
			display: block;
			border-radius: 50%;
			position: absolute;
			top: -8px;
			right: 0;
			box-sizing: border-box;
			transition: transform 150ms, color 150ms, opacity 150ms;
			opacity: 0.8;
			&:hover {
				transform: scale(1.3) rotate(180deg);
				color: $textColor-50 !important;
				opacity: 1;
			}
		}
	}
}

.b-profile-users {
	@extend .b-profile-section;
	border-bottom: none !important;
	.section__header {
		padding-bottom: 20px;
	}
}

.b-profile-wall {
	border-top: 1px solid $borderColor-23;
	display: block;
	.news-form {
		border-bottom: none;
	}
}
