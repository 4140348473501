figure[data-type-image] {
	display: table;

	img {
		max-width: 100%;
	}

	&[data-position="left"] {
		float: left;
		margin-right: 20px;
	}
	&[data-position="right"] {
		float: right;
		margin-left: 20px;
	}
	&[data-position="center"] {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	&[data-position="none"] {

	}
}

figure[data-type-image] span {
	display: none;
}

.redactor-editor figure[data-type-image]#redactor-image-box {
	position: relative;
	outline: #fff dashed 1px;
	box-shadow: 0 0 4px 2px rgba(#000, 0.6);

	span#redactor-image-resizer {
		display: block;
		position: absolute;
		z-index: 2;
		line-height: 0;
		cursor: nw-resize;
		bottom: 0;
		right: 0;
		width: 11px;
		height: 11px;
		background: $bgColor-11;
		border-top: 1px dashed #fff;
		border-left: 1px dashed #fff;
		box-sizing: border-box;

		&[anchor-type='left'] {
			right: auto;
			left: 0;
		}
	}

	span#redactor-image-button-update {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 5;
		transform: translate(-50%, -50%);
		cursor: pointer;
		user-select: none;
		font-size: 22px;
		color: $textColor-10;
		line-height: 1;
		text-shadow: 1px 1px 2px rgba(#000, 0.5);

		&::before {
			background: $bgColor-11;
			border-radius: 5%;
			border: 1px solid #fff;
			box-shadow: 0 0 2px rgba($bgColor-50, 0.7);
			transition: transform 0.3s ease;
			padding: 6px;
		}

		&:hover::before {
			transform: scale(1.1);
		}
	}
}

#redactor-modal-image-update {
	select#redactor-image-position {
		background: #fff url("http://www.scottgood.com/jsg/blog.nsf/images/arrowdown.gif") no-repeat 97% 50%;
	}
}
