@function em($target, $context: $fontSize) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context + 0em;
}

@function cp($target, $context: $wrapperWidth) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context * 100%;
}

@function retina-filename($filename, $extention: '.png') {
	@return $filename + '@2x' + $extention;
}


@function ww($point) {
	@if $point == maxwidth {
		@return 2400px;
	} @else if $point == laptop {
		@return 1200px;
	} @else if $point == tablet-landscape {
		@return 1050px;
	} @else if $point == tablet-portrait {
		@return 900px;
	}
}

@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}


@function getTimeByName($name) {
	@if $name == 'slow' {
		@return 400ms;
	} @else if $name == 'normal' {
		@return 200ms;
	} @else if $name == 'fast' {
		@return 150ms;
	}
	@return 200ms;
}


