.search-query-pane {
	display: table;
	width: 100%;
	box-sizing: border-box;
	table-layout: auto;
	padding-top: 20px;

	@extend .c-wrapper;
	&.mt-10 {
		margin-top: 10px;
	}
	&.mb-10 {
		margin-bottom: 10px;
	}
	.search__form {
		position: relative;

		.icon {
			font-size: 16px;
			position: absolute;
			left: 15px;
			top: 50%;
			margin-top: -8px;
			color: #7F7F7F;
		}
		.textfield {
			width: 100%;
			box-sizing: border-box;
			padding-left: 40px;
		}
		.summary {
			color: #A1A1A1;
			font-size: 0.875em;
			white-space: nowrap;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -6px;
			opacity: 1;
		}
	}
}
