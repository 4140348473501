.images-list {
	@include pie-clearfix;
	@extend .c-wrapper;
	margin: 10px -2px 0;
}

.images-list-item {
	float: left;
	width: 20%;
	padding: 2px;
	box-sizing: border-box;
}
