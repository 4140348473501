.article {

	/***  Header  ***/

	.a-header {
		@include pie-clearfix;

		.article-image {
			@include no-select;
			float: left;
			width: 142px;
			height: 142px;
			border: 1px solid $borderColor-20;
			border-radius: 8px;
			overflow: hidden;
		}

		.image__article {
			width: 100%;
			height: 100%;
			border-radius: 0;
		}

		.header-container {
			margin-left: 170px;
			padding-top: 5px;
		}
	}
}
