.btn4 {
	cursor: pointer;
	font-size: em(14px);
	padding: 4px 10px;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 4px;
	text-align: center;
	white-space: nowrap;
}


// # Colors

.btn4-blue {
	color: $textColor-10;
	background-color: $bgColor-30;
	&:hover {
		background-color: $bgColor-31;
	}
}

.btn4-white {
	color: $textColor-31;
	background-color: $bgColor-20;
	border: 1px solid $borderColor-12;
	padding-top: 4px;
	padding-bottom: 2px;
}

.btn4-red {
	color: $textColor-10;
	background-color: $bgColor-40;
}

.btn4-green {
	color: $textColor-10;
	background-color: $bgColor-11;
	&:hover {
		background-color: $bgColor-12;
	}
}
