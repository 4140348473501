.post-message {
	&-more {
		.l-show_more {
			color: $textColor-30;
			border-bottom: 1px dashed $textColor-30;
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}
}
