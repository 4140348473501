md-chips {
	.md-chips {
		font-family: $fontName;
		padding: 0 0 8px 8px;
		box-shadow: none;
		border-radius: 4px;
		border: 1px solid #D0D5D8;
		font-size: 14px;

		&.md-focused {
			box-shadow: none;
		}

		md-chip {
			user-select: none;
			background-color: $bgColor-23;
			color: $textColor-20;
			border: 1px solid $bgColor-21;
			border-radius: 3px;

			._md-chip-remove-container {
				line-height: 16px;
				._md-chip-remove {
					vertical-align: top;
				}
			}

			._md-chip-remove {
				md-icon {
					min-height: 20px;
					min-width: 20px;
				}
			}

			&.md-focused {
				background-color: $bgColor-21;
				color: $textColor-20;
				._md-chip-remove md-icon {
					color: $textColor-20;
				}
			}
		}
	}
}
