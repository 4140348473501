.section-header {
	display: block;
	background: $bgColor-59;
	border-bottom: 1px solid $borderColor-20;
	border-top: 1px solid $borderColor-20;
	margin-bottom: 20px;
	margin-top: -1px;
	@include pie-clearfix;

	&.bordered {
		border-bottom: 1px solid $borderColor-20;
		border-top: 1px solid $borderColor-20;
	}

	&::before {
		content: '';
		display: table;
		clear: both;
	}

	.sh-row {
		@include pie-clearfix;
		@extend .c-wrapper;
		margin-top: 20px;
		margin-bottom: 20px;
		display: block;
	}
	.sh-row-table {
		@extend .c-wrapper;
		margin-top: 20px;
		margin-bottom: 20px;
		display: table;
		width: 100%;
		box-sizing: border-box;
		.sh-cell {
			display: table-cell;
			vertical-align: middle;
			&.right {
				text-align: right;
			}
		}
	}

	.sh-title {
		display: inline-block;
		color: $textColor-25;
		font-size: em(20px);
		line-height: 20px;
		vertical-align: baseline;
	}

	.sh-badge {
		@include font-helvetica-light;
		border: 1px solid $borderColor-20;
		border-radius: 12px;
		margin-left: 0.8em;
		min-width: 26px;
		text-align: center;
		padding: 4px 6px 5px;
		box-sizing: border-box;
		font-size: 15px;
		line-height: 1;
		display: inline-block;
		background: $bgColor-20;
	}

	.sh-action-right {
		float: right;
		margin-left: 1.5em;
	}

	.sh-action-left {
		display: inline-block;
		margin-right: 1.5em;
	}

	.sh-action-warning {
		cursor: pointer;
		color: $textColor-51;
		&:hover {
			color: darken($textColor-51, 20%);
		}
	}
}
