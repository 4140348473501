.page-profile-view .profile-header {
	overflow: hidden;

	/**
	 * **************** Event Header Info Section *****************
	 **/

	.info {
		height: auto;
		width: 100%;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	/**
	 * **************** Event Header Other Styles *****************
	 **/

	.title {
		font-size: em(31px);
		color: $textColor-10;
		font-weight: normal;
	}

	.nickname {
		color: $textColor-10;
		background: $bgColor-11;
		white-space: nowrap;
		line-height: 26px;
		padding: 0 13px;
		position: absolute;
		bottom: -10px;
		left: 122px;
		transform: translateX(-50%);
		border-radius: 13px;
		box-shadow: 0 2px 0 0 rgba(#000, .3);
	}

	.subtitle {
		padding-top: 5px;
		color: $textColor-10;
		.icon {
			position: relative;
			top: 2px;
			font-size: em(18px);
			margin-right: 3px;
		}
	}

	.actions {
		padding-top: 20px;
		margin: 0 -7px;

		.action {
			margin: 0 7px;
			display: inline-block;
		}

		.action.action-connect {
			min-width: 170px;
			display: inline-block;
			button {
				display: block;
				width: 100%;
				box-sizing: border-box;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.entity-update {
		position: absolute;
		right: 0;
		top: 20px;
		z-index: 91;
		margin-right: -110px;
		transition: margin-right 200ms ease-in;
		&:hover {
			margin-right: 0;
		}
	}
}
