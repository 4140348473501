@mixin background-image-2x($filename, $width, $height, $extention: '.png') {
	//@include background-image(image-url($filename + $extention));
	//@include background-size($width $height);
	background-image: url($filename + $extention);
	background-size: $width $height;
	@media (-webkit-min-device-pixel-ratio: 1.3),
	(min--moz-device-pixel-ratio: 1.3),
	(-o-min-device-pixel-ratio: 2.6/2),
	(min-device-pixel-ratio: 1.3),
	(min-resolution: 1.3dppx) {
		//@include background-image(image-url(retina-filename($filename, $extention)));
		$retina: retina-filename($filename, $extention);
		background-image: url($retina);
	}
}

@mixin breakpoint($point) {
	@media (max-width: ww($point)) {
		@content;
	}
}


@mixin reset-btn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
//  @include appearance(normal);
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &.empty {
    @content;
  }
}

@mixin link-underline {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin pie-clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin stretch($offset-top: 0, $offset-right: 0, $offset-bottom: 0, $offset-left: 0) {
	position: absolute;
	@if $offset-top {
		top: $offset-top;
	}
	@if $offset-bottom {
		bottom: $offset-bottom;
	}
	@if $offset-left {
		left: $offset-left;
	}
	@if $offset-right {
		right: $offset-right;
	}
}