.manage-content-tags {
	margin-top: 20px;
	border: 1px solid $borderColor-20;
	border-radius: 5px;
	overflow: hidden;
	.header-container {
		padding: 7px 15px 8px;
		background: $bgColor-23;
		&.awaiting_moderation {
			background: rgba($bgColor-42, 0.1);
		}
		&.moderated {
			background: rgba($bgColor-10, 0.2);
		}
	}

	.content-tags-container {
		padding: 8px 30px;
		border-top: 1px solid $borderColor-20;
	}

	.icon-container {
		width: 30px;
		text-align: left;
		font-size: 16px;
		.icon-checkmark {
			color: $textColor-30;
			font-size: 14px;
		}
		.icon-locked {
			color: darken($textColor-51, 5%);
		}
		.icon-warning {
			color: $textColor-60
		}
	}

	.buttons-container {
		padding-left: 30px;

		button {
			display: inline-block;
			margin: 2px 5px;
		}
	}
}

.images-list-item .manage-content-tags {
	margin: 0 0 0 220px;
}

.image-teaser_wrapper {
	width: 200px;
	height: 130px;
	display: inline;
}

.s-count_content {
	display: inline-block;
	padding: 4px 8px;
	border: 1px solid $borderColor-21;
	vertical-align: middle;
	border-radius: 15px;
}
.s-text-label {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}