.content_selector {
	.content_selector-item.audio {
		.content_selector-item-info {
			width: 80px;
			.state-button, .duration {
				display: inline-block;
				vertical-align: middle;
			}
			.state-button {
				font-size: 30px;
				line-height: 30px;
				cursor: pointer;
				color: $bgColor-10;
				display: inline-block;
				vertical-align: middle;
				width: 30px;
				.button {
					width: 30px;
					display: inline-block;
					vertical-align: top;
					position: relative;
				}
			}
			.duration {
				margin-left: 10px;
			}
		}
		.content_selector-item-details {
			padding-left: 10px;
			.title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				a {
					cursor: pointer;
					font-size: 14px;
					line-height: 18px;
					color: $textColor-24;
					&:hover {
						color: $textColor-32;
					}
				}
			}
			.artist {
				color: $textColor-18;
			}
		}
	}
}
