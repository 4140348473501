.btn2 {
	border: 1px solid transparent;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: inset 0 -1px 0 0 rgba(#000, .3);
	cursor: pointer;
	display: inline-block;
	font-size: em(14px);
	padding: 9px 29px 8px;
	white-space: nowrap;
}

// # Colors

.btn2-green {
	background-color: $bgColor-10;
	color: $textColor-10;
	&:hover {
		background-color: $bgColor-11;
		color: $textColor-10;
	}
}

.btn2-white {
	border: 1px solid $borderColor-28;
	color: $textColor-22;
	background-color: $bgColor-20;
	&:hover {
		background-color: darken($bgColor-20, 5%);
	}
}
