.albums-list {
	margin: -10px;
	overflow: hidden;
	.album-teaser-container {
		$height: 230px;
		float: left;
		height: $height;
		width: 33.3333%;
		padding: 10px;
		box-sizing: border-box;
	}
}

.album-teaser-container {
	width: 325px;
	height: 210px;
}

.album-teaser {
	background: $bgColor-20;
	overflow: hidden;
	height: 100%;
	display: block;
	position: relative;
	box-shadow: 0 0 1px $borderColor-20;
	@include block-fill-image;

	.details {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		color: $textColor-10;
		padding: 12px 15px 11px;
		background: rgba(#000, 0.6);
		font-size: em(13px);

		.count {
			float: right;
			@include font-helvetica-light;
		}
		.title {
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.album-icon {
		position: absolute;
		top: 58px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 60px;
		color: $textColor-21;
	}

	.album-cover {}

	.album-icon {
		z-index: 10;
	}
	.album-cover, .details {
		z-index: 11;
	}
}
