.btn5 {
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	transition: background-color 100ms, color 100ms;
	border-radius: 5px;
	box-sizing: border-box;
	font-size: em(14px);
	padding: 8px 10px;
	border: 1px solid transparent;
	line-height: 1;
}

// # Sizes

.btn5-small {
	font-size: em(12px);
	padding: 7px 13px;
	border-radius: 4px;
}

.btn5-large {
	font-size: em(16px);
	border-radius: 8px;
	padding: 10px 15px;
}

.btn5-right-space {
	margin-right: 10px;
}


// # Colors

.btn5-white {
	color: $textColor-23;
	background-color: $bgColor-20;
	border-color: $borderColor-26;
	&:not([disabled]):hover {
		color: darken($textColor-23, 5%);
		background-color: darken($bgColor-20, 5%);
		border-color: darken($borderColor-26, 5%);
	}
	&[disabled] {
		opacity: 0.5;
		cursor: default;
		.icon {
			color: $textColor-23;
		}
	}
	&.btn5-back {
		position: relative;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		margin-left: 13px;

		&:after, &:before {
			right: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-color: transparent;
			border-right-color: #ffffff;
			border-width: 13px;
			margin-top: -13px;
			transition: border-right-color 100ms;
		}
		&:before {
			border-color: rgba(222, 222, 222, 0);
			border-right-color: $borderColor-26;
			border-width: 14px;
			margin-top: -14px;
			transition: border-right-color 100ms;
		}

		&:not([disabled]):hover {
			&::after {
				border-right-color: darken($bgColor-20, 5%);
			}
			&::before {
				border-right-color: darken($borderColor-26, 5%);
			}
		}
	}
}

.btn5-transparent {
	color: $textColor-13;
	border-color: $borderColor-26;
	border-radius: 5px;
	background-color: transparent;
	&:hover {
		color: $textColor-21;
		background-color: rgba($bgColor-57, 0.5);
	}
}

.btn5-transparent-white {
	color: $textColor-10;
	background-color: rgba($bgColor-20, 0.1);
	border-color: $borderColor-24;
	&:hover {
		color: $textColor-17;
		background-color: $bgColor-20;
	}
}

.btn5-green {
	color: $textColor-10;
	border-color: $bgColor-11;
	background-color: $bgColor-11;
	&:hover {
		background-color: $bgColor-13;
		color: $textColor-10;
	}
	&[disabled] {
		opacity: 0.5;
		cursor: default;
		&:hover {
			border-color: $bgColor-11;
			background-color: $bgColor-11;
		}
		.icon {
			color: $textColor-23;
		}
	}
}

.btn5-blue {
	color: $textColor-10;
	background-color: $bgColor-30;
	border-top: 1px solid $borderColor-30;
	border-bottom: 1px solid $borderColor-31;
	&:hover {
		background-color: $bgColor-31;
	}
}

// # Icons

.btn5 {
	.icon {
		float: left;
		padding-right: 4px;
		position: relative;
	}

	.icon-camera {
		font-size: 19px;
		top: -3px;
		padding-right: 8px;
	}

	.icon-add {
		font-size: 0.9em;
		padding-right: 0.7em;
		color: $textColor-30;
	}

	.icon-clear {
		font-size: 0.8em;
		padding-right: 0.7em;
		top: 0.15em;
		color: $textColor-50;
	}

	.icon-m-close {
		font-size: 16px;
		line-height: 14px;
		padding-right: 3px;
		color: $textColor-50;
		vertical-align: middle;
		display: inline-block;
		&:before {
			vertical-align: top;
			line-height: 14px;
		}
	}

	.icon-m-library_books {
		font-size: 16px;
		line-height: 14px;
		padding-right: 3px;
		vertical-align: middle;
		display: inline-block;
		&:before {
			vertical-align: top;
			line-height: 14px;
		}
	}

	.icon-checkmark {
		top: -0.2em;
		padding-right: 0.5em;
		color: $textColor-30;
	}

	.icon-plus {
		padding-right: 0.7em;
		top: -0.1em;
		color: $textColor-30;
	}

	.icon-bullhorn {
		padding-right: 0.5em;
		top: -0.2em;
		font-size: 1.2em;
	}

	.icon-edit {
		padding-right: 0.5em;
		top: -0.2em;
		font-size: 1.2em;
	}

	.icon-message {
		color: $textColor-10;
		padding: 0;
		float: none;
	}
}

.btn5-green {
	.icon-add {
		color: $textColor-10;
	}
}
