.redactor-question-data-popover {
	//border: 1px solid black;
	// position: absolute;
	border: 1px solid $borderColor-25;

	border-radius: 4px;
	background: #FFF;
	padding: 6px 12px 12px;
	box-shadow: 0 0 3px 0 rgba(#000, .2);

	.body-container {
		cursor: pointer;
	}

	.title {
		line-height: 1.3;
		font-size: em(15px);
		margin: 0 0 5px;
		color: darken($textColor, 15%);
	}

	.tags {
		margin: 5px 0;
	}

	.tags-item {
		color: $textColor-40;
		font-size: em(14px);
		display: inline-block;
		&:not(:last-of-type)::after {
			content: '•';
			margin: 0 5px;
		}
	}

	.description {
		line-height: 1.3;
		font-size: em(14px);
		color: darken($textColor, 15%);

		p {
			line-height: inherit;
			font-size: inherit;
			&:not(:last-of-type) {
				margin-bottom: 5px;
			}
		}
	}
}

.redactor-question-popover:before {
  content: ""
}
