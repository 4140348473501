.video-teaser {
	position: relative;

	.title {
		$line-height: 1.3;
		color: $textColor-24;
		transition: color 100ms;
		line-height: $line-height;
		cursor: pointer;
		display: block;
		&:hover {
			color: $textColor-32;
		}
	}

	.thumbnail {
		display: block;
		overflow: hidden;
		text-align: center;
		position: relative;
		height: 180px;
		width: 320px;
		@include no-select;

		img {
			display: block;
			width: inherit;
			height: inherit;
		}

		&::after {
			content: '';
			position: absolute;
			z-index: 30;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: inset 0 0 50px 10px #000;
			opacity: 0.3;
			transition: opacity 150ms;
		}

		.icon {
			$size: 50px;
			position: absolute;
			bottom: 8px;
			left: 5px;
			color: $bgColor-23;
			font-size: $size;
			z-index: 31;
			transition: transform 150ms;
			&::before {
				position: relative;
				z-index: 90;
			}
			&::after {
				$diffSize: 20px;
				content: '';
				display: block;
				width: $size - $diffSize;
				height: $size - $diffSize;
				position: absolute;
				top: $diffSize / 2;
				left: $diffSize / 2;
				background-color: #000;
				z-index: 89;
				border-radius: 50%;
			}
		}

		&:hover {
			&::after {
				opacity: 0.2;
			}
			.icon {
				transform: scale(1.3) translateX(5px) translateY(-5px);
			}
		}
	}

	.description {
		color: $textColor-22;
		line-height: 1.3;
	}

	.tags-list {
		font-size: em(15px);
	}

	.controls {
		display: none;

		.action-container {
			float: left;
			margin-left: 7px;
			&:first-of-type {
				margin-left: 0;
			}
		}

		.action {
			display: inline-block;
			border-radius: 50%;
			text-align: center;
			background-color: $bgColor-20;
			width: 26px;
			height: 26px;
			cursor: pointer;
			border: 1px solid $borderColor-55;
			transition: transform 100ms ease-in-out;
			color: $borderColor-51;
			&.action-edit {
				font-size: 12px;
			}
			&.action-share {
				font-size: 14px;
			}
			&.success {
				color: $textColor-30;
				border-color: $textColor-30;
			}
			&.warning {
				color: $textColor-51;
				border-color: $textColor-51;
			}
			&:hover {
				transform: scale(1.15);
			}
			i[class^='icon-m'] {
				display: inline-block;
				margin-left: 1px;
				margin-top: 2px;
				font-size: 16px;
				width: 20px;
				text-align: center;
				vertical-align: top;
			}
		}
	}
}

.video-teaser-info {
	box-sizing: border-box;
	padding: 15px 15px 0;
	position: relative;
}

// type: compact

.video-teaser.type-compact {
	.video-teaser-info .title {
		$font-size: 16px;
		$line-height: 1.3;
		$lines-to-show: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		max-height: $font-size * $line-height * $lines-to-show; /* Fallback for non-webkit */
		-webkit-line-clamp: $lines-to-show;
		-webkit-box-orient: vertical;
	}

	.video-teaser-details {
		$height: 194px;
		display: none;
		position: absolute;
		z-index: 80;
		top: 0;
		left: 0;
		width: 100%;
		background: $bgColor-55;
		border-radius: 5px;
		border: 1px solid $borderColor-55;
		box-shadow: 0 1px 1px 1px rgba(#000, 0.1);
		padding: $height 14px 14px;
		overflow: hidden;
		box-sizing: border-box;
		.details-container {
			overflow: hidden;
		}
	}

	.title {
		padding-top: 5px;
	}

	.controls {
		position: absolute;
		z-index: 85;
	}
	.controls:not(.controls-owner) {
		padding: 15px 10px 9px 20px;
		top: 145px;
		right: 14px;
	}
	.controls.controls-owner {
		top: -10px;
		right: -10px;
	}

	&:hover {
		.video-teaser-info {
			z-index: 85;
			.title {
				display: none;
			}
		}
		.video-teaser-details {
			display: block;
		}
		.controls {
			display: block;
		}
	}
}

// type: default

.video-teaser.type-default {
	overflow: hidden;
	.video-teaser-info {
		float: left;
		padding: 0 15px 0 0;
		.title {
			display: none;
		}
	}

	.title {
		font-size: em(18px);
		font-weight: bold;
	}

	.details-container {
		padding-top: 3px;
	}

	.tags-list {
		display: none;
	}
}
