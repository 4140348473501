.page-article-question {
	.text-center {
		text-align: center;
	}

	.question-container {
		.question {
			font-size: em(18px);
			font-weight: bold;
			line-height: 1.4;
		}
		.answer {
			padding-top: .7em;
		}

		.widget-input-tags {
			.select2-container {
				width: 300px;
			}
			.t-list {
				float: none;
				max-width: none;
				margin: 0;
			}
			.t-list__item {
				display: inline-block;
				float: none;
				margin: 7px 12px 0 0;
			}
		}
	}

	.questions-list--item {
		.widget-input-tags {
			.select2-container {
				width: 300px;
			}
			.t-list {
				max-width: 700px;
			}
		}

		.controls {
			padding-top: 5px;
			.action {
				margin-right: 8px;
				&:last-of-type {
					margin-right: 0;
				}
			}
			.action-remove {
				color: $textColor-51;
				float: right;
				cursor: pointer;
				margin-top: 10px;
				&:hover {
					color: darken($textColor-51, 15%);
				}
			}
		}
	}
}
