.widget-input-tags {
	.select2-container {
		width: 172px;
		.select2-choice {
			box-sizing: border-box;
			width: 100%;
		}

		&.select2-container-multi .select2-choices {
			.select2-search-field {
				width: 100%;
				input {
					width: 100% !important;
				}
			}
			.select2-search-choice {
				display: none;
			}
		}
	}


	.t-list {
		float: right;
		max-width: 750px;
		margin-right: -9px;
	}

	.t-list__item {
		@include no-select;
		border: 1px solid $borderColor-41;
		border-radius: 40px;
		float: right;
		margin: 0 8px 10px;
		position: relative;
		&:last-of-type {
			margin-left: 0;
		}
		.name {
			font-size: em(15px);
			line-height: 1;
			padding: 8px 18px 7px;
			display: block;
			&.color {
				color: $textColor-40;
			}
		}
		a.name {
			color: $textColor-40;
			&:hover {
				color: darken($textColor-40, 15%);
			}
		}

		.delete {
			@include font-absolutera;
			@extend .icon-clear-circle-fill;
			cursor: pointer;
			color: $textColor-40;
			background-color: #fff;
			display: block;
			border-radius: 50%;
			position: absolute;
			top: -6px;
			right: 0;
			box-sizing: border-box;
			transition: transform 150ms, color 150ms;
			&:hover {
				transform: scale(1.3) rotate(180deg);
				color: $textColor-50 !important;
			}
		}
	}

	.t-list.color-green {
		.t-list__item {
			border-color: $bgColor-11;
		}
		.name.color {
			color: $textColor-30;
		}
		a.name {
			color: $textColor-30;
			&:hover {
				color: darken($textColor-30, 15%);
			}
		}
		.delete {
			color: $textColor-30;
		}
	}
}


.widget-input-tags.tagging {
	.select2-container {
		width: auto;
		display: block;
	}

	.select2-container-multi {
		.select2-choices {
			.select2-search-field {
				width: auto;
			}
			.select2-search-choice {
				display: block;


			}
		}
	}
}
