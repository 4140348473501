.tags-list {}

// Tags Style 1
.tags-list-item {
	$textColor: $textColor-40;
	color: $textColor;
	font-size: em(14px);
	line-height: 1.4;

	a {
		cursor: pointer;
		color: $textColor;
		&:hover {
			text-decoration: underline;
		}
	}

	&:not(:last-of-type)::after {
		content: ', ';
	}
}


// Tags Style 2
.tags-list {
	$margin: 5px;

	.tags-wrapper {
		margin: -$margin;
	}

	.tag {
		position: relative;
		display: inline-block;
		border: 1px solid $borderColor-41;
		color: $textColor-40;
		padding: 7px 10px 6px;
		border-radius: 16px;
		margin: $margin;
		font-size: em(14px);
		white-space: nowrap;

		.delete {
			@include font-absolutera;
			@extend .icon-clear-circle-fill;
			cursor: pointer;
			color: $textColor-40;
			background-color: #fff;
			display: block;
			border-radius: 50%;
			position: absolute;
			top: -8px;
			right: 0;
			box-sizing: border-box;
			transition: transform 150ms, color 150ms, opacity 150ms;
			opacity: 0.8;
			&:hover {
				transform: scale(1.3) rotate(180deg);
				color: $textColor-50 !important;
				opacity: 1;
			}
		}
	}
}
