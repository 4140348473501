.modal.modal-ligthbox {
	.modal-dialog {
		background: $bgColor-20;
		width: 1020px;
		margin-top: 70px;
		margin-bottom: 60px;
	}

	.media-container {
		text-align: center;
		@include no-select;
	}

	.media-info {
		$rightColumnWidth: 250px;
		padding: 20px 25px 25px;
		display: table;
		width: 100%;
		table-layout: fixed;
		box-sizing: border-box;
		.information {
			display: table-cell;
			margin-right: $rightColumnWidth + 30px;
		}
		.controls {
			display: table-cell;
			width: $rightColumnWidth;
			border-left: 1px dotted $borderColor-20;
			padding-left: 25px;
			box-sizing: border-box;
		}
	}

	.media-title {
		font-size: em(22px);
		font-weight: normal;
		padding: 0.4em 0;
	}

	.media-subtitle {
		font-size: em(14px);
		padding-bottom: 0.7em;
		.genre {
			text-transform: capitalize;
			color: $textColor-30;
		}
		.tag {
			color: $textColor-40;
			&:not(:last-of-type)::after {
				content: '•';
				margin: 0 5px;
			}
		}

		.separator {
			color: $textColor-12;
			margin: 0 7px;
		}
	}

	.maker {
		overflow: hidden;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		.maker-image {
			float: left;
			img {
				width: 40px;
				height: 40px;
			}
		}

		.maker-title,
		.maker-subtitle {
			margin-left: 50px;
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.maker-title {
			white-space: nowrap;
			color: $textColor-30;
			margin-top: 3px;
			&:hover {
				text-decoration: underline;
			}
		}

		.maker-subtitle {
			font-size: em(12px);
			margin-top: 4px;
			text-transform: capitalize;
			color: $textColor-12;
		}
	}

	.description {
		line-height: 1.4;
		white-space: pre-line;
	}

	.actions {
		margin-left: -10px;
		.action {
			display: block;
			width: 100%;
			cursor: pointer;
			padding: 6px 10px 5px;
			text-align: left;
			font-size: em(14px);
			color: $textColor-13;
			transition: background 0.3s ease, color 0.3s ease;
			&:hover {
				background: $bgColor-23;
				color: $textColor;
			}
			i[class^='icon-m'] {
				display: inline-block;
				color: $borderColor-51;
				font-size: 20px;
				margin-right: 5px;
				width: 22px;
				text-align: center;
				vertical-align: middle;
				transition: color 0.3s ease;
			}
			.icon {
				display: inline-block;
				color: $borderColor-51;
				font-size: 16px;
				margin-right: 5px;
				width: 22px;
				text-align: center;
				vertical-align: middle;
				transition: color 0.3s ease;
				margin-top: 3px;
				margin-bottom: 3px;
			}
			.s-text {
				display: inline-block;
				vertical-align: middle;
			}
			.icon-m-star.added {
				color: $textColor-30;
			}
			&.success .icon {
				color: $textColor-30;
			}
			&.action-warning,
			&.action-warning .icon {
				color: $textColor-51;
			}
		}
	}
}
