.b-events-column {
	min-height: 300px;

	.events-not-carousel-wrap {
		position: relative;
		margin-right: -30px;
	}

	.events-not-carousel {
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
		event-teaser {
			display: table-cell;
			padding-right: 20px;
		}
	}
}
