@import "profile";
@import "subscribes";
@import "settings";

.user-settings.page-settings-wrapper .page__tabs {
	li.adminize {
		float: right;
		overflow: hidden;
		a {
			color: $textColor-51;
			padding-left: 0;
			padding-right: 0;
			&:hover {
				color: darken($textColor-51, 10%);
			}
		}
	}
}
