.modal.modal-style-1 {
	.modal-dialog {
		width: 500px;
	}

	.modal-content {
		padding: 25px 57px 40px;
		background-color: $bgColor-24;
		border-radius: 0 0 16px 16px;
		overflow: hidden;
		&.big-pb {
			padding-bottom: 70px;
		}
	}

	.modal-top-line {
		background-color: $bgColor-11;
		text-align: center;
		border-radius: 16px 16px 0 0;
		.site-logo {
			height: 100%;
			line-height: 60px;
			font-size: 29px;
			text-shadow: 0 2px 2px rgba(#000, .4);
		}
	}

	.modal-info {
		color: $textColor-10;
		font-size: em(14px);
		text-align: center;
		margin-top: 13px;
		a {
			color: $textColor-31;
			@include transition-color();
			&:hover {
				color: $textColor-33;
			}
		}
	}

	.modal-title {
		color: $textColor-23;
		font-size: em(30px);
		font-weight: bold;
		text-align: center;
	}

	.modal-header {
		padding-bottom: 20px;
	}

	.modal-field {
		padding-bottom: 43px;
		min-height: 65px;
		width: 320px;
		margin: 0 auto;
		box-sizing: border-box;
		position: relative;

		.textfield {
			display: block;
			width: 100%;
			box-sizing: border-box;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.error-msg {
			display: block;
			color: $textColor-50;
			font-size: em(13px);
			margin: 0 12px 5px;
			&:first-of-type {
				margin-top: 7px;
			}
			&:last-of-type {
				margin-bottom: 10px;
			}
		}

		.textfield-require-icon {
			color: $bgColor-53;
			display: inline-block;
			background-color: $bgColor-20;
			border: 1px solid #e0e2e5;
			border-radius: 100%;
			position: relative;
			height: 22px;
			width: 22px;
			z-index: 1;
			&:after {
				content: '*';
				display: block;
				position: absolute;
				padding-top: 2px;
				font-size: 28px;
				font-family: "Arial", sans-serif;
				line-height: 1;
				text-align: center;
				vertical-align: bottom;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			&.right {
				position: absolute;
				right: 10px;
				bottom: 35px;
			}
		}
	}

	.modal-actions {
		margin-top: 40px;
		overflow: hidden;
		text-align: center;
		.action {
			@include transition-bg-color();
			min-width: 150px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.description {
		line-height: 1.4;
		padding-bottom: 5px;
		color: $textColor-22;
		font-size: em(14px);
		margin-bottom: 1em;
		&:last-of-type {
			margin-bottom: 0;
		}
		&.description-center {
			text-align: center;
		}
	}
}

.modal.modal-style-1 .notification-message {
	padding: 0;
	.message {
		font-size: em(14px);
		&.big {
			font-size: em(18px);
			line-height: 1;
		}
	}
}

md-tooltip {
	z-index: 2000;
}
