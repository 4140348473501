@import "page-article-question";

.article-form {
	/**
   * **************** Article Header Section *****************
   **/

	.a-header {
		background-color: $bgColor-59;
		position: relative;
		z-index: 1;
		&.gradient {
			//background-color: #fff;
			//@include filter-gradient(#fff, $bgColor-59, vertical);
			//@include background-image(linear-gradient(top, #fff 0%, $bgColor-59 10%));
			background: #fff linear-gradient(top, #fff 0%, $bgColor-59 10%);
		}
	}

	.a-header .article-image.b-avatar-upload {
		cursor: pointer;
		overflow: visible;
		.image-container {
			overflow: hidden;
			border-radius: 6px;
		}
		.upload-container {
			padding-top: 25px;
		}
	}

	/**
   * **************** Article Other Styles *****************
   **/

	.a-header .header-container {
		.field-label {
			padding-bottom: 6px;
		}

		.textfield {
			display: block;
			width: 100%;
			box-sizing: border-box;
		}

		.info-details {
			padding-top: 18px;
			display: table;
			vertical-align: top;
			table-layout: fixed;
			&.no-fixed {
				table-layout: auto;
			}
			&.series {
				height: 40px;
			}
			width: 100%;
			.row-field {
				display: table-cell;
				padding-right: 30px;
				padding-bottom: 4px;
				vertical-align: top;
				&.selectbox {
					width: 172px;
				}
				&.article-author {
					padding-right: 0;
				}
				&.series {
					width: 550px;
					vertical-align: top;
					padding-right: 20px;
					.series-textfield {
						width: 550px;
					}
				}
				&.order {
					padding-right: 20px;
					.textfield.textfield-style-1 {
						padding: 7px 10px 6px;
					}
				}
				&.series-button {
					padding-right: 20px;
					width: 136px;
					&:last-of-type {
						padding-right: 0;
					}
				}
				&.reply {
					padding-right: 0;
				}
				&.maker {
					width: 200px;
				}
			}
			.has-errors-hint {
				margin-bottom: 0;
			}
		}
		.controls-container {
			.action, md-checkbox {
				margin-right: 20px;
			}
			.btn5.btn5-green.save {
				width: 120px;
			}
		}

		.widget-input-roles-matrix {
			margin-left: -17px;
		}
		.btn5.series {
			font-size: 15px;
			width: 100%;
			.icon-m-reply {
				&:before {
					vertical-align: initial;
				}
			}
		}
		md-checkbox {
			margin-bottom: 0;
		}
	}

	.row-field.article-author {
		.name {
			line-height: 26px;
			cursor: pointer;
			border-bottom: 1px dashed $textColor-30;
			color: $textColor-30;
			white-space: nowrap;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			max-width: 100%;
			&:hover {
				color: darken($textColor-30, 15%);
				border-bottom-color: darken($textColor-30, 15%);
			}
		}
		.name-empty {
			font-style: italic;
		}
	}

	.redactor_content-body {
		font-size: em($articleFontSize);
		padding-top: 30px;
		padding-bottom: 30px;
		text-align: justify;
	}

	.length-counter {
		float: right;
		&.success {
			color: $textColor-32;
		}
		&.warning {
			color: $textColor-51;
		}
	}

	.form-footer.border-top {
		margin-top: 0;
	}
}

.form-options {
	margin-bottom: 30px;
	&:last-of-type {
		margin-bottom: 0;

	}
	.form-options-hint {
		margin-top: 0.5em;
		padding-left: 24px;
		p {
			line-height: 1.3;
			font-size: em(15px);
			margin-bottom: 0.3em;
		}
	}
}

.b-form-info {
	$check-height: 22px;
	display: inline-block;
	vertical-align: top;
	line-height: $check-height;
	margin-left: 5px;
	font-size: $check-height;
	height: $check-height;
	color: $textColor-30;
	&:before {
		vertical-align: top;
	}
}

.tags-info {
	margin-top: 10px;
	.b-form-info {
		margin-right: 5px;
	}
	.s-text {
		vertical-align: middle;
		display: inline-block;
		font-size: 13px;
		line-height: 13px;
	}
}
