@mixin transitionfix() {
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
}

@mixin transition-bg-color($speed: 'normal') {
  transition: background-color getTimeByName($speed) ease-out;
}

@mixin transition-color($speed: 'normal') {
  transition: color getTimeByName($speed) ease-out;
}

@mixin transition-opacity($speed: 'normal') {
  transition: opacity getTimeByName($speed) ease-out;
}

@mixin transition-border-color($speed: 'normal') {
  transition: border-color getTimeByName($speed) ease-out;
}

@mixin circle($diameter: 0px, $color: #fff) {
  width: $diameter;
  height: $diameter;
  background-color: $color;
  border-radius: $diameter/2;
  box-sizing: border-box;
}

@mixin icon-centered($textFontSize: 20px, $textLineHeight: 1, $iconFontSize: 18px) {
	position: absolute;
	display: block;
	top: 0;
	right: 0;
	font-size: em($iconFontSize, $textFontSize);
	line-height: strip-units($textFontSize * $textLineHeight / $iconFontSize);
}


@mixin block-fill-image() {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	img {
		flex-shrink: 0;
		min-width: 100%;
		min-height: 100%
	}
}
