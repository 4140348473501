.article-media-box {
	$rowContainerOffset: 20px;
	@include pie-clearfix;
	position: relative;

	.article-media-box-close {
		display: none;
	}

	.presentation {
		float: left;
		position: relative;
		z-index: 10;
	}

	.info {
		position: relative;
		box-sizing: border-box;
		min-height: 142px;
		padding-bottom: 36px;
		overflow: hidden;
		.row-container {
			margin-left: $rowContainerOffset;
		}
	}

	.title {
		overflow: hidden;
		line-height: 1.1;
		a:hover {
			color: $textColor-32;
		}
	}

	.created {
		font-size: em(12px);
		color: $textColor-13;
		white-space: nowrap;
	}

	.type {
		color: $textColor-40;
		font-size: em(15px);
		white-space: nowrap;
	}

	.description {
		color: $textColor-24;
		line-height: 1.4;
		overflow: hidden;
		text-align: justify;
	}

	.reading_time {
		display: inline-block;
		border: 1px solid $borderColor-12;
		border-radius: 4px;
		font-size: em(14px);
		line-height: 22px;
		padding: 0 10px 1px;
		color: $textColor-32;
		white-space: nowrap;
	}

	.title-container {
		margin: 4px 0;
		.created {
			float: right;
			background-color: $bgColor-20;
			line-height: 1.1px * strip-units(em(20px, $fontSize)) * strip-units($fontSize);
			padding-left: 10px;
			box-shadow: -10px 0 5px -5px $bgColor-20;
		}
	}

	.subtitle-container {
		margin: 4px 0;
		line-height: em(15px);
	}

	.description-container {
		margin: 4px 0;
	}

	.footer-container {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0 !important;
		padding-left: $rowContainerOffset;
		box-sizing: border-box;

		.info__owner {
			margin-right: 300px;
		}

		.reading_time {
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -12px;
		}

		.edit_article {
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -14px;
			.btn5 {
				&.btn5-white {
					display: none;
				}
			}
		}
	}
}

.article-media-box.with-close-button {
	.article-media-box-close {
		position: absolute;
		top: 2px;
		right: 0;
		z-index: 10;
		display: block;
		transition: opacity 100ms ease-in-out;
		opacity: 0;

		button {
			display: block;
			width: 24px;
			height: 24px;
			font-size: 10px;
			background-color: $bgColor-20;
			text-align: center;
			border-radius: 50%;
			cursor: pointer;
			transition: all 100ms ease-in-out;
			&.btn-remove {
				color: $textColor-13;
				border: 1px solid $textColor-16;
				&:hover {
					color: $textColor-51;
					border-color: $textColor-51;
				}
			}
			&.btn-star {
				color: $textColor-32;
				border: 1px solid $textColor-32;
				&:hover {
					color: $textColor-31;
					border-color: $textColor-31;
				}
			}
			i[class^='icon-m'] {
				font-size: 16px;
				display: inline-block;
				vertical-align: middle;
				margin-top: 2px;
				margin-right: -1px;
			}
		}
	}

	.created {
		transition: transform 80ms ease-in-out;
	}

	&:hover {
		.created {
			transform: translateX(-35px);
		}
		.article-media-box-close {
			opacity: 1;
		}
	}
}

// type: minimal

.article-media-box.type-minimal {
	.image__article {
		width: 100px;
		height: 100px;
		border-radius: 5px;
	}
	.title {
		font-size: em(18px);
	}
	.info {
		min-height: 0;
		padding-bottom: 0;
		.row-container {
			margin-left: 15px;
		}
	}
}

// type: compact

.article-media-box.type-compact {
	.title-container {
		clear: both;
		margin: 0 !important;
		padding-bottom: 10px;
	}
}

// type: column
.article-media-box.type-column {
	$lineHeight: 21px;
	border: 1px solid $borderColor-25;
	border-radius: 5px;
	padding: 12px 12px 56px;
	background: #fff;
	height: 400px;
	box-sizing: border-box;

	&.highlighted {
		border-color: $bgColor-11;
		box-shadow: 0 0 4px $bgColor-11;
	}

	.info-container {
		margin: 0;
		padding: 0;
		height: auto;
		position: static;
	}

	.title, .type, .description {
		line-height: $lineHeight;
		padding: 0;
		white-space: normal;
	}

	.title {
		font-size: em(16px);
		margin: 0 0 5px;
	}

	.type {
		font-size: em(13px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 5px 0;
	}

	.description {
		font-size: em(15px);
		white-space: pre-line;
		text-align: left;
	}

	.presentation {
		float: none;
		display: block;
		margin-bottom: 8px;
		height: 160px;
		overflow: hidden;
		border-radius: 3px;
		position: relative;

		.counters-container {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.90) 100%);
			padding: 16px 12px 12px;
			overflow: hidden;

			.counter {
				font-size: em(14px);
				color: $textColor-10;
				line-height: 1;
				position: relative;
				padding-left: 23px;

				i {
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					&.icon-clock {
						font-size: 16px;
						transform: translateY(-9px);
					}
					&.icon-eye {
						font-size: 18px;
						transform: translateY(-11px);
					}
				}
			}

			.position-right {
				float: right;
			}
		}

		avatar {
			background-color: #F3F6F8;
			display: block;
			position: relative;
			height: inherit;
		}
	}

	.image__article {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		min-height: 100%;
		margin: auto;
		height: auto;
		border-radius: 0;
		&[ng-src$='article-large.png'] {
			width: auto;
		}
	}


	.badge {
		position: absolute;
		right: 24px;
		top: 0;
		z-index: 12;
		background-color: $bgColor-11;
		padding: 5px 5px 3px;
		color: $textColor-10;
		text-align: center;
		font-size: 16px;
		line-height: 16px;
		vertical-align: top;
		min-width: 16px;
		cursor: default;
		&:after {
			content: '';
			border: 13px solid transparent;
			border-top: 6px solid $bgColor-11;
			position: absolute;
			bottom: -19px;
			left: 0;
		}
	}

	.footer-container {
		padding: 12px 12px 12px;
		background: #fff;

		.info__owner {
			margin: 0;
			position: relative;
			a {
				display: block;
				width: 100%;
				position: relative;
				overflow: hidden;
			}
		}

		.owner-image-container {
			float: left;
		}

		.owner__details {
			width: 100%;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			display: table;
			max-width: 100%;
			padding: 0;
			border-left: 40px solid transparent;
			box-sizing: border-box;

			.owner-cell {
				display: table-cell;
				position: relative;
				white-space: nowrap;
				vertical-align: middle;
				line-height: 15px;
				overflow: hidden;
				&.ellipsis:before {
					content: '&nbsp;';
					visibility: hidden;
					font-size: em(14px);
				}
				&.text-right {
					text-align: right;
					width: 1px;
				}
			}

			.name, .created {
				display: inline;
				max-width: none;
				width: auto;
				line-height: 15px;
				border: none;
			}

			.name {
				position: absolute;
				left: 0;
				right: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: em(14px);
			}
			.created {
				font-size: em(13px);
			}
		}
	}
}


// type: tile
.article-media-box.type-tile {
	display: block;
	height: 100%;
	border-radius: 3px;
	overflow: hidden;
	background: #fff;
	box-sizing: border-box;
	position: relative;

	/* Mask: */

	.info-container {
		margin: 0;
		padding: 12px 12px;
		box-sizing: border-box;
		height: auto;
		position: absolute;
		z-index: 10;
		width: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 95%);
	}

	.title, .type, .created {
		line-height: 1.3;
		padding: 0;
		white-space: nowrap;
		overflow: hidden;
		margin: 8px 0;
		font-size: em(14px);
	}

	.title {
		font-size: em(18px);
		//line-height: 23
		white-space: normal;
		color: #fff;
	}

	.type {
		//line-height: 17
		text-overflow: ellipsis;
	}

	.created {
		opacity: 0.9;
		//line-height: 17
		color: #fff;
	}

	.presentation {
		background: #F8FAFB;
		float: none;
		display: block;
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
		align-items: center;
		justify-content: center;
		box-align: center;
		box-align: center;

		.image__article {
			border-radius: 0;
			height: auto;
			width: auto;
			flex-shrink: 0;
			min-width: 100%;
			min-height: 100%;
		}

		.image__article.is-default {
			display: none;
		}
	}

	.info__owner {
		margin: 0;
		position: relative;
		a {
			display: block;
			width: 100%;
			position: relative;
			overflow: hidden;
		}


		.owner-image-container {
			float: left;

			img:not(.is-default) {
				border: 1px solid #fff;
			}

			.image__group {
				border-radius: 2px;
			}
		}

		.owner__details {
			width: 100%;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			display: table;
			max-width: 100%;
			padding: 0;
			border-left: 40px solid transparent;
			box-sizing: border-box;

			.owner-cell {
				display: table-cell;
				position: relative;
				white-space: nowrap;
				vertical-align: middle;
				line-height: 15px;
				overflow: hidden;
				&.ellipsis:before {
					content: '&nbsp;';
					visibility: hidden;
					font-size: em(14px);
				}
				&.text-right {
					text-align: right;
					width: 1px;
					padding-left: 10px;
				}
			}

			.name, .counter {
				display: inline;
				max-width: none;
				width: auto;
				line-height: 20px;
				border: none;
				color: #fff;
			}

			.name {
				position: absolute;
				left: 20px;
				right: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: em(15px);
			}
			.counter {
				font-size: em(13px);
				padding-left: 23px;
				position: relative;
				i {
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					font-size: 16px;
					transform: translateY(-9px);
				}

				.counter-label-full {
					display: none;
				}
			}
		}
	}
}

.page-home {
	.article-media-box.type-tile .presentation {
		position: initial;
	}
}
