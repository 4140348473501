.processing-materials {
	position: relative;
}

.processing-materials-loader {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $bgColor-59;
	border: 1px solid $borderColor-12;
	border-radius: 5px;
	z-index: 10;
	box-shadow: 0 0 4px rgba(#000, 0.2);
	.loading_list {
		display: block;
		width: 160px;
	}
}

.processing-materials-list {}

.processing-materials.loading {
	pointer-events: none;
	@include no-select;
	.processing-materials-loader {
		display: block;
	}
	.processing-materials-list {
		opacity: 0.3;
		filter: blur(4px);
	}
}

.processing-materials-load-container {
	padding: 0 30px 20px;
	//padding-bottom: 20px;

	.load-btn {
		display: block;
		width: 100%;
		text-align: center;
		padding: 15px;
		border: 1px solid $borderColor-20;
		background: $bgColor-59;
		border-radius: 5px;
		cursor: pointer;
		font-size: em(14px);
		font-style: italic;
		opacity: 0.7;

		&:hover {
			border-color: darken($borderColor-20, 3%);
			opacity: 1;
		}
		&.active {
			opacity: 1;
			border-color: $borderColor-12;
		}
	}
}
