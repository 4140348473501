.comments-list {
	margin-bottom: 12px;
	&:last-child {
		margin-bottom: 0;
	}
	.button-show-all-comments {
		display: block;
		width: 100%;
		font-size: em(14px);
		padding: 12px 0 10px;
		cursor: pointer;
		font-style: italic;
		opacity: 0.6;
		background-color: transparent;
		transition: opacity 100ms, background-color 100ms;
		&:hover {
			opacity: 1;
			background-color: $bgColor-25;
		}
	}
}

.comment {
	overflow: hidden;
	padding: 10px 0;
	&:first-of-type {
		padding-top: 5px;
	}
	.author-image {
		float: left;
	}
	.image__user {
		border: 2px solid $borderColor-24;
		width: 50px;
		height: 50px;
	}
	&:hover {
		.comment-header .controls {
			visibility: visible;
		}
	}
}

.comment-header {
	$fontSize: 16px;
	overflow: hidden;
	padding: 5px 0;
	margin-left: 65px;
	.controls {
		float: right;
		visibility: hidden;
		border-right: 1px dotted $borderColor-20;
		margin-right: 7px;
		padding-right: 7px;
		button {
			font-size: em(12px);
			overflow: hidden;
			color: $textColor-13;
			float: left;
			cursor: pointer;
			opacity: 0.5;
			.icon {
				position: relative;
				top: 1px;
			}
			&:hover {
				opacity: 1;
			}
			&.remove:hover {
				color: $textColor-51;
			}
		}
	}
	.date {
		float: right;
		font-size: em(12px);
		line-height: $fontSize;
		color: $textColor-13;
	}
	.author-name {
		font-size: em($fontSize);
		font-weight: bold;
		color: $textColor-23;
		&:hover {
			color: $textColor-32;
		}
	}
}

.comment-content {
	margin-left: 65px;
	line-height: 1.3;
	white-space: pre-line;
}


