@include keyframes(loading-list) {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

.loader-wrapper {
	height: 90px;
	display: block;
}

.loading_list {
	height: 90px;
	position: relative;
	i {
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -5px;
		background-color: $bgColor-11;
		animation: loading-list 800ms infinite ease-out;
	}
	.s1 {
		margin-left: -21px;
	}
	.s2 {
		margin-left: -5px;
		animation-delay: 150ms;
	}
	.s3 {
		margin-left: 11px;
		animation-delay: 300ms;
	}
}

.no-results {
	vertical-align: middle;
	display: table-cell;
	font-size: 18px;
	line-height: 1;
	font-style: italic;
	text-align: center;
	color: $textColor-25;
	&-wrap {
		display: table;
		table-layout: fixed;
		height: 90px;
		width: 100%;
	}
}
