.popover-simple {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $borderColor-56;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);

  &.top {
    margin-top: -10px;
  }
  &.right {
    margin-left: 10px;
  }
  &.bottom {
    margin-top: 10px;
  }
  &.left {
    margin-left: -10px;
  }
}

.popover-simple-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-simple-content {
  padding: 9px 14px;
}

.popover-simple {
  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
      border-width: 10px;
    }
    border-width: 11px;
  }
  &.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;
    &:after {
      bottom: 1px;
      margin-left: -10px;
      content:  '';
      border-top-color: #fff;
      border-bottom-width: 0;
    }
    &:before {
	    position: absolute;
	    content: '';
      bottom: 10px;
      margin-left: -10px;
      width: 20px;
	    height: 5px;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;
    &:after {
      bottom: -10px;
      left: 1px;
      content:  '';
      border-right-color: #fff;
      border-left-width: 0;
    }
  }
  &.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    &:after {
      top: 1px;
      margin-left: -10px;
      content:  '';
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }
  &.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);
    &:after {
      right: 1px;
      bottom: -10px;
      content:  '';
      border-right-width: 0;
      border-left-color: #fff;
    }
  }
}
