.event-media-box {
	$imageHeight: 240px;
	$imageWidth: 313px;
	$borderColor: $borderColor-26;
	border: 1px solid $borderColor;
	border-radius: 5px;
	overflow: hidden;
	height: $imageHeight;
	position: relative;

	.image__event {
		width: 100%;
		height: 100%;
		&.is-default {
			display: none;
		}
	}

	.presentation {
		float: left;
		border-right: 1px solid $borderColor;
		width: $imageWidth;
		height: $imageHeight;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}

	.presentation:not(.no-image)::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, 0.2);
	}

	.presentation.no-image {
		.calendar {
			.date, .time {
				color: $textColor-23;
				text-shadow: none;
			}
		}
	}

	.calendar {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		transform: translateY(-50%);
		text-align: center;

		.date, .time {
			font-weight: bold;
			text-shadow: 0 2px 2px rgba(#000, 0.7);
			color: $textColor-10;
		}
		.date {
			font-size: em(28px);
			line-height: em(36px, 28px);
		}
		.time {
			font-size: em(36px);
			@include font-helvetica-light();
		}
		.timer {
			display: inline-block;
			color: $textColor-23;
			background: $bgColor-20;
			opacity: 0.9;
			padding: 10px 16px 9px;
			white-space: nowrap;
			border-radius: 18px;
			margin-top: 20px;
			min-width: 122px;
			box-sizing: border-box;
		}
	}

	.info-container {
		margin-left: $imageWidth + 20px;
		position: relative;
		padding: 20px 20px 20px 0;
		height: 100%;
		box-sizing: border-box;
		overflow: hidden;
	}

	.info-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 20px 20px 0;
		box-sizing: border-box;
	}

	.title {
		font-size: em(20px);
		font-weight: bold;
		padding-bottom: 12px;
		color: $textColor-23;
	}

	.type {
		display: block;
		font-size: em(13px);
		color: $textColor-40;
		padding-bottom: 8px;
	}

	.description {
		line-height: 1.3;
		padding-top: 13px;
		word-wrap: break-word;
	}

	.members-count {
		color: $textColor-13;
		font-size: em(14px);
		white-space: nowrap;
	}
}


// type: column
.event-media-box.type-column {
	$lineHeight: 21px;
	border: 1px solid $borderColor-20;
	border-radius: 5px;
	padding: 12px 12px 15px;
	background: #fff;
	height: 300px;
	box-sizing: border-box;

	.presentation {
		float: none;
		display: block;
		border: none;
		width: auto;
		margin: 0 0 14px;
		height: 100px;
		overflow: hidden;
		border-radius: 3px;
		position: relative;

		&.no-image {
			background: #F8FAFB;
			border: 1px solid lighten($borderColor-20, 7%);
		}
	}

	.info-container {
		margin: 0;
		padding: 0;
		height: auto;
		position: static;
	}

	.title, .type, .description {
		line-height: $lineHeight;
		padding: 0;
		white-space: normal;
	}

	.title {
		font-size: em(16px);
		margin: 0 0 5px;
	}

	.type {
		font-size: em(13px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 5px 0;
	}

	.description {
		font-size: em(15px);
	}

	.calendar {
		.date, .time {
			font-weight: normal;
			line-height: 1;
		}
		.date {
			font-weight: bold;
			font-size: em(19px);
		}
		.time {
			font-size: em(22px);
			padding-top: 10px;
		}
	}

	.image__event {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		min-height: 100%;
		margin: auto;
		height: auto;
		border-radius: 0;
	}
}
