.page-group-view {
	.group_header {
		.image-group {
			.image__group {
				border-radius: 8px;
				border: 1px solid $borderColor-24;
			}
			.badge-closed {
				position: absolute;
				right: -8px;
				bottom: -8px;
			}
		}
		.entity-update {
			position: absolute;
			right: 0;
			top: 20px;
			z-index: 91;
			margin-right: -110px;
			transition: margin-right 200ms ease-in;
			&:hover {
				margin-right: 0;
			}
		}
	}

	.b-profile-users {
		.requests {
			color: $textColor-30;
			font-weight: bold;
			&:hover {
				color: darken($textColor-30, 10%);
				text-decoration: underline;
			}
		}
	}
}

// Closed Group Block
.page-group-view .group-is-closed {
	.image-wrapper {
		@include circle(220px, $bgColor-58);
		margin: 30px auto;
		text-align: left;
		position: relative;
		img {
			width: 126px;
			height: 128px;
			display: block;
			position: relative;
			left: 62px;
			top: 35px;
		}
	}

	.text-warning {
		font-style: italic;
		color: $textColor-13;
		padding-bottom: 30px;
		line-height: 1.7em;
		text-align: center;
	}
}



// Groups Materials Block

.page-group-view .group-materials {
	.videos-list-item {
		width: 346px;
	}
}



