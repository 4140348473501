.participants-list {}

.participants-list-item {
	$textColor: $textColor-30;
	font-size: em(14px);
	line-height: 1.4;

	.participant-label::after {
		content: ': ';
	}

	.participant-name {
		color: $textColor;
	}

	.participant-name a {
		cursor: pointer;
		color: $textColor;
		&:hover {
			text-decoration: underline;
		}
	}

	.participant-name:not(:last-of-type)::after {
		content: ', ';
	}
}
