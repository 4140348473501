$fontName: 'PT Sans', sans-serif;
$fontSize: 16px;
$wrapperWidth: 1080px;
$wrapperPaddingSize: 20px;
$bgColor: #F7F9FA;
$textColor: #5C5C5C;
$linkColor: #519fff;
$headerHeight: 60px;
$headerBorderSize: 2px;
$audioPlayerHeight: 50px;
$userBarWidth: 90px;

$articleFontSize: 18px;
$articleFontColor: #666;

// Screens
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);


/* Modal Options */

$modalZIndex: 1040;
$backdropOpacity: 0.5;
$backdropAnimationSpeed: 200ms;
$modalAnimationSpeed: 400ms;
