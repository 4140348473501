.redactor-audios-list {
	display: block;
	border: 1px solid $borderColor-25;
	border-top: none;
	padding: 10px 20px;
	background: $bgColor-59;
	overflow: hidden;
}

.redactor-audios-list-item {
	position: relative;

	.redactor-audio-remove {
		$size: 20px;
		display: none;
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -$size/2 - 1;
		background-color: $bgColor-20;
		border: 1px solid $borderColor-12;
		border-radius: 50%;
		color: $textColor-31;
		font-size: $size/2;
		padding: $size/4;
		cursor: pointer;
		transition: transform 100ms ease;

		.icon {
			display: block;
		}

		&:hover {
			transform: scale(1.2);
		}
	}

	&:hover {
		.redactor-audio-remove {
			display: block;
		}
		.duration {
			display: none;
		}
	}
}
