.audio-player-container {
	height: $audioPlayerHeight;
	box-sizing: border-box;
	background-color: $bgColor-11;
	background-clip: padding-box;
	padding-top: 6px;
	padding-bottom: 6px;
	z-index: 100;
	position: fixed;
	bottom: 0;
	left: 50%;
	width: $wrapperWidth;
	margin-left: -$wrapperWidth / 2;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;

	.audio-player-close {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		padding: 6px 5px;
		color: $textColor-10;
		font-size: 10px;
		&:hover {
			transform: scale(1.2);
			text-shadow: 0 1px 1px rgba(#000, 0.2);
		}
	}
}

.audio-player {
	display: table;
	width: 100%;
	height: 100%;

	.column {
		display: table-cell;
		vertical-align: middle;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		position: relative;
		&:first-of-type {
			border-left: none;
		}
		&:last-of-type {
			border-right: none;
		}
	}
	.column__position,
	.column__duration {
		width: 55px;
		padding-top: 24px;
		font-size: em(13px);
		color: $textColor-10;
	}
	.column__position {
		text-align: right;
	}
	.column__duration {
		text-align: left;
	}
	.column__actions {
		width: 36px;
	}
	.column__volume {
		width: 160px;
		padding-top: 22px;
		position: relative;
		.btn-volume {
			background: transparent;
			color: $textColor-10;
			font-size: 18px;
			position: absolute;
			bottom: -3px;
			left: 0;
			width: 20px;
			cursor: pointer;
			text-align: left;
		}
		md-slider {
			margin-left: 30px;
			margin-right: 20px;
		}
	}

	.action {
		display: inline-block;
		border-radius: 50%;
		width: 36px;
		height: 36px;
		box-sizing: border-box;
		font-size: 36px;
		position: relative;
		cursor: pointer;
		color: $textColor-10;
		transition: transform 0.1s ease;
		&:hover {
			transform: scale(1.1);
		}
	}
	.download {
		$size: 20px;
		display: inline-block;
		border-radius: 50%;
		width: $size;
		height: $size;
		box-sizing: border-box;
		cursor: pointer;
		color: $textColor-10;
		position: absolute;
		right: 0;
		bottom: 10px;
		margin: -$size/2;
		transition: transform 0.1s ease;
		z-index: 1;
		&:hover {
			transform: scale(1.1);
		}
		.icon-m-get_app {
			vertical-align: top;
			font-size: 22px;
			line-height: 22px;
			&:before {
				vertical-align: initial;
			}
		}
	}

	.info {
		text-align: center;
		color: $textColor-10;
		height: 20px;
		font-size: em(14px);
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.info__title::before {
		content: " – ";
	}

	md-slider {
		height: 8px;
		._md-track-container {
			position: relative;
			top: 0;
			height: 8px;
			cursor: pointer;
		}
		._md-track {
			border-radius: 4px;
			&._md-track-fill {
				background: $bgColor-20;
			}
		}
		.md-thumb-container {
			display: none;
		}
	}
}
