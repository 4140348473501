.page__tabs {
  display: block;
  background: $bgColor-59;
  border-bottom: 1px solid $borderColor-20;
  padding-top: 10px;
  @include pie-clearfix;
	@include no-select;

  li {
    float: left;
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    bottom: -1px;
		height: 40px;

		a {
			display: block;
			padding: 13px 20px 0;
			color: $textColor-13;
			height: 100%;
			position: relative;
			.badge {
				display: block;
				color: $textColor-30;
				font-size: em(13px, 18px);
				@include font-helvetica-light();
				text-align: center;
				position: absolute;
				top: 2px;
				right: 3px;
			}

			&:hover {
				color: $textColor-30;
			}
		}

    &.active {
      background: $bgColor-20;
      border-color: $borderColor-20 $borderColor-20 $borderColor-24;
      a {
				cursor: default;
        color: $textColor-30;
				.badge {
					opacity: 1;
				}
      }
    }
		&.right {
			float: right;
		}
  }
}
