.widget-select-owner {
	.select2-container {
		display: block;
	}

	.select2-results .select2-highlighted .select-owner-list-item {
		.name, .hint {
			color: $textColor-10;
		}
	}

	.select2-container-multi {
		.select2-choices {
			padding: 0;
		}
		.select2-search-field {
			float: none;
			display: block;
		}
		.select2-search-field input {
			width: 100% !important;
		}
	}
}



.widget-select-owner.search-disabled {
	.select2-container-multi {
		.select2-search-field, .select2-drop {
			display: none;
		}

		.select2-choices {
			cursor: default;
			padding: 0;
		}

		.select2-search-choice {
			display: block;
			overflow: hidden;
			width: 100%;
			box-sizing: border-box;
			padding: 0;
			border: none;
			margin: 0;
		}
	}
}


.select-owner-list-item {
	$padding: 5px;
	$height: 35px;
	padding: $padding;
	line-height: 1;
	overflow: hidden;
	white-space: nowrap;
	position: relative;
	font-size: $fontSize;

	&::before {
		content: '';
		display: inline;
		vertical-align: middle;
		line-height: $height;
	}

	.image-container {
		position: absolute;
		top: $padding;
		left: $padding;
		img {
			width: $height;
			height: $height;
			border: 1px solid #fff;
			&.image__group {
				border-radius: 2px;
			}
		}
	}

	.info-container {
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		width: 100%;
		padding-left: 45px;
	}

	.name {
		font-size: em(14px);
		color: $textColor-20;
		max-width: 370px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.hint {
		padding-top: 5px;
		color: $textColor-12;
		font-size: em(13px);
	}
}
