.event-voting-question-form {
	.input-message {
		min-height: 100px;
		max-height: 200px;
	}
	.section-title {
		display: block;
		margin-bottom: 10px;
	}
}
