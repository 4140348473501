/**
 * **************** Event Avatar Section *****************
 **/

.b-avatar-upload.event-image {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.20);
	overflow: visible;
	cursor: pointer;
	.image-container {
		border-radius: 6px;
	}

	.upload-container {
		padding-top: 43px;
	}
}
