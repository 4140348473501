#all-participants {
	.modal-dialog-container {
		overflow: hidden;
	}

	.modal-header {
		padding-bottom: 20px;
	}

	.modal-content {
		padding: 0;
	}

	.page__tabs {
		background: transparent;
		margin-bottom: 20px;
	}
}
