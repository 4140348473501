@import "term.navigation";
@import "term.content";
@import "term.info";
@import "term.faq";
@import "term.modal";
@import "term.images";
@import "term.forms";
@import "term.filter";
@import "term.elements";
@import "term.videos";
@import "term.manage";

.page-wiki-view {
	$navWidth: 230px;
	position: relative;
	padding-left: $navWidth;
	.wiki-navigation {
		width: $navWidth;
	}
	.wiki-content {
		padding-bottom: 70px;
		position: relative;
		z-index: 2;
		@media screen and (max-height: 885px) {
			padding-bottom: 165px;
		}
		.b-search-query-pane {
			margin-top: 0;
			padding-top: 20px;
			margin-bottom: 15px;
		}
		.b-search {
			max-height: 106px;
			overflow: hidden;
		}
	}
}

.wiki-ui-view {
	position: relative;
	display: block;
}

.page-wiki {
	min-height: inherit;
	display: block;
}
