// notifications
.user-settings-notifications {
	.checkbox {
		padding-bottom: 20px;
	}

	.row-notifications {
		padding: 20px 0 15px 30px;
	}

	.row-frequency {
		.field-label {
			display: inline-block;
			margin-right: 15px;
			vertical-align: baseline;
		}
	}
}

// Change Email
.user-settings-email {
	.input-email {
		width: 250px;
	}
}

// Remove Account
.user-settings-remove-account {
	.image__user {
		$size: 100px;
		display: block;
		width: $size;
		height: $size;
		margin: 0 auto;
		border: 1px solid $borderColor-20;
		background-color: $bgColor-20;
	}

	.username {
		padding-top: 13px;
		font-size: em(24px);
		text-align: center;
	}

	.actions {
		padding-top: 20px;
		text-align: center;
	}
}
