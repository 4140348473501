.search_section {
	.b-search {
		border: none;
	}

	.audios-list {
		padding-top: 0;
		padding-bottom: 20px;
	}

	.images-list {
		padding-bottom: 20px;
	}

	.terms-filters {
		display: block;
		height: 41px; // for IE
		overflow: hidden;
		padding-top: 20px;
	}
}
