.redactor-box .redactor-questions-panel {
	$element-width: 32px;
	$element-height: 38px;
	$element-radius: $element-height / 2;
	$panel-offset: $element-width - $element-radius;
	position: absolute;
	top: 0;
	right: 0;
	width: 0;

	.panel-container {
		position: relative;
		left: -$panel-offset;
		&:first-of-type {
			margin-top: 5px;
		}
	}

	.redactor-question-popover {
		@include font-material;
		// @extend .icon-m-live_help;

		position: relative;
		display: block;
		box-sizing: border-box;
		padding-top: 9px;
		font-size: 23px;
		width: $element-width;
		height: $element-height;
		color: lighten($textColor-20, 20);
		cursor: pointer;
		border-radius: 0 $element-radius $element-radius 0;
		background: #fff;

		&::before {
			position: relative;
			z-index: 1;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: $element-radius;
			border-radius: 0 $element-radius $element-radius 0;
			box-shadow: 2px 1px 3px 0 rgba(#000, .2);
		}

		transition: all 100ms;
		&::after {
			transition: width 100ms;
		}

		&:hover {
			$size: 8px;
			width: $element-width + $size;
			padding-left: $size;
			color: $textColor-30;
			&::after {
				width: $element-radius + $size;
			}
		}
	}
}
