.group-media-box {
	$marginLeft: 170px;
	@include pie-clearfix;
	position: relative;

	.image-container {
		float: left;
	}

	.info {
		margin-left: $marginLeft;
		padding: 5px 0 25px;
		box-sizing: border-box;
		min-height: 142px;
		position: relative;
	}

	.title a:hover {
		color: $textColor-32;
	}

	.type {
		color: $textColor-13;
		font-size: em(13px);
		font-style: italic;
		float: right;
		margin-top: -3px;
		padding-left: 10px;
		.icon {
			margin-left: 4px;
			font-size: 16px;
			position: relative;
			top: 1px;
			opacity: 0.8;
		}
	}

	.categories-list {
		padding-top: 4px;
		font-size: em(14px);
		color: $textColor-40;
		span:not(:last-of-type)::after {
			content: '•';
			margin: 0 5px;
		}
	}

	.description {
		color: $textColor-22;
		line-height: 1.3;
		overflow: hidden;
		text-align: justify;
		padding-top: 4px;
		white-space: pre-line;
	}

	.members-count {
		color: $textColor-13;
		font-size: em(14px);
		white-space: nowrap;
		position: absolute;
		bottom: 5px;
		left: 0;
	}
}
