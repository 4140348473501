.select2-container {
  .select2-choice {
    font-size: em(13px);
    border-color: $borderColor-28;
    border-radius: 3px;
    background: $bgColor-20;
    color: $textColor-23;
    line-height: 26px;
    height: 26px;
    padding: 0 0 0 10px;
    min-width: 160px;

		& > .select2-chosen {
			line-height: 25px;
			padding-top: 1px;
		}

    .select2-arrow {
      border-left: 1px solid $borderColor-28;
      border-radius: 0;
      background: transparent;
    }
  }

	&:not(.select2-container-disabled) .select2-choice {
		@include transition-bg-color('fast');
		&:hover {
			background: $bgColor-21;
		}
	}

	&.select2-container-disabled .select2-choice {
		background-color: $bgColor-27;
		border-color: $borderColor-28;
		.select2-arrow {
			background-color: transparent;
		}
	}
}

.select2-dropdown-open .select2-choice .select2-arrow {
	border-left: none;
	filter: none;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices.select2-arrow,
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid $borderColor-12;
  box-shadow: none;
}

.select2-drop-active {
  border-color: $borderColor-12;
}

.select2-drop {
  background: $bgColor-20;
  color: $textColor-23;
  border-color: $borderColor-12;
  border-radius: 0 0 3px 3px;
  margin-top: 0;

  &.select2-drop-above {
    margin-top: 0;
    border-color: $borderColor-12;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;

    &.select2-drop-active {
      border-color: $borderColor-12;
    }
  }
}

.select2-search {
	padding: 0;
	font-size: em(14px);
	input {
		padding-left: 10px;
		border-color: $borderColor-12;
		border-left: none;
		border-right: none;
		border-top: none;
		box-sizing: border-box;
	}
}

.select2-results {
  padding: 0;
  margin: 0;

  .select2-result-label {
    padding: 0 10px;
    font-size: em(13px);
    line-height: 26px;
  }

  .select2-highlighted {
    background: $bgColor-11;
    color: $textColor-10;
  }
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border-color: $borderColor-12;
  background: $bgColor-21;
}

.select2-container.select2-allowclear {
	.select2-choice .select2-chosen {
		margin-right: 36px;
	}

	.select2-choice abbr {
		@include font-absolutera;
		@extend .icon-clear;
		display: block;
		width: 8px;
		height: 8px;
		background: none;
		font-size: 8px;
		color: $textColor-11;
		top: 9px;
		transition: color 150ms, transform 150ms;
		&:hover {
			color: $textColor-51;
			transform: scale(1.5) rotate(360deg);
		}
	}
}


// Multi Select2
.select2-container-multi {
	.select2-choices {
		background: $bgColor-20;
		border: 1px solid $borderColor-21;
		border-radius: 3px;
		box-sizing: border-box;

		.select2-search-field {
			input {
				margin: 0;
				padding: 5px 10px 3px;
				line-height: 18px;
				box-sizing: border-box;
				font-family: $fontName;
				font-size: em(13px);
				color: $textColor-21;
			}
		}

		.select2-search-choice {
			display: block;
			font-size: 14px;
			padding: 4px 18px 3px 6px;
			margin: 3px 0 3px 5px;
			line-height: 14px;
			color: $textColor-23;
			border-color: $borderColor-28;
			box-shadow: none;
			background: none;
			&.select2-search-choice-focus {
				border-color: $borderColor-12;
			}
		}
	}

	.select2-search-choice-close {
		@include font-absolutera;
		@extend .icon-clear;
		top: 7px;
		right: 4px;
		bottom: auto;
		left: auto;
		width: 8px;
		height: 8px;
		background: none !important;
		font-size: 8px;
		color: $textColor-11;
		transition: color 150ms, transform 150ms;
		&:hover {
			color: $textColor-51;
			transform: scale(1.5) rotate(360deg);
		}
	}
}
