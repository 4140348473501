.modal.modal-style-2 {
	.modal-dialog {
		width: $wrapperWidth;
		margin-top: 60px;
	}

	.modal-dialog-container {
		border-radius: 16px;
		background-color: $bgColor-20;
		@include pie-clearfix;
	}

	.modal-dialog-close {
		display: block;
		position: absolute;
		top: -11px;
		right: -11px;
		font-size: 14px;
		opacity: .9;
		z-index: 15;
		background: #222;
		border-radius: 50%;
		padding: 9px;
		border: 1px solid $textColor-10;
		color: $textColor-10 !important;
		&:hover {
			opacity: 1;
		}
		.icon {
			display: block;
		}
	}

	.modal-header,
	.modal-content,
	.modal-footer {
		padding-left: 30px;
		padding-right: 30px;
	}

	.modal-header {
		padding-top: 40px;
		padding-bottom: 40px;

		.header-wrapper {
			position: relative;
		}

		.controls {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

	.modal-title {
		color: $textColor-23;
		font-size: em(30px);
		font-weight: bold;
		text-align: center;
	}

	.modal-content {
		padding-bottom: 30px;
	}

	.modal-footer {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.c-wrapper {
		padding-left: 60px;
		padding-right: 60px;
	}
}
