// type
.group-settings-type {
	table.change-type-table {
		width: 100%;
		td {
			width: 50%;
			vertical-align: top;
			&.separator {
				width: auto;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
			}
		}
	}
	button.change-type-button {
		cursor: pointer;
		display: block;
		text-align: left;
		padding: 15px 10px;
		border: 1px solid $borderColor-20;
		position: relative;

		.icon {
			font-size: 30px;
			position: absolute;
			top: 50%;
			margin-top: -15px;
			color: $textColor-16;
		}

		.title, .description {
			display: block;
			margin-left: 55px;
		}

		.title {
			padding-bottom: 5px;
			font-size: em(17px);
		}

		.description {
			font-size: em(14px);
			line-height: 1.3;
			height: 55px;
		}

		&:hover {
			border-color: $borderColor-12;
		}
		&.active {
			border-color: $borderColor-12;
			.title {
				color: $textColor-32;
			}
			.icon {
				color: $textColor-30;
			}
		}
	}
}

// Group Managers
.group-settings-managers {
	.managers-controls {
		text-align: right;
		padding-top: 20px;
	}
}

// Remove Account
.group-settings-remove {
	.image__group {
		$size: 100px;
		display: block;
		width: $size;
		height: $size;
		margin: 0 auto;
		border: 1px solid $borderColor-20;
		background-color: $bgColor-20;
	}

	.name {
		padding-top: 13px;
		font-size: em(24px);
		text-align: center;
	}

	.actions {
		padding-top: 20px;
		text-align: center;
	}
}
