.page-group {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 290px - 30px;//header height - content overflow;

  .c-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

	.header-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 280px;
		z-index: 2;
		background-color: $borderColor-29;
		background-image: linear-gradient(-179deg, #B9B9B9 0%, #868686 100%);
		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.page-group-borders {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-left: 5px solid $borderColor-50;
	border-right: 5px solid $borderColor-50;
	z-index: 1;
}

.group_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  overflow: hidden;
	padding: 0 30px;
	box-sizing: border-box;
	z-index: 10;

	.image-group {
		position: absolute;
		width: 160px;
		height: 160px;
		z-index: 91;
		top: 50%;
		margin-top: -100px;
		.image__group {
			width: 100%;
			height: 100%;
		}
	}

	.badge-closed {
		$size: 36px;
		@include circle($size, $bgColor-20);
		display: block;
		text-align: center;
		box-shadow: 0 0 1px $borderColor-28;
		.icon {
			color: $textColor-29;
			font-size: 16px;
			line-height: $size;
		}
	}

  .info {
		z-index: 90;
		height: auto;
		width: 100%;
		position: absolute;
		top: 44%;
		transform: translateY(-50%);
		padding-left: 200px;
		box-sizing: border-box;
  }

  .info__name,
  .info__location {
    color: $textColor-10;
    display: block;
  }

  .info__name {
    font-size: 28px;
    padding-top: 15px;
  }

  .info__location {
    padding-top: 1px;
    .icon {
      font-size: em(18px);
      margin-right: 3px;
      position: relative;
      top: 2px;
    }
  }

  .actions {
		margin: 20px -10px;

    .action {
			padding-left: 30px;
			padding-right: 30px;
			margin: 0 10px;
      &.action-ms-hover {
				padding-left: 20px;
				padding-right: 20px;
        min-width: 220px;
      }
    }
  }
}


.group_content {
  position: relative;

  .container-shadow-1,
  .container-shadow-2 {
    content: '';
    display: block;
    position: absolute;
    height: 30px;
    left: 0;
  }

  .container-shadow-1,
  .container-shadow-2,
  .container {
    border-radius: 20px 20px 0 0;
  }

  .container-shadow-1 {
    top: -10px;
    left: 25px;
    right: 25px;
    background: $bgColor-27;
    z-index: 20;
  }

  .container-shadow-2 {
    top: -20px;
    left: 45px;
    right: 45px;
    z-index: 10;
    background: $bgColor-20;
    opacity: .5;
  }

  .container {
    @include pie-clearfix;
    background: $bgColor-20;
    padding-top: 10px;
    position: relative;
    z-index: 20;
  }
}
