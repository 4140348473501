.tags-container {
	@include pie-clearfix;
	display: inline-block;
	vertical-align: middle;

	.tc-tag,
	.tc-sep {
		float: left;
	}

	.tc-sep {
		padding-right: 0.3em;
		&::before {
			content: ',';
		}
	}
}
