.radio {
	position: relative;

	label {
		cursor: pointer;
		padding-left: 23px;
	}

	label::before {
		content: '';
		width: 6px;
		height: 6px;
		background: $borderColor-51;
		border-radius: 50%;
		position: absolute;
		z-index: 50;
		left: 5px;
		top: 5px;
		opacity: 0;
	}

	label::after {
		content: '';
		border: 1px solid $borderColor-28;
		background-color: $bgColor-20;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		margin: 0;
		position: absolute;
		left: 0;
		top: 0;
	}

	input[type=radio] {
		display: none;
		&:checked + label::before {
			opacity: 1;
			background: $borderColor-12;
		}

		&:not(:disabled):not(:checked) + label:hover::before {
			opacity: 0.3;
		}

		&:disabled + label {
			opacity: 0.6;
		}
	}
}
