.social-buttons-auth {
	overflow: hidden;
	button {
		white-space: nowrap;
		cursor: pointer;
		border-radius: 1px;
		box-sizing: border-box;
		padding: 8px 15px 9px 0;
		border: 1px solid $borderColor-26;
		line-height: 1;
		text-align: right;
		color: $textColor-23;
		background: $bgColor-20;
		display: block;
		width: 100%;
		overflow: hidden;
		position: relative;
		&:hover {
			color: darken($textColor-23, 5%);
			background: darken($bgColor-20, 3%);
		}
	}

	button .logo-container {
		height: 100%;
		width: 34px;
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		border-right: 1px solid $borderColor-26;
		i {
			display: block;
			text-align: center;
			position: relative;
			top: 50%;
			margin-top: -11px;
			font-size: 20px;
			line-height: 20px;
		}
	}

	button:hover {
		.logo-container {
			color: #fff;
			border-right-color: transparent;
		}
		&.button-facebook .logo-container { background: #3b5998; }
		&.button-vk .logo-container { background: #45668e; }
		&.button-google .logo-container { background: #dc4e41; }
		&.button-yandex .logo-container { background: #ffcc00; }
	}


	button .label {
		font-size: em(16px);
	}

	.button-container {
		margin: 5px auto;
	}

	.container-column {
		width: 50%;
		float: left;
		box-sizing: border-box;
		&:first-of-type {
			padding-right: 8px;
		}
		&:last-of-type {
			padding-left: 8px;
		}
	}
}
