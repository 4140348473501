.b-avatar-upload {
	$animationSpeed: 100ms;
	position: relative;
	background: $bgColor-25;
	overflow: visible;

	&.error {
		border-color: $borderColor-42 !important;
		.i-field-error {
			top: -10px;
			right: 15px;
			z-index: 95;
		}
	}

	&.change_avatar {
		height: 350px;
		border: 1px solid $bgColor-27;
		margin-bottom: 10px;
		.upload-container {
			padding-top: 120px;
		}
		.upload-container {
			.icon {
				font-size: 55px;
			}
			.s-text {
				color: #ccc;
				display: block;
				margin-top: 5px;
				font-style: italic;
			}
		}
	}

	.upload-container {
		position: relative;
		z-index: 90;
		overflow: hidden;
		display: block;
		text-align: center;
		height: 100%;
		box-sizing: border-box;
		color: $textColor-23;
		transition: color $animationSpeed;
		opacity: 1;

		input {
			display: block !important;
			width: 100% !important;
			height: 100% !important;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 90;
			opacity: 0 !important;
			overflow: hidden !important;
			cursor: pointer;
		}
		.icon {
			display: block;
			padding-bottom: 10px;
			font-size: 35px;
			opacity: 0.7;
		}
	}

	.image-container {
		position: absolute;
		z-index: 85;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
		overflow: hidden;
		transition: opacity $animationSpeed;
	}

	&.has-image .upload-container {
		opacity: 0;
		transition: none;
	}

	&:hover {
		.upload-container {
			color: $textColor-20;
			opacity: 1;
		}
		.image-container {
			opacity: 0.2;
		}
	}
}
