.site-logo {
	@include font-absolutera;
	@extend .icon-logo;
	color: $textColor-10;
	text-shadow: 0 1px 1px rgba(#000, .3);
	&:hover {
		color: $textColor-10;
	}
}

.entity-update {
	background: $bgColor-23;
	color: $textColor-23;
	border-bottom-left-radius: 24px;
	border-top-left-radius: 24px;
	white-space: nowrap;
	padding: 10px 15px;
	overflow: hidden;

	.icon, .label {
		vertical-align: middle;
		display: inline-block;
	}

	.icon {
		font-size: 20px;
		padding-right: 8px;
	}

	.label {
		opacity: 0;
		transition: opacity 200ms;
		padding-top: 2px;
	}

	&:focus {
		background: $bgColor-23 !important;
	}

	&:hover {
		color: $textColor-23;
		.label {
			opacity: 1;
		}
	}
}
