.navigation {
	display: block;
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	height: $headerHeight;
	box-sizing: border-box;
	background-color: $bgColor-11;
	border-bottom: $headerBorderSize solid $borderColor-10;
	transform: scale(1);

	.base-wrapper {
		height: 100%;
		position: relative;
	}

	@include breakpoint(lg) {
		position: fixed;
	}

	.mobile & {
		position: absolute;
	}
}

.navigation .mobile-notification-switcher {
	background: $bgColor-12;
	padding: 5px 0 7px;
	display: none;

	.mobile & {
		display: block;
	}

	.notification-wrapper {
		@extend .c-wrapper;
	}

	button {
		color: $textColor-10;
		&:hover {
			text-decoration: underline;
		}
	}

	.btn-switch {
	}

	.btn-close {
		float: right;
	}
}

.navigation {
	/* Logo styles */

	.site-logo {
		float: left;
		font-size: 29px;
		height: 100%;
		box-sizing: border-box;
		padding: 0 30px;
		line-height: $headerHeight;
		background-color: transparent;
		text-shadow: 0 2px 2px rgba(#000, .4);
		@include transition-bg-color("normal");
		&:hover {
			background-color: $bgColor-13;
		}
	}

	/* Navigation common styles */

	.h-menu {
		height: 100%;
	}
	.h-menu__container {
		display: inline-block;
		vertical-align: top;
		height: 100%;
		transition: background-color .2s ease-out;
	}
	.h-menu__button {
		display: block;
		height: 100%;
		box-sizing: border-box;
		padding: 21px 19px 0;
		color: $textColor-10;
		cursor: pointer;
		text-shadow: 0 1px 1px rgba(#000, .4);
		font-size: em(18px);
		border-bottom: 1px solid transparent;
		position: relative;

		.badge {
			color: $textColor-10;
			font-size: em(12px, 18px);
			display: block;
			@include font-helvetica-light();
			text-shadow: none;
			text-align: center;
			opacity: 0.7;
			position: absolute;
			top: 10px;
			right: 5px;
		}
	}
	.h-menu__container.active,
	.h-menu__container:hover {
		background-color: $bgColor-13;
		.h-menu__button {
			border-bottom-color: $borderColor-10;
			.badge {
				opacity: 1;
			}
		}
	}

	/* Navigation main menu styles */

	.h-menu-nav {
		float: right;
	}

	/* Navigation actions menu styles */

	.h-menu-actions {
		height: 100%;
		float: right;
		white-space: nowrap;
		.h-menu__button {
			width: 60px;
			height: 100%;
			text-align: center;
			padding: 0;
			position: relative;
			@include reset-btn;
			i {
				position: absolute;
				top: 50%;
				left: 50%;
			}
		}
		.icon-search {
			font-size: 19px;
			margin-top: -11px;
			margin-left: -10px;
		}
		.icon-exit,
		.icon-enter {
			font-size: 24px;
			margin-top: -12px;
		}
		.icon-exit {
			margin-left: -10px;
		}
		.icon-enter {
			margin-left: -14px;
		}
	}

	/* Search bar styles */

	.nav-search-bar {
		background-color: $bgColor-11;
		height: 100%;
		position: absolute;
		top: 0;
		right: 140px;
		z-index: 10;
		padding-top: 11px;
		box-sizing: border-box;
		width: 600px;
		.nav-search-input {
			$font-size: 18px;
			border: none;
			background: #fff;
			font-size: em($font-size);
			line-height: normal;
			height: $font-size + 10px + 9px;
			color: #2c3e50;
			width: 100%;
			box-sizing: border-box;
			padding: 10px 35px 9px 10px;
			/* Internet Explorer 10 */
			&:-ms-input-placeholder {
				color: #999;
			}
			&:focus {
				&:-ms-input-placeholder {
					color: #999;
				}
			}
		}
		.nav-blur_focus {
			position: absolute;
			right: -60px;
			top: 0;
			width: 60px;
			height: 60px;
			cursor: pointer;
		}
		button {
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -7px;
			transition: all .2s;
			cursor: pointer;
			&:hover {
				color: #000;
			}
		}

		&.ng-enter, &.ng-leave {
			transition: .5s all;
		}
		&.ng-enter {
			width: 0;
		}
		&.ng-enter.ng-enter-active {
			overflow: hidden;
			width: 600px;
		}
		&.ng-leave.ng-leave-active {
			width: 0;
			overflow: hidden;
		}
	}
}
