.content_selector {
	.images-list, .audios-list, .articles-list-item {
		margin: -4px;
		padding: 0;
	}
	.audios-list{
		&-item {
			display: block;
			float: none;
			margin: 0 0 12px;
			width: auto;
			height: auto;
			font-size: 14px;
			line-height: 18px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.articles-list-item {
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
		&:not(:last-of-type)::after {
			display: none;
		}
	}
	.images-list-item {
		width: 33.333%;
		padding: 4px;
	}
	&.upload-avatar {
		.images-list-item {
			width: 20%;
		}
	}

	.content_selector-item {
		display: table;
		margin: 0;
		position: relative;
		padding: 10px;
		border: 1px solid transparent;
		border-radius: 4px;
		transition: border-color .3s ease;
		width: 100%;
		user-select: none;
		table-layout: fixed;
		box-sizing: border-box;
		&:hover {
			border-color: $borderColor-13;
			.content_selector-item-icon_radio {
				&:after {
					opacity: 0.5;
				}
			}
		}
		&.active {
			border-color: $borderColor-13;
			.content_selector-item-icon_radio {
				&:after {
					border-color: $borderColor-13;
					opacity: 1;
				}
			}
		}
		&-info, &-details, &-icon {
			display: table-cell;
			vertical-align: middle;
		}
		&-icon {
			padding: 0 5px 0 0;
			width: 20px;
			&_radio {
				display: inline-block;
				width: 16px;
				height: 16px;
				cursor: pointer;
				position: relative;
				vertical-align: top;
				&:after {
					content: '';
					width: 11px;
					height: 7px;
					border: 4px solid $bgColor-26;
					border-top: none;
					border-right: none;
					background: transparent;
					transform: rotate(-45deg);
					transition: 0.15s ease opacity, 0.15s ease border-color;
					position: absolute;
					opacity: 0.25;
				}
			}
		}
	}
}
