.page-group-form {

	.page-group-borders {
		border-left-color: $borderColor-29;
		border-right-color: $borderColor-29;
	}

	.group_header {
		overflow: visible;
		padding-top: 40px;
		z-index: 90;
		@include pie-clearfix;

		.image-group {
			border-radius: 8px;
			border: 1px solid $borderColor-24;
			cursor: pointer;
			.image-container.canvas {
				border-radius: 8px;
				overflow: hidden;
			}
			.upload-container {
				padding-top: 30px;
			}
		}

		button.badge-closed {
			position: absolute;
			left: 165px;
			top: 175px;
			cursor: pointer;
			z-index: 95;
			&:hover {
				background-color: darken($bgColor-20, 5%);
			}
		}

		.selectbox {
			.label {
				display: block;
				color: $textColor-10;
				font-size: em(14px);
				padding: 5px 0;
			}
			.select2-container .select2-choice {
				min-width: 150px;
			}
		}

		.info {
			position: relative;
			top: auto;
			transform: none;
			table {
				width: 100%;
				tr {
					height: 60px;
					td:first-of-type {
						width: 100%;
					}
				}
			}

			.textfield.name {
				box-sizing: border-box;
				box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.20);
				width: 100%;
			}

			td.location .selectbox {
				float: left;
				&:first-of-type {
					margin-right: 30px;
				}
			}

			td.change-background {
				text-align: right;
				padding-left: 40px;
				button {
					@include no-select;
					font-size: 15px;
					.icon {
						float: none;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}

	.group_content {
		.group-details {
			display: table;
			width: 100%;
			box-sizing: border-box;

			.contacts,
			.info {
				display: table-cell;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
			}
			.contacts {
				border-left: none;
				min-width: 220px;
				padding-right: 10px;
			}
			.info {
				border-right: none;
				width: 100%;
			}

			.field-label_suffix {
				padding-bottom: 20px;
			}
			.field-label_small {
				padding-bottom: 4px;
				font-size: em(13px);
				color: $textColor-22;
			}

			.contacts__item {
				padding-top: 20px;
				position: relative;
			}
			.textfield {
				width: 100%;
				&.not-valid {
					border-color: $borderColor-42;
				}
			}
			.error {
				@include font-absolutera;
				@extend .icon-warning;
				background-color: #fff;
				border-radius: 50%;
				display: block;
				color: $textColor-51;
				position: absolute;
				right: 15px;
				bottom: 20px;
				box-sizing: border-box;
				cursor: pointer;
			}
			.remove {
				@include font-absolutera;
				@extend .icon-clear-circle-fill;
				cursor: pointer;
				color: $bgColor-53;
				background-color: #fff;
				display: block;
				border-radius: 50%;
				position: absolute;
				bottom: 20px;
				right: -7px;
				box-sizing: border-box;
				transition: transform 150ms, color 150ms;
				&:hover {
					transform: scale(1.3) rotate(180deg);
					color: $textColor-50;
				}
			}
		}

		.description {
			min-height: 148px;
		}
	}

	.length-counter {
		float: right;
		&.success {
			color: $textColor-32;
		}
		&.warning {
			color: $textColor-51;
		}
	}
}
