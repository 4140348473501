.item__info {
	&.search {
		padding: 30px;
		.b-definition-wrapper {
			padding: 30px 0;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
		.b-definition {
			p {
				&:nth-child(2) {
					margin-top: 10px;
				}
			}
		}
	}

	.b-definition {
		line-height: 1.3;
		color: $textColor-22;
		text-align: justify;
		position: relative;

		&_tools {
			position: absolute;
			left: 30px;
			bottom: 9px;
			font-size: em(12px);
			color: $textColor-11;
			.action, .separator {
				display: inline-block;
				vertical-align: baseline;
			}
			.action {
				color: inherit;
				&:hover {
					color: darken($textColor-11, 25%);
					text-decoration: underline;
				}
			}
			.separator {
				width: 1px;
				height: 10px;
				margin: 0 8px;
				background: $textColor-11;
			}
		}

		.name {
			font-weight: bold;
			color: $textColor-22;
			~ a:hover {
				color: darken($textColor-22, 20%);
			}
		}

		.b-definition_text {
			display: inline;
			& > *:first-child {
				display: inline;
				margin-right: 5px;
			}
		}
		p {
			line-height: inherit;
			&:last-of-type {
				margin-bottom: 0 !important;
			}
			& > * {
				font-size: inherit !important;
				line-height: inherit !important;
			}
		}
	}


	.b-definition-wrapper {
		padding: 30px 40px 30px 30px;
		border-bottom: 1px dotted $borderColor-23;
		position: relative;
		&:last-child {
			border-bottom: none;
		}
	}

	.b-definition_context {
		position: relative;
	}

	.b-definition_context-less {
		display: block;
	}
	.b-definition_context-more {
		display: none;
	}

	.b-definition_context-show {
		display: none;
		.l-show_more {
			color: $textColor-30;
			transition: background 0.3s ease;
			border-bottom: 1px dashed $textColor-30;
			line-height: 16px;
			position: relative;
			z-index: 1;
			&:hover {
				color: $textColor-31;
			}
		}
	}

	.b-definition_context-wrapper {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.b-definition_context-wrapper.content-show-more {
		.b-definition_context-show {
			display: block;
		}

		&.content-show-more-active {
			.b-definition_context-less {
				display: none;
			}
			.b-definition_context-more {
				display: block;
			}
		}
	}



	.info__details {
		display: table;
		box-sizing: border-box;
		padding: 0 30px;
		.row {
			display: table-row;
			overflow: hidden;
			vertical-align: top;
			padding: 0;
		}

		.label,
		.value-wrapper {
			display: table-cell;
			padding: 4px 0;
			line-height: 1.4;
			a {
				white-space: nowrap;
				color: $textColor-32;
				&:hover {
					color: darken($textColor-32, 10%);
					text-decoration: underline;
				}
			}
			span {
				&:after {
					content: ', ';
				}
				&:last-child {
					&:after {
						content: '';
					}
				}
			}
		}

		.value-wrapper {
			width: 100%;
		}

		.label {
			color: $textColor-13;
			padding-right: 20px;
			white-space: nowrap;
		}
	}
	.b-description {
		position: relative;
		text-align: justify;
		&-wrapper {
			border-top: 1px dotted $borderColor-23;
			padding: 30px;
			h3 {
				margin-bottom: 10px;
			}
		}
		p {
			line-height: 21px;
			margin-bottom: 0;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
