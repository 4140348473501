.modal.modal-style-6 {
	$modal-width: 600px;
	$horizontal-padding: 30px;
	$vertical-padding: 20px;
	$border-radius: 6px;
	$border-size: 3px;

	&.content_selector {
		&.reset-search {
			.b-search {
				border: none;
			}
			.b-search-query-pane {
				padding: 0;
				margin: 10px 0;
				border: none;
			}
		}
		.content_selector-not {
			text-align: center;
			padding: 30px 0;
			p {
				color: $bgColor-28;
				font-style: italic;
			}
		}
		.loader-wrapper {
			height: 0;
			.loading_list {
				height: 30px;
			}
		}
		.modal-dialog {
			width: 800px;
		}
		.modal-body {
			height: 550px;
			overflow-y: auto;
			overflow-x: hidden;
			padding-bottom: 10px;
			&.pb0 {
				padding: 0;
			}
			&-content {
				&.mr0 {
					margin: 0 auto;
				}
				margin: 0 auto 30px;
				width: 740px;
			}
		}
		.modal-nav {
			padding: $vertical-padding $horizontal-padding 0;
			border-bottom: 1px solid $borderColor-20;
			.menu_tabs.menu_tabs_buttons {
				padding: 0;
				border: none;
				&:last-child {
					margin-bottom: $vertical-padding;
				}
			}
		}
	}

	.modal-dialog {
		width: $modal-width;
		background-color: $bgColor-55;
		border-radius: $border-radius;
		overflow: visible;
		@include pie-clearfix;
		box-shadow: 0 0 20px 0 rgba(#000, 0.6);
		border: $border-size solid $bgColor-11;
		&.bg-white {
			background-color: $bgColor-20;
		}
		&.width-800 {
			width: 800px;
		}
	}

	.modal-header {
		background: $bgColor-11;
		padding: $vertical-padding $horizontal-padding;
		border-bottom: 1px solid $borderColor-10;
		overflow: hidden;
		position: relative;

		.modal-title {
			color: $textColor-10;
			font-weight: bold;
			font-size: em(18px);
		}

		.modal-dialog-close {
			$height: 14px;
			position: absolute;
			top: 50%;
			right: $horizontal-padding;
			margin-top: -($height/2);
			color: $textColor-10;
			font-size: em($height);
			opacity: 0.85;
			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}
	}

	.modal-body {
		padding: $vertical-padding $horizontal-padding;
		@include pie-clearfix;
		&.border-top {
			border-top: 1px solid $borderColor-20;
		}
		&.small-padding {
			padding-top: 10px;
			padding-bottom: 10px;
		}
		&.no-padding {
			padding: 0;
		}
	}

	.modal-footer {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		overflow: hidden;
		padding: $vertical-padding $horizontal-padding;
		border-top: 1px solid $borderColor-20;
	}
}
