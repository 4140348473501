.show_code {
	position: fixed;
	padding: 10px;
	background-color: rgba(255,255,255,0.6);
	right: 10px;
	top: 60px;
	z-index: 999999;
	font-size: 14px;
	line-height: 1.2;
	max-width: 340px;
	max-height: 600px;
	overflow-x: hidden;
	overflow-y: auto;
}
