.widget-input-roles-matrix {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;

	th {
		padding: 4px 0;
		font-size: em(14px);
	}
	td {
		height: 37px;
		&.counter::after {
			content: '.';
		}
		&.performer {
			position: relative;
			&::before {
				content: '';
				display: block;
				background-color: $bgColor-52;
				height: 1px;
				width: 13px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -1px;
			}
		}
	}

	.counter {
		width: 17px;
		font-size: em(14px);
	}

	.roles {
		width: 200px;
		.select2-container {
			width: 100%;
		}
	}
	.performer {
		width: 200px;
		padding-left: 13px;
		.select2-container-multi {
			display: block;
			.select2-choices {
				.select2-search-field {
					width: 100%;
					input {
						width: 100% !important;
					}
				}
				.select2-search-choice {
					display: none;
				}
			}
		}
	}

	.value {
		text-align: right;
	}
	.value__performer {
		font-size: 15px;
	}
	.value__box {
		border: 1px solid $borderColor-52;
		position: relative;
		display: inline-block;
		margin-right: 2px;
		padding: 6px 13px 5px;
		border-radius: 15px;

		.delete {
			@include font-absolutera;
			@extend .icon-clear-circle-fill;
			cursor: pointer;
			color: $bgColor-53;
			background-color: #fff;
			display: block;
			border-radius: 50%;
			position: absolute;
			top: -6px;
			right: 0;
			box-sizing: border-box;
			transition: transform 150ms, color 150ms;
			&:hover {
				transform: scale(1.3) rotate(180deg);
				color: $textColor-50;
			}
		}
	}
}


.widget-input-roles-matrix.tagging {
	.roles {
		width: 100px;
	}
	.performer {
		width: auto;
		.select2-container-multi {
			.select2-choices {
				.select2-search-field {
					width: auto;
				}
				.select2-search-choice {
					display: block;
				}
			}
		}
	}
}
