.news-form {
	@extend .c-wrapper;
	border-bottom: 1px solid $borderColor-20;
	padding-top: 20px;
	padding-bottom: 20px;
	.message-container {
		margin-right: 130px;
	}

	textarea {
		height: 36px;
		font-size: em(14px);
		border: 1px solid $borderColor-20;
		box-shadow: none;
		line-height: normal;
		padding-top: 10px;
		padding-bottom: 9px;
	}

	button {
		float: right;
	}
}
