.page-settings-profile {
	padding-bottom: 100px;
}

.page-settings-profile .profile-header {
	overflow: visible;
}

/**
 * **************** User Header Info Section *****************
 **/
.page-settings-profile .profile-header .info {
	& > table {
		width: 100%;
		& > tbody > tr > td {
			border-bottom: 17px solid transparent;
			border-right: 40px solid transparent;
		}
		& > tbody > tr > td:last-of-type {
			border-right: none;
		}
		& > tbody > tr:last-of-type td {
			border-bottom: none;
			vertical-align: top;
		}
		& > tbody > tr:last-of-type td.bottom {
			vertical-align: bottom;
		}
		& > tbody > tr > td.width-100 {
			width: 100%;
		}
	}
}

/**
 * **************** Header Common Fields Styles *****************
 **/
.page-settings-profile .profile-header {
	.textfield {
		box-sizing: border-box;
		width: 100%;
	}

	.label {
		font-size: em(14px);
		color: $textColor-10;
	}

	.selectbox {
		.label {
			display: block;
			padding-bottom: 5px;
		}
		.select2-container .select2-choice {
			width: 140px;
			min-width: 140px;
		}
	}

	.birthday-box {
		.label {
			display: block;
			padding-bottom: 5px;
		}

		.date-container {
			display: block;
			white-space: nowrap;
			width: 100%;
			text-align: right;
			cursor: pointer;
			border: 1px solid $borderColor-28;
			border-radius: 3px;
			line-height: 25px;
			padding: 1px 10px 0;
			background: $bgColor-20;
			color: $textColor-23;
			font-size: em(14px);
			transition: background-color 150ms;

			&.empty {
				color: $textColor-11;
			}

			&:hover {
				background: $bgColor-21;
			}

			span {
				float: left;
			}

			.icon {
				line-height: 25px;
				margin-top: -1px;
				color: $textColor-11;
			}
		}
	}
}

/**
 * **************** Fields Styles *****************
 **/
.page-settings-profile .profile-image {
	cursor: pointer;
	overflow: visible;
	.upload-container {
		padding-top: 50px;
	}
	.i-field-error {
		top: 5px;
		right: 25px;
	}

	.image-container.canvas {
		border-radius: 50%;
		overflow: hidden;
	}
}

.page-settings-profile {
	.nickname-wrapper,
	.full_name-wrapper {
		display: inline-block;
		box-sizing: border-box;
	}

	.nickname-wrapper {
		padding-right: 11px;
		width: 35%;
	}

	.full_name-wrapper {
		padding-left: 11px;
		width: 65%;
	}
}

/**
 * **************** p-details *****************
 **/
.page-settings-profile .p-details {
	display: table;
	width: 100%;
	box-sizing: border-box;
	padding-bottom: 40px;
	.row-field {
		display: table-cell;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		padding-bottom: 0;
		&:first-of-type {
			border-left: none;
		}
		&:last-of-type {
			border-right: none;
		}
	}
	.row-contacts {
		min-width: 220px;
		padding-right: 10px;
	}
	.row-description {
		width: 100%;
	}

	.select2-container {
		width: 100%;
	}

	.field-label_suffix {
		padding-bottom: 24px;
	}
	.field-label_small {
		padding-bottom: 4px;
		font-size: em(13px);
		color: $textColor-22;
	}

	.contacts__item {
		padding-top: 20px;
		position: relative;
	}
	.textfield {
		width: 100%;
		&.not-valid {
			border-color: $borderColor-42;
		}
	}

	.error {
		@include font-absolutera;
		@extend .icon-warning;
		background-color: #fff;
		border-radius: 50%;
		display: block;
		color: $textColor-51;
		position: absolute;
		right: 15px;
		bottom: 20px;
		box-sizing: border-box;
		cursor: pointer;
	}
	.remove {
		@include font-absolutera;
		@extend .icon-clear-circle-fill;
		cursor: pointer;
		color: $bgColor-53;
		background-color: #fff;
		display: block;
		border-radius: 50%;
		position: absolute;
		bottom: 20px;
		right: -7px;
		box-sizing: border-box;
		transition: transform 150ms, color 150ms;
		&:hover {
			transform: scale(1.3) rotate(180deg);
			color: $textColor-50;
		}
	}

	.description {
		min-height: 175px;
	}
}
