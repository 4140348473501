.widget-input-directions .table-fields {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;

	th {
		padding: 4px 0;
		font-size: em(14px);
	}
	td {
		height: 37px;
		&.role {
			position: relative;
			&::before {
				content: '';
				display: block;
				background-color: $bgColor-52;
				height: 1px;
				width: 13px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -1px;
			}
		}
	}

	.direction {
		width: 200px;
		.select2-container {
			width: 100%;
		}
	}
	.role {
		width: 200px;
		padding-left: 13px;
		.select2-container {
			width: 100%;
		}
	}

	.actions {
		padding-left: 15px;
	}
}


.widget-input-directions .b-profile-tags {
	padding: 20px 0 0;
}
