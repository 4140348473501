.page-error {
  position: relative;
  padding: 60px 0 100px;
	text-align: center;
}


.page-error {
	.error__code {
		$size: 180px;
		$borderSize: 10px;

		@include circle($size, $bgColor-54);
		display: inline-block;
		border: $borderSize solid $borderColor-53;
		font-size: em(70px);
		font-weight: bold;
		line-height: $size - $borderSize * 2 - 5px;
		color: $textColor-10;
		text-align: center;
	}

	.error__message {
		h1 {
			padding: 25px 0 20px;
		}
		p {
			font-size: em(17px);
			line-height: 1.5;
			white-space: pre-line;
		}
	}

	.buttons-container {
		margin-top: 40px;

		.button {
			margin: 0 10px;
		}
	}
}




