@font-face {
	font-family: 'PT Sans';
	font-weight: normal;
	font-style: normal;
	src: url('/fonts/PTSansWeb/PTS55F_W.eot');
	src:
		local('PT Sans'),
		url('/fonts/PTSansWeb/PTS55F_W.eot?#iefix') format('embedded-opentype'),
		url('/fonts/PTSansWeb/PTS55F_W.woff') format('woff'),
		url('/fonts/PTSansWeb/PTS55F_W.ttf') format('truetype'),
		url('/fonts/PTSansWeb/PTS55F_W.svg#PTSans-Regular') format('svg');
}

@font-face {
	font-family: 'PT Sans';
	font-weight: normal;
	font-style: italic;
	src: url('/fonts/PTSansWeb/PTS56F_W.eot');
	src:
		local('PT Sans Italic'),
		url('/fonts/PTSansWeb/PTS56F_W.eot?#iefix') format('embedded-opentype'),
		url('/fonts/PTSansWeb/PTS56F_W.woff') format('woff'),
		url('/fonts/PTSansWeb/PTS56F_W.ttf') format('truetype'),
		url('/fonts/PTSansWeb/PTS56F_W.svg#PTSans-Italic') format('svg');
}

@font-face {
	font-family: 'PT Sans';
	src: url('/fonts/PTSansWeb/PTS75F_W.eot');
	font-style: normal;
	font-weight: bold;
	src:
		local('PT Sans Bold'),
		url('/fonts/PTSansWeb/PTS75F_W.eot?#iefix') format('embedded-opentype'),
		url('/fonts/PTSansWeb/PTS75F_W.woff') format('woff'),
		url('/fonts/PTSansWeb/PTS75F_W.ttf') format('truetype'),
		url('/fonts/PTSansWeb/PTS75F_W.svg#PTSans-Bold') format('svg');
}

@font-face {
	font-family: 'PT Sans';
	font-style: italic;
	font-weight: bold;
	src: url('/fonts/PTSansWeb/PTS76F_W.eot');
	src:
		local('PT Sans Bold Italic'),
		url('/fonts/PTSansWeb/PTS76F_W.eot?#iefix') format('embedded-opentype'),
		url('/fonts/PTSansWeb/PTS76F_W.woff') format('woff'),
		url('/fonts/PTSansWeb/PTS76F_W.ttf') format('truetype'),
		url('/fonts/PTSansWeb/PTS76F_W.svg#PTSans-BoldItalic') format('svg');
}

//////////////
@mixin font-helvetica-light {
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	font-weight: 300;
}
