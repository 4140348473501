/* Image Group */

.image__group {
  display: block;
  width: 142px;
  height: 142px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
}

/* Image User */

.image__user {
  display: block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50%;
}

/* Image Event */

.image__event {
  display: block;
  width: 330px;
  height: 270px;
  overflow: hidden;
  box-sizing: border-box;
}

/* Image Article */

.image__article {
  display: block;
  width: 142px;
  height: 142px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
}
