.articles-list-item {
	padding: 0 30px;
	margin: 20px 0;
	position: relative;
	&:not(:last-of-type)::after {
		content: '';
		display: block;
		padding: 19px 10px 0;
		height: 1px;
		background-color: $borderColor-20;
		background-clip: content-box;
	}
}

.articles-list-item.compact {
	padding-left: 20px;
	padding-right: 20px;
	&::after {
		padding: 9px 0 0;
	}
	.article-media-box {
		padding: 0 0 10px;
	}
}

// Processing Materials List
.processing-materials-articles {
	$padding: 15px;
	.processing-materials-list {
		min-height: 174px;
	}

	.articles-list-item {
		padding: 0 15px;
		&.reading .article-media-box {
			border: 1px solid $borderColor-20;
			&:after {
				border-color: transparent transparent $borderColor-20 transparent;
			}
		}
	}
	.articles-list-item::after {
		display: none;
	}

	.article-media-box {
		border: 1px solid $borderColor-12;
		border-radius: 3px;
		padding: $padding;
		overflow: hidden;
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 20px 20px;
			border-color: transparent transparent $borderColor-12 transparent;
		}
		.info {
			overflow: hidden;
		}
	}

	.controls {
		$margin: 8px;
		margin: 0 (-$margin);
		position: absolute;
		top: -1px;
		right: 0;
		display: block;
		padding-top: 1px;
		button {
			float: left;
			margin: 0 $margin;
			&.btn5-green {
				&.add {
					background-color: #fff;
				}
			}
		}
	}

	.referrer {
		color: inherit;
		float: left;
		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			width: 30px;
			height: 30px;
			&.image__group {
				border-radius: 3px;
			}
		}
		.name {
			font-weight: bold;
		}
		.hint {
			font-style: italic;
		}
		&:hover .name {
			text-decoration: underline;
		}
	}

	.referrer-info {
		display: inline-block;
		vertical-align: middle;
		font-size: em(13px);
		.hint {
			padding-top: 0.2em;
			display: block;
		}
	}

	.processing-materials-load-container {
		padding-left: 15px;
		padding-right: 15px;
	}
}
