a {
  color: $linkColor;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
		color: darken($linkColor, 20%);
  }
}

h1, h2, h3, h4, h5, h6, p {
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6 {
	color: $textColor-23;
	font-weight: bold;
  a {
		transition: color 50ms ease-in-out;
    color: inherit;
		&:hover {
			color: darken($textColor-23, 20%);
		}
  }
}
h1 {
	font-size: em(30px);
	line-height: 1.3;
}
h2 {
	font-size: em(20px);
}

strong, b {
  font-weight: bold;
}

em, i {
	font-style: italic;
}

strike {
	text-decoration: line-through;
}

p {}
