.articles-for-adding-to-group {

	.image__article {
		width: 80px;
		height: 80px;
		border-radius: 4px;
	}

	.articles-list-item {
		position: relative;
		&:not(:last-of-type) {
			border-bottom: 1px solid #E3E3E3;
		}

		&::after {
			content: '';
			display: block;
			padding: 5px 0;
			height: auto;
			background: none;
		}
	}

	.article-media-box  {
		position: static;
		.info {
			min-height: 0;
			position: static;
			padding-bottom: 10px;
			.row-container {
				margin-left: 10px;
			}
		}

		.reading_time {
			display: none;
		}

		.title {
			font-size: em(18px);
		}

		.created {
			line-height: 1.1px * strip-units(em(18px, $fontSize)) * strip-units($fontSize);
		}

		.description {
			font-size: em(14px);
		}


		.footer-container {
			margin: 0 !important;
			width: auto !important;
			padding-left: 0;
			position: absolute;
			bottom: 10px;
			left: 30px;
			display: inline-block;

			.info__owner {
				.image__user, .image__group {
					width: 28px;
					height: 28px;
				}

				.owner__details .name {
					font-size: em(14px);
				}
			}
		}
	}

	.bottom-controls-wrapper {
		margin-left: 95px;
		overflow: hidden;
		text-align: right;

		.add-hint {
			display: inline-block;
			line-height: 28px;
			font-size: em(14px);
			font-style: italic;
		}
	}
}
