.modal.modal-ligthbox.modal-ligthbox-video {
	.media-container {
		font-size: 0;
		background-color: $bgColor-50;
		border-bottom: 1px solid $borderColor-20;
	}

	.participants-list {
		margin-bottom: 0.7em;
		padding: 0.2em 0;
		font-style: italic;
	}
}
