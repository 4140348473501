.video-form {
	.row-teaser {
		@include pie-clearfix;
		padding-bottom: 20px;
		.row-teaser-thumbnail {
			float: left;
			width: 50%;
			img {
				width: 100%;
				height: auto;
				border: 1px solid $borderColor-25;
				border-radius: 4px;
			}
		}

		.row-teaser-fields {
			margin-left: 50%;
			padding-left: 30px;
			padding-top: 10px;
		}

		.selectbox .select2-container {
			display: block;
		}
	}

	.row-tags {
		margin-top: 15px;
		.widget-input-tags .select2-container {
			width: auto;
			display: block;
		}
	}

	.tags-list {
		margin-bottom: 20px;
	}
}
