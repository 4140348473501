.audio-form {
	.tags-list {
		margin-bottom: 30px;
	}

	.row-two-columns {
		.row-column {
			width: 50%;
			float: left;
		}
	}

	.row-genre .selectbox {
		float: right;
	}
}
