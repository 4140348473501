#loading-bar {
	.bar, .peg {
		height: 5px;
	}

	.bar {
		background: $bgColor-11;
		top: auto;
		bottom: 0;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
	}

	.peg {
		box-shadow: $bgColor-11 1px 0 6px 1px;
	}
}

#loading-bar-spinner {
	top: auto;
	bottom: 20px;
	left: 50%;
	margin-left: -9px;

	.spinner-icon {
		width: 18px;
		height: 18px;
		border-top-color:  $bgColor-11;
		border-left-color: $bgColor-11;
	}
}
//.loader-space
