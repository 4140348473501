/* Search block actions styles */

.b-search__action {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	padding: 8px 20px;
	box-sizing: border-box;
	border-radius: 5px;
	border: 1px solid transparent;
	transition: background-color getTimeByName('fast');
	cursor: pointer;
	&[disabled] {
		cursor: default;
		opacity: 0.6;
	}
	&.action-white {
		color: $textColor-23;
		border-color: $borderColor-21;
		background-color: $bgColor-20;
		transition: all .3s ease;
		&:hover {
			background-color: rgba($bgColor-57, 0.5);
			&[disabled] {
				background-color: $bgColor-20;
			}
		}
		&.active {
			background-color: $bgColor-57;
		}
	}
	&.action-green {
		color: $textColor-10;
		border-color: $bgColor-13;
		background-color: $bgColor-11;
		&:hover {
			background-color: $bgColor-13;
			&[disabled] {
				background-color: $bgColor-11;
			}
		}
	}
	&.action-small {
		height: 26px;
		padding: 6px 11px;
		font-size: em(14px);
	}
	&.action-padding {
		padding: 6px;
	}
}
