.event-dates {
	.b-details {
		display: block;
		float: none;
		margin: 0;
		overflow: hidden;
		padding-bottom: 5px;
	}

	.b-details__header {
		float: left;
		width: 100px;
		padding: 5px 0 0;
		box-sizing: border-box;
		white-space: nowrap;
	}

	.b-details__item {
		font-size: em(17px);
		white-space: nowrap;
	}

	.date-container {
		display: block;
		margin-left: 100px;
	}

	.b-details.timer {
		padding-bottom: 20px;
		.b-details__item {
			font-size: em(26px);
			text-align: center;
		}
		.b-details__header {
			float: none;
			width: auto;
			display: block;
			text-align: center;
			padding: 0;
		}

		.date-container {
			margin: 0;
			padding-top: 5px;
		}
	}
}
