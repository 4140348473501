.im__content__chats {
	position: absolute;
	overflow: hidden;
	border-right: 1px solid $borderColor-23;
	left: 0;
	bottom: 0;
	top: $imHeaderHeight;
	width: $imChatsWidth;
	background-color: $bgColor-20;
	box-sizing: border-box;
	&-fixed {
		position: fixed;
		width: 1078px;
		left: 50%;
		top: 0;
		bottom: 0;
		margin-left: -539px;
	}
	.b-search {
		padding: 13px 20px;
		background-color: #fff;
		&-table {
			margin: 0;
			padding: 0;
		}
	}
}

.chat {
	&-wrap {
		box-sizing: border-box;
		overflow-y: auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		.loader-wrapper {
			height: 70px;
			.loading_list {
				height: 70px;
			}
		}
	}
	&__item {
		position: relative;
		padding: 13px 15px 12px;
		display: table;
		table-layout: fixed;
		box-sizing: border-box;
		width: 100%;
		&-wrapper {
			height: 70px;
			position: relative;
			overflow: hidden;
			border-bottom: 1px solid $borderColor-20;
			color: $textColor-15;
			border-left: 3px solid transparent;
			transition: border 0.2s ease;
			&.new {
				background-color: $bgColor-29;
				.chat__counter {
					opacity: 1;
				}
			}
			&.active {
				background-color: $bgColor-11;
				cursor: default;
				color: $textColor-10;
				.chat__content-name {
					color: $textColor-10;
				}
				&:hover {
					background-color: $bgColor-11;
				}
				.link {
					cursor: default;
				}
			}
			&:hover {
				border-left-color: $bgColor-11;
			}
			.link {
				background-color: rgba(255, 255, 255, 0);
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}
		}
	}
	&__image, &__content {
		display: table-cell;
		vertical-align: middle;
	}
	&__image {
		width: 55px;
		&-recepient {
			display: inline-block;
			vertical-align: top;
		}
		&-sender {
			width: 26px;
			height: 26px;
		}
		&-recepient,
		&-sender {
			display: inline-block;
			vertical-align: middle;
			.image__user, .image__group {
				width: 45px;
				height: 45px;
			}
		}
		&-link {
			position: relative;
			z-index: 10;
			display: inline-block;
			vertical-align: top;
			transition: transform 0.2s ease;
			transform: scale(1);
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	&__content {
		white-space: nowrap;
		&-cell {
			display: table;
			width: 100%;
			table-layout: fixed;
			overflow: hidden;
			&:last-of-type {
				margin-top: 3px;
			}
		}
		&-item {
			display: table-cell;
			vertical-align: middle;
			&.image {
				width: 30px;
			}
			&.date {
				width: 50px;
				text-align: right;
			}
		}
		&-date,
		&-message {
			line-height: 1;
		}
		&-date {
			font-size: 12px;
			vertical-align: middle;
			display: inline-block;
		}
		&-name {
			font-size: 16px;
			line-height: 18px;
			height: 18px;
			white-space: nowrap;
			font-weight: normal;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-message {
			display: block;
			vertical-align: middle;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			height: 14px;
		}
		&-exit {
			position: absolute;
			right: 5px;
			top: 5px;
			z-index: 10;
		}
	}
	&__counter {
		position: absolute;
		top: 6px;
		left: 6px;
		z-index: 11;
		opacity: 0;
		transition: opacity 0.3s ease;
		&-item {
			font-size: 10px;
			line-height: 10px;
			display: inline-block;
			background-color: $bgColor-12;
			padding: 7px 0 5px;
			vertical-align: top;
			color: $textColor-10;
			text-align: center;
			width: 22px;
			border-radius: 100%;
			cursor: default;
		}
	}
	&__exit {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
		.action {
			display: inline-block;
			border-radius: 0 0 0 8px;
			text-align: center;
			width: 21px;
			height: 21px;
			font-size: 10px;
			padding-top: 1px;
			cursor: pointer;
			border-left: 1px solid $borderColor-55;
			border-bottom: 1px solid $borderColor-55;
			background: $bgColor-23;
			&:hover {
				.icon {
					color: darken($textColor-14, 10%);
				}
			}
			.icon {
				transition: color .1s ease-in-out;
				color: $textColor-14;
				font-size: 10px;
			}
		}
	}
}
